<template>
  <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
    offset-y :disabled="disabled" min-width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field :disabled="disabled" :value="dateChange" :label="label || $t('general.date')" readonly
        :prepend-inner-icon="'event'" v-bind="attrs" v-on="on" v-model="dateChange" required
        :rules="[(v) => v !== '' || $t('validation.required')]" :placeholder="placeholder"
        :prefix="prefixtext"></v-text-field>
    </template>
    <div class="loading-date" v-if="loading">
      <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
    </div>
    <v-date-picker ref="picker" :disabled="disabled" v-model="date" v-bind="$attrs" first-day-of-week="1"
      :show-current="showCurrent" :picker-date.sync="pickerDate" locale="da-dk"></v-date-picker>
    <v-btn block color="primary" @click="$refs.menu.save(date)">{{
      $t("general.ok")
    }}</v-btn>
  </v-menu>
</template>
<script>
import Vue from "vue";
import { DateTime } from "luxon";

export default Vue.component("Datepicker", {
  inheritAttrs: false,
  props: [
    "value",
    "label",
    "placeholder",
    "disabled",
    "active-picker",
    "prefixtext",
    "dateformat",
    "showCurrent",
    "loading"
  ],
  methods: {},
  data() {
    return {
      menu: false,
      pickerDate: null,
      dateChange: null,
      format: this.dateformat || "dd/LL/yyyy",
    };
  },
  created: function () {
    this.dateChange = this.value ? DateTime.fromJSDate(new Date(this.value)).setLocale(this.$store.state.order.culture).toLocaleString(DateTime.DATE_FULL) : '';
  },
  watch: {
    menu(val) {
      if (this.activePicker && this.activePicker != "") {
        val && this.$nextTick(() => (this.$refs.picker.activePicker = "YEAR"));
      }
    },

    pickerDate(val, oldval) {
      if (oldval && val !== oldval) {
        //this.dateChange = DateTime. fromJSDate(new Date(val)).setLocale(this.$store.state.order.culture).toLocaleString(DateTime.DATE_FULL);
        this.$emit("monthchange", val);
      }
    }
  },
  computed: {
    // dateView: {
    //   get() { return  this.value? DateTime.fromISO(this.value).toFormat(DateTime.DATE_FULL):"" }},
    date: {
      get() {
        return this.value;
      },
      set(val) {
        this.dateChange = DateTime.fromJSDate(new Date(val)).setLocale(this.$store.state.order.culture).toLocaleString(DateTime.DATE_FULL);
        this.$refs.menu.save(this.date);
        this.$emit("input", val);
      },
    },
  },
});
</script>
<style lang="scss" scoped>
.loading-date {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0000000f;
}
</style>
