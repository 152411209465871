import axios from "../../settings/axios.js";
import { DateTime } from "luxon";
import _ from "lodash";
import i18n from "../../i18n";
import GTMMessage from "@/Helpers/GTMMessage";
import {
  GetDaysAndMonthsToPay,
  getTraceId,
  GetDaysToPay,
} from "../../Helpers/apiDataHelper";

/**
             $$\                $$\               
            $$ |               $$ |              
 $$$$$$$\ $$$$$$\    $$$$$$\ $$$$$$\    $$$$$$\  
$$  _____|\_$$  _|   \____$$\\_$$  _|  $$  __$$\ 
\$$$$$$\    $$ |     $$$$$$$ | $$ |    $$$$$$$$ |
 \____$$\   $$ |$$\ $$  __$$ | $$ |$$\ $$   ____|
$$$$$$$  |  \$$$$  |\$$$$$$$ | \$$$$  |\$$$$$$$\ 
\_______/    \____/  \_______|  \____/  \_______|
 */

const state = {
  traceId: "00000000-0000-0000-0000-000000000000",
  host: "",
  headOffset: 0,
  inverseHidePrice: false,
  showBruttoList: false,
  showSmsNotify: false,
  daysAndMonthsToPay: null,
  daysToPay: 0,
  periodesOptions: null,
  periodsFromProp: null,
  restrictToPeriods: false,
  periodStartBuffer: 0,
  acceptssms: false,
  periodeChosenKey: undefined,
  chosenShowMonthsOptions: false,
  showPeriodsView: true,
  minimumRentalPeriod: "0d",
  selectCarFromBruttoList: undefined,
  forceofficeid: {
    force: false,
    officeid: 0,
  },
  forcelocations: {
    force: false,
    id: 0,
  },
  container: "",
  tempcalcPrice: 0,
  companyId: "",
  culture: "da-DK",
  appTheme: "",
  onlinepayment: true,
  payinstoreoption: false,
  deliveryTimefromRentlog: false,
  insurance: false,
  insurancePriceForCar: null,
  companysettings: {
    rentingstarttime: 10,
    rentingendtime: 11,
    forceabonnement: false,
    parametersLock: [],
    priceforoverrunkm: 0,
  },
  department: {},
  selecteddepartment: "",
  startlocation: "",
  endlocation: "",
  pickupDate: "",
  deliveryDate: "",
  pickupTime: "",
  deliveryTime: "",
  car: {},
  range: null,
  selectVehicleType: "Alle",
  customRange: 0,
  accessories: [],
  extraDrivers: [],
  contactInfo: {
    firstName: "",
    lastName: "",
    companyName: "",
    cvr: "",
    address: "",
    city: "",
    zipcode: "",
    birthdate: "",
    email: "",
    phone: "",
  },
  createContractProcess: {
    loading: false,
    choiceType: "",
    created: false,
    errorMessage: "",
    cancelled: false,
    error: false,
  },
  contractData: {},
  payment: {
    paymenturl: "",
  },
  paymentstatus: {},
  subscriptionstatus: "",
  paymentError: false,
  periodesOptionsError: false,
  daysAndMonthsToPayError: false,
  disableOnlinePayment: false,
};

/*
                     $$\     $$\                                   
                     $$ |    $$ |                                  
 $$$$$$\   $$$$$$\ $$$$$$\ $$$$$$\    $$$$$$\   $$$$$$\   $$$$$$$\ 
$$  __$$\ $$  __$$\\_$$  _|\_$$  _|  $$  __$$\ $$  __$$\ $$  _____|
$$ /  $$ |$$$$$$$$ | $$ |    $$ |    $$$$$$$$ |$$ |  \__|\$$$$$$\  
$$ |  $$ |$$   ____| $$ |$$\ $$ |$$\ $$   ____|$$ |       \____$$\ 
\$$$$$$$ |\$$$$$$$\  \$$$$  |\$$$$  |\$$$$$$$\ $$ |      $$$$$$$  |
 \____$$ | \_______|  \____/  \____/  \_______|\__|      \_______/ 
$$\   $$ |                                                         
\$$$$$$  |                                                         
 \______/       

*/

const getters = {
  getApiAccessValues: (state) => {
    state.companyId, state.traceId;
  },

  getInverseHidePrice: (state) => (typeGiven) => {
    // this.$store.getters["order/getInverseHidePrice"]("days");
    // this.$store.getters["order/getInverseHidePrice"]("months");
    /**
     * send the given type of you ar working with, an it will return true or false if you should hide price
     * exampel: if you are working with month and send months as type (area of work), then it will return true, and you should hide price
     */

    let hidePrice = false;
    const monthsView =
      state.daysAndMonthsToPay && state.daysAndMonthsToPay.fullmonths > 0;
    if (state.inverseHidePrice) {
      if (typeGiven == "days" && monthsView) {
        hidePrice = true;
      } else if (typeGiven == "months" && !monthsView) {
        hidePrice = true;
      }
    }
    return hidePrice;
  },
  getSelectCarFromBruttoList: (state) => {
    return state.selectCarFromBruttoList;
  },
  getExtraDrivers: (state) => {
    return state.extraDrivers;
  },
  getMonthsView: (state) =>
    state.daysAndMonthsToPay && state.daysAndMonthsToPay.fullmonths > 0
      ? true
      : false,
  getRestrictToPeriods: (state) => state.restrictToPeriods,
  getOfficeId: (...args) => {
    const state = args[0];
    if (state.department && state.department.id) {
      return state.department.id;
    }

    const rootState = args[2];
    const startlocations = rootState.company.locations.startlocations;
    const startlocation = state.startlocation;
    if (startlocation) {
      const startlocationObj = startlocations.find(
        (x) => x.name == startlocation
      );
      if (startlocationObj && startlocationObj.branchoffice) {
        return startlocationObj.branchoffice.id;
      }
    }

    return 0;
  },
  getOfficeInfo: (...args) => {
    const state = args[0];
    if (state.department && state.department.id) {
      return state.department;
    }

    const rootState = args[2];
    const startlocations = rootState.company.locations.startlocations;
    const startlocation = state.startlocation;
    if (startlocation) {
      const startlocationObj = startlocations.find(
        (x) => x.name == startlocation
      );
      if (startlocationObj && startlocationObj.branchoffice) {
        return startlocationObj.branchoffice;
      }
    }

    return null;
  },
  getMinimumRentalPeriodText(state) {
    if (
      state.minimumRentalPeriod != "0d" ||
      state.minimumRentalPeriod != "1d"
    ) {
      const period = state.minimumRentalPeriod;
      if (/d$/.test(period)) {
        let val = Math.floor(period.split("d")[0]);
        if (!isNaN(val)) {
          return {
            value: parseInt(val),
            type: "d",
          };
        }
      } else if (/m$/.test(period)) {
        let val = Math.floor(period.split("m")[0]);
        if (!isNaN(val)) {
          return {
            value: parseInt(val),
            type: "m",
          };
        }
      }
    }
    return {};
  },
  getMinimumRentalPeriodDate: (state) => (dategiven) => {
    const baseDateNow = new Date().toISOString().split("T")[0];
    if (!dategiven) {
      return baseDateNow;
    }
    let dateGivenLux = DateTime.fromISO(dategiven);
    if (dateGivenLux.invalid || dateGivenLux.isLuxonDateTime == false) {
      return baseDateNow;
    }
    if (!state.minimumRentalPeriod || state.minimumRentalPeriod == "0d") {
      return dateGivenLux.toFormat("yyyy-LL-dd");
    }
    const period = state.minimumRentalPeriod;
    if (/d$/.test(period)) {
      // days
      let val = Math.floor(period.split("d")[0]) - 1;

      if (!isNaN(val)) {
        dateGivenLux = dateGivenLux.plus({ days: val });
      } else
        console.info(
          "%cMinimum Rental Period is not valid, a number should end with d or m. value is " +
            state.minimumRentalPeriod,
          "color: red; font-size: 2rem"
        );
    } else if (/m$/.test(period)) {
      // months
      let val = Math.floor(period.split("m")[0]);
      if (!isNaN(val)) {
        dateGivenLux = dateGivenLux.plus({ months: val });
      } else
        console.info(
          "%cMinimum Rental Period is not valid, a number should end with d or m. value is " +
            state.minimumRentalPeriod,
          "color: red; font-size: 2rem"
        );
    }
    return dateGivenLux.toFormat("yyyy-LL-dd");
  },
  daysToPay: (state) => {
    return state.daysToPay;
  },
  getPeriodesOptions: (state) => {
    return state.periodsFromProp;
  },
  getCar: (state) => {
    return state.car;
  },
  getRangeIncluded: (state) => {
    return state.companysettings.maxkmprday;
  },
  companysettings: (state) => {
    return state.companysettings;
  },
  paymentstatus: (state) => {
    return state.paymentstatus;
  },
  contractProcess: (state) => {
    return state.createContractProcess;
  },
  payment: (state) => state.payment,
  pickup: (state) => {
    return `${state.pickupDate}`;
  },
  delivery: (state) => {
    return `${state.deliveryDate}`;
  },
  pickupDateTime: (state) => (dategiven) => {
    const hour = state.pickupTime
      ? state.pickupTime.substring(0, 2)
      : state.companysettings.rentingstarttime;
    const minute = state.pickupTime ? state.pickupTime.substring(3) : "00";
    const date = (dategiven ? DateTime.fromISO(dategiven) : DateTime.local())
      .set({ hour: hour, minute: minute, second: 0, millisecond: 0 })
      .toISO();
    return `${date}`;
  },
  deliveryDateTime: (state) => (dategiven, days = 0, months = 0) => {
    const hour = state.deliveryTime
      ? state.deliveryTime.substring(0, 2)
      : state.companysettings.rentingstarttime;
    const minute = state.deliveryTime ? state.deliveryTime.substring(3) : "00";
    let date = dategiven ? DateTime.fromISO(dategiven) : DateTime.local();
    date = date.set({ hour: hour, minute: minute, second: 0, millisecond: 0 });
    if (days) date = date.plus({ days: days });
    if (months) date = date.plus({ months: months });
    date = date.toISO();
    return `${date}`;
  },
  city: (state) => {
    return state.department.city;
  },
  selectedAccessories: (state) => {
    if (state.accessories.length === 0) return [];
    return state.accessories;
  },
  getRangeInKm: (state) => {
    if (state.range == undefined) {
      return "";
    }
    return state.range.maxkm;
  },
  getActualRange: (state) => {
    if (state.range == undefined) {
      return "";
    }

    return state.range.userspecifiedmaxkm
      ? `${state.customRange} km`
      : state.range.name;
  },
  isRangeUserSpecified: (state) => {
    if (state.range == undefined) {
      return;
    }
    return state.range.userspecifiedmaxkm;
  },
};

/**
                           $$\                $$\     $$\                               
                          $$ |               $$ |    \__|                              
$$$$$$\$$$$\  $$\   $$\ $$$$$$\    $$$$$$\ $$$$$$\   $$\  $$$$$$\  $$$$$$$\   $$$$$$$\ 
$$  _$$  _$$\ $$ |  $$ |\_$$  _|   \____$$\\_$$  _|  $$ |$$  __$$\ $$  __$$\ $$  _____|
$$ / $$ / $$ |$$ |  $$ |  $$ |     $$$$$$$ | $$ |    $$ |$$ /  $$ |$$ |  $$ |\$$$$$$\  
$$ | $$ | $$ |$$ |  $$ |  $$ |$$\ $$  __$$ | $$ |$$\ $$ |$$ |  $$ |$$ |  $$ | \____$$\ 
$$ | $$ | $$ |\$$$$$$  |  \$$$$  |\$$$$$$$ | \$$$$  |$$ |\$$$$$$  |$$ |  $$ |$$$$$$$  |
\__| \__| \__| \______/    \____/  \_______|  \____/ \__| \______/ \__|  \__|\_______/
 */

const mutations = {
  SET_setInverseHidePrice(state, payload) {
    state.inverseHidePrice = payload;
  },
  SET_SELECTCARFROMBRUTTOLIST(state, payload) {
    state.selectCarFromBruttoList = payload;
  },
  SET_EXTRADRIVERS(state, payload) {
    state.extraDrivers = payload;
  },
  SET_PERIODES_OPTIONS_ERROR(state, val) {
    state.periodesOptionsError = val;
  },
  SET_DAYS_AND_MONTHS_TO_PAY_ERROR(state, val) {
    state.daysAndMonthsToPayError = val;
  },
  SET_MINIMUM_RENTAL_PERIOD(state, val) {
    state.minimumRentalPeriod = val;
  },

  SET_SHOW_BRUTTO_LIST(state, val) {
    state.showBruttoList = val;
  },
  SET_PERIODE_CHOSEN_KEY(state, val) {
    state.periodeChosenKey = val;
  },
  SET_CHOSEN_SHOW_MONTHS_OPTIONS(state, val) {
    state.chosenShowMonthsOptions = val;
  },
  SET_SHOW_SMS_NOTIFY(state, val) {
    state.showSmsNotify = val;
  },
  SET_PERIOD_START_BUFFER(state, val) {
    state.periodStartBuffer = val;
  },
  SET_PERIODS_FROM_PROP(state, val) {
    state.periodsFromProp = val;
  },
  SET_SHOW_PERIODS_VIEW(state, val) {
    state.showPeriodsView = val;
  },
  SET_RESTRICT_TO_PERIODS(state, val) {
    state.restrictToPeriods = val;
  },
  SET_DAYSTOPAY(state, payload) {
    state.daysToPay = payload;
  },
  SET_DAYS_AND_MONTHS_TO_PAY(state, payload) {
    state.daysAndMonthsToPay = payload;
  },
  SET_TRACEID(state, payload) {
    state.traceId = payload;
    localStorage.traceId = payload;
  },
  SET_ENDLOCATION(state, payload) {
    state.endlocation = payload;
  },
  SET_STARTLOCATION(state, payload) {
    state.startlocation = payload;
  },
  DELETE_TRACEID(state) {
    state.traceId = "00000000-0000-0000-0000-000000000000";
    localStorage.removeItem("traceId");
  },
  SET_INSURANCEPRICEFORCAR(state, payload) {
    state.insurancePriceForCar = payload;
  },
  SET_SUBSCRIPTIONSTATUS(state, payload) {
    state.paymentstatus = payload;
  },
  SET_CREATECONTRACTPROCESS(state, payload) {
    if (Object.prototype.hasOwnProperty.call(payload, "loading"))
      state.createContractProcess.loading = payload.loading;

    if (Object.prototype.hasOwnProperty.call(payload, "choiceType"))
      state.createContractProcess.choiceType = payload.choiceType;

    if (Object.prototype.hasOwnProperty.call(payload, "created"))
      state.createContractProcess.created = payload.created;

    if (Object.prototype.hasOwnProperty.call(payload, "errorMessage"))
      state.createContractProcess.errorMessage = payload.errorMessage;

    if (Object.prototype.hasOwnProperty.call(payload, "cancelled"))
      state.createContractProcess.cancelled = payload.cancelled;

    if (Object.prototype.hasOwnProperty.call(payload, "error"))
      state.createContractProcess.error = payload.error;
  },
  SET_PAYMENT(state, payload) {
    if (Object.prototype.hasOwnProperty.call(payload, "paymenturl")) {
      state.payment.paymenturl = payload.paymenturl;
    }
  },

  SET_APPTHEME(state, payload) {
    state.appTheme = payload;
  },
  SET_PAYINSTOREOPTION(state, payload) {
    state.payinstoreoption = payload;
  },
  SET_ACTIVATE_DELIVERYTIME_FROM_RENTLOG(state, payload) {
    state.deliveryTimefromRentlog = payload;
  },
  SET_CULTURE(state, payload) {
    state.culture = payload;
  },
  SET_PAYMENT_STATUS(state, payload) {
    state.paymentstatus = payload;
  },
  SET_CONTRACT(state, payload) {
    state.contractData = payload;
  },
  SET_COMPANYID(state, payload) {
    state.companyId = payload;
  },

  SET_COMPANY_SETTINGS(state, payload) {
    // state.companysettings.parametersLock = []; // ?
    if (Object.prototype.hasOwnProperty.call(payload, "parametersLock"))
      state.companysettings.parametersLock.push(payload.parametersLock);

    if (Object.prototype.hasOwnProperty.call(payload, "rentingstarttime"))
      if (
        (payload.parametersLock != undefined &&
          payload.parametersLock == "rentingstarttime") ||
        state.companysettings.parametersLock.includes("rentingstarttime") ==
          false
      )
        state.companysettings.rentingstarttime = payload.rentingstarttime;

    if (Object.prototype.hasOwnProperty.call(payload, "rentingendtime"))
      if (
        (payload.parametersLock != undefined &&
          payload.parametersLock == "rentingendtime") ||
        state.companysettings.parametersLock.includes("rentingendtime") == false
      )
        state.companysettings.rentingendtime = payload.rentingendtime;

    if (Object.prototype.hasOwnProperty.call(payload, "forceabonnement"))
      state.companysettings.forceabonnement = payload.forceabonnement;

    if (Object.prototype.hasOwnProperty.call(payload, "ownriskprice"))
      state.companysettings.ownriskprice = payload.ownriskprice;

    if (Object.prototype.hasOwnProperty.call(payload, "maxinsuranceprice"))
      state.companysettings.maxinsuranceprice = payload.maxinsuranceprice;

    if (Object.prototype.hasOwnProperty.call(payload, "maxkmprday"))
      state.companysettings.maxkmprday = payload.maxkmprday;
    if (Object.prototype.hasOwnProperty.call(payload, "priceforoverrunkm"))
      state.companysettings.priceforoverrunkm = payload.priceforoverrunkm;
    if (Object.prototype.hasOwnProperty.call(payload, "insuranceprice"))
      state.companysettings.insuranceprice = payload.insuranceprice;
  },
  SET_DEPARTMENT(state, payload) {
    state.department = payload;
  },
  SET_DEPARTMENT_NAME(state, payload) {
    state.selecteddepartment = payload;
  },
  SET_CAR(state, payload) {
    state.car = payload;
  },
  SET_PICKUP_DATE(state, payload) {
    state.pickupDate = payload;
  },
  SET_TIMESPAN(state, timespan) {
    state.timespan = timespan;
  },
  M_SET_PERIODES_OPTIONS(state, payload) {
    state.periodesOptions = payload;
  },
  SET_DELIVERY_DATE(state, payload) {
    state.deliveryDate = payload;
  },
  SET_PICKUP_TIME(state, payload) {
    state.pickupTime = payload;
  },
  SET_DELIVERY_TIME(state, payload) {
    state.deliveryTime = payload;
  },
  SET_RANGE(state, payload) {
    state.range = payload;
  },
  SET_ACCESSORIES(state, payload) {
    state.accessories = payload.filter((x) => x !== undefined);
  },
  SET_CUSTOM_RANGE(state, payload) {
    if (payload && !isNaN(payload)) {
      state.customRange = parseInt(payload);
    }
  },

  SET_FIRSTNAME(state, payload) {
    state.contactInfo.firstName = payload;
  },
  SET_LASTNAME(state, payload) {
    state.contactInfo.lastName = payload;
  },
  SET_COMPANYNAME(state, payload) {
    state.contactInfo.companyName = payload;
  },
  SET_CVR(state, payload) {
    state.contactInfo.cvr = payload;
  },
  SET_ADDRESS(state, payload) {
    state.contactInfo.address = payload;
  },
  SET_CITY(state, payload) {
    state.contactInfo.city = payload;
  },
  SET_ZIPCODE(state, payload) {
    state.contactInfo.zipcode = payload;
  },
  SET_BIRTHDATE(state, payload) {
    state.contactInfo.birthdate = payload;
  },
  SET_EMAIL(state, payload) {
    state.contactInfo.email = payload;
  },
  SET_PHONE(state, payload) {
    state.contactInfo.phone = payload;
  },
  SET_ACCEPTSSMS(state, payload) {
    state.acceptssms = payload;
  },
  SET_PRICE(state, payload) {
    state.tempcalcPrice = payload;
  },
  SET_INSURANCE(state, payload) {
    state.insurance = payload;
  },
  SET_CONFIGCONTAINER(state, payload) {
    state.container = payload;
  },
  SET_CONFIGHOST(state, payload) {
    state.host = payload;
  },
  SET_CONFIGHEADOFFSET(state, payload) {
    state.headOffset = payload;
  },
  SET_FORCE_LOCATIONS(state, payload) {
    const id = _.toNumber(payload);
    if (_.isNumber(id)) {
      state.forceofficeid.force = true;
      state.forceofficeid.officeid = id;
      state.forcelocations.force = true;
      state.forcelocations.id = id;
    } else
      console.error(
        "Try to force officeid error: isNumber" + _.isNumber(id),
        "| payload: " + payload
      );
  },
  RESET_FORCE_DEPARTMENT_ID(state) {
    state.forceofficeid.force = false;
    state.forceofficeid.officeid = 0;
  },
  RESET_FORCE_LOCATIONS_ID(state) {
    state.forcelocations.force = false;
    state.forcelocations.id = 0;
  },
  SET_DISABLE_ONLINE_PAYMENT(state, payload) {
    state.disableOnlinePayment = payload;
  },
};

/**
                      $$\     $$\                               
                      $$ |    \__|                              
 $$$$$$\   $$$$$$$\ $$$$$$\   $$\  $$$$$$\  $$$$$$$\   $$$$$$$\ 
 \____$$\ $$  _____|\_$$  _|  $$ |$$  __$$\ $$  __$$\ $$  _____|
 $$$$$$$ |$$ /        $$ |    $$ |$$ /  $$ |$$ |  $$ |\$$$$$$\  
$$  __$$ |$$ |        $$ |$$\ $$ |$$ |  $$ |$$ |  $$ | \____$$\ 
\$$$$$$$ |\$$$$$$$\   \$$$$  |$$ |\$$$$$$  |$$ |  $$ |$$$$$$$  |
 \_______| \_______|   \____/ \__| \______/ \__|  \__|\_______/

 */

function CreateContractObject(
  state,
  pickupDateTime,
  rangeIncluded,
  deliveryDateTime,
  rootState,
  isSubscription = false
) {
  let range = state.customRange || state.range ? state.range.maxkm : 0;

  // var accessoriesMap = state.accessories.map((x) => {
  //   return {
  //     accessoryid: x.id,
  //     name: x.name,
  //     quantity: x.quantityvalue,
  //   };
  // });

  const accessories = rootState.order.accessories.filter(
    (x) => x.accessorytype == "standard"
  );
  var accessoriesMap = accessories.map((x) => {
    return {
      accessoryid: x.id,
      name: x.name,
      quantity: x.quantityvalue,
    };
  });

  let extrakmitem;
  if (state.range) {
    extrakmitem = {
      ExtraKmPackageId: state.range.id,
    };

    if (state.range.userspecifiedmaxkm) {
      extrakmitem.UserSpecifiedMaxKm = state.customRange;
    }
  }

  let branchofficeid = 0;
  if (state.car) {
    branchofficeid = state.car.branchoffice?.id;
  }
  if (!branchofficeid || branchofficeid <= 0) {
    branchofficeid =
      state.department && state.department.id && state.department.id > 0
        ? state.department.id
        : state.car.branchoffice?.id;
    let locationObj = rootState.company.locations.startlocations.find(
      (x) => x.name == state.startlocation
    );
    if (
      locationObj &&
      locationObj.branchoffice &&
      locationObj.branchoffice.id
    ) {
      branchofficeid = locationObj.branchoffice.id;
    }
  }

  const data = {
    insurance: state.insurance,
    rentalstartlocation: state.startlocation || state.selecteddepartment,
    accessoryids: accessoriesMap,
    rentingcarid: state.car.id,
    branchofficeid: branchofficeid,
    rentingstartdate: pickupDateTime,
    rentingenddate: deliveryDateTime,
    ekstrakmid: range ? state.range.id : 0,
    includedkm: rangeIncluded,
    ekstrakm: range,
    acceptssms: state.acceptssms,
    extrakmitem: extrakmitem,
    culture: "da-DK",
    customername: `${state.contactInfo.firstName} ${state.contactInfo.lastName}`,
    customercompanyname: state.contactInfo.companyName,
    customercvr: state.contactInfo.cvr,
    customeraddress: state.contactInfo.address,
    customercity: state.contactInfo.city,
    customerpostcode: state.contactInfo.zipcode,
    customeremail: state.contactInfo.email,
    customerphonenumber: state.contactInfo.phone,
    carbrand:
      state.car.vehiclemodel != undefined &&
      state.car.vehiclemodel.vehiclebrand != undefined
        ? state.car.vehiclemodel.vehiclebrand.name
        : "",
    carmodel: state.car.vehiclemodel ? state.car.vehiclemodel.name : "",
    carclass: state.car.vehicleclass ? state.car.vehicleclass.name : "",
    isSubscription: isSubscription,
  };

  const accessoriesextradriver = state.accessories.find(
    (x) => x.accessorytype == "extradriver"
  );
  if (
    accessoriesextradriver &&
    state.extraDrivers &&
    state.extraDrivers.length
  ) {
    data.extraDrivers = state.extraDrivers.map((x) => {
      return {
        name: x.driverName,
        driverLicenseNr: x.licenseNr,
      };
    });
  }

  return data;
}

const actions = {
  setInverseHidePrice({ commit }, payload) {
    commit("SET_setInverseHidePrice", payload);
  },
  setSelectCarFromBruttoList({ commit }, payload) {
    commit("SET_SELECTCARFROMBRUTTOLIST", payload);
  },
  setExtraDrivers({ commit }, payload) {
    commit("SET_EXTRADRIVERS", payload);
  },
  setDaystopay({ commit }, payload) {
    commit("SET_DAYSTOPAY", payload);
  },
  setPeriodeChosenKey({ commit }, payload) {
    commit("SET_PERIODE_CHOSEN_KEY", payload);
  },
  setChosenShowMonthsOptions({ commit }, payload) {
    commit("SET_CHOSEN_SHOW_MONTHS_OPTIONS", payload);
  },
  setShowSmsNotify({ commit }, payload) {
    commit("SET_SHOW_SMS_NOTIFY", payload);
  },
  setPeriodStartBuffer({ commit }, payload) {
    commit("SET_PERIOD_START_BUFFER", payload);
  },
  setPeriodsFromProp({ commit }, payload) {
    commit("SET_PERIODS_FROM_PROP", payload);
  },
  setShowPeriodsView({ commit }, payload) {
    commit("SET_SHOW_PERIODS_VIEW", payload);
  },
  setRestrictToPeriods({ commit, dispatch, state }, payload) {
    if (payload && !state.periodsFromProp) {
      let periodsFromProp = [];
      for (let index = 1; index <= 36; index++) {
        periodsFromProp.push({
          unit: "måneder",
          value: index,
        });
      }
      dispatch("setShowPeriodsView", true);
      commit("SET_PERIODS_FROM_PROP", periodsFromProp);
      commit("SET_RESTRICT_TO_PERIODS", true);
    } else {
      commit("SET_RESTRICT_TO_PERIODS", payload);
    }
  },
  setMinimumRentalPeriod({ commit }, val) {
    commit("SET_MINIMUM_RENTAL_PERIOD", val);
  },
  setShowBruttoList({ commit }, val) {
    commit("SET_SHOW_BRUTTO_LIST", val);
  },
  RESET_FORCE_DEPARTMENT_ID({ commit }) {
    commit("RESET_FORCE_DEPARTMENT_ID");
  },
  RESET_FORCE_LOCATIONS_ID({ commit }) {
    commit("RESET_FORCE_LOCATIONS_ID");
  },
  DELETE_TRACEID({ commit }) {
    commit("DELETE_TRACEID");
  },
  async TRACEID({ commit }, traceId) {
    if (traceId) {
      commit("SET_TRACEID", traceId);
    } else {
      const { data, error } = await getTraceId();
      if (error && error == true) {
        console.log(error);
        commit("app_error_departments", true);
      } else {
        commit("SET_TRACEID", data);
      }
    }
  },
  SET_ENDLOCATION({ commit }, payload) {
    commit("SET_ENDLOCATION", payload);
  },
  SET_STARTLOCATION({ commit, dispatch, rootState }, payload) {
    const location = rootState.company.locations.startlocations.find(
      (x) => x.name == payload
    );
    if (location.branchoffice && location.branchoffice.id) {
      // set branchoffice
    } else {
      // remove branchoffice
    }

    commit("SET_STARTLOCATION", payload);
    dispatch("company/LOAD_START_DATES", null, {
      root: true,
    });
  },
  SET_CONFIGCONTAINER({ commit }, payload) {
    commit("SET_CONFIGCONTAINER", payload);
  },
  SET_CONFIGHEADOFFSET({ commit }, payload) {
    commit("SET_CONFIGHEADOFFSET", payload);
  },
  SET_CONFIGHOST({ commit }, payload) {
    commit("SET_CONFIGHOST", payload);
  },
  SET_FORCE_LOCATIONS({ commit }, payload) {
    commit("SET_FORCE_LOCATIONS", payload);
  },
  SET_FORCE_OFFICEID({ commit }, payload) {
    commit("SET_FORCE_OFFICEID", payload);
  },
  RESET_CHOSEN({ commit, dispatch }, payload) {
    commit("SET_CAR", {});

    commit("SET_RANGE", null);
    commit("SET_ACCESSORIES", []);
    commit("company/SET_AVAILABLE_ACCESSORIES", [], {
      root: true,
    });

    commit("SET_INSURANCE", false);
    dispatch("company/GET_PRICE_FOR_VEHICLE", payload, {
      root: true,
    });
  },
  SET_INSURANCE({ commit }, payload) {
    commit("SET_INSURANCE", payload);
  },
  SET_PRICE({ commit }, payload) {
    commit("SET_PRICE", payload);
  },
  SET_PAYINSTOREOPTION({ commit }, payload) {
    commit("SET_PAYINSTOREOPTION", payload);
  },
  SET_ACTIVATE_DELIVERYTIME_FROM_RENTLOG({ commit }, payload) {
    commit("SET_ACTIVATE_DELIVERYTIME_FROM_RENTLOG", payload);
  },
  SET_CULTURE({ commit }, payload) {
    commit("SET_CULTURE", payload);
  },
  SET_APPTHEME({ commit }, payload) {
    commit("SET_APPTHEME", payload);
  },
  SET_COMPANY_SETTINGS({ commit }, payload) {
    commit("SET_COMPANY_SETTINGS", payload);
  },
  DELETE_ORDER({ commit, state }, payload) {
    commit("SET_CREATECONTRACTPROCESS", {
      loading: true,
      cancelled: false,
      error: false,
    });
    let url = `/Contracts/${payload.customer}?traceId=${state.traceId}`;
    const customer = _.toNumber(payload.customer);

    axios
      .delete(url)
      .then(() => {
        const dataLayerData = {
          eventAction: "checkoutCancelled",
          eventCategory: "confirmation",
          contractId: customer,
          contractIsDeleted: true,
        };
        GTMMessage.post(dataLayerData, state.host);

        commit("SET_CREATECONTRACTPROCESS", {
          loading: false,
          cancelled: true,
          errorMessage: "",
          error: false,
        });
      })
      .catch((error) => {
        const dataLayerData = {
          eventAction: "checkoutCancelled",
          eventCategory: "confirmation",
          contractId: customer,
          contractIsDeleted: false,
        };
        GTMMessage.post(dataLayerData, state.host);

        commit("SET_CREATECONTRACTPROCESS", {
          loading: false,
          created: false,
          errorMessage:
            error && error.response && error.response.data.errors
              ? error.response.data.errors
              : "error unknown",
          cancelled: false,
          error: true,
        });
      });
  },
  GET_PAYMENT_STATUS({ commit, state }, payload) {
    axios
      .get(
        `/Contracts/payment/status/${payload.customer}?traceId=${state.traceId}`
      )
      .then((response) => {
        commit("SET_PAYMENT_STATUS", response.data);
      })
      .catch((error) => {
        console.error("payment error", error);
      });
  },
  HANDEL_CHECKOUTACCEPTED({ commit, dispatch, state }, payload) {
    const customer = _.toNumber(payload.customer);
    let url = `/Contracts/payment/info/${customer}?traceId=${state.traceId}`;
    axios
      .get(url)
      .then((response) => {
        const dataLayerData = {
          eventAction: "checkoutAccepted",
          eventCategory: "confirmation",
          contractId: customer,
          contractAmount: response.data.amount,
          contractPaymentStatus: response.data.payment
            ? response.data.payment.paymentstatus
            : null,
          contractCurrency: response.data.payment
            ? response.data.payment.currency
            : null,
        };
        GTMMessage.post(dataLayerData, state.host);
        if (response.data.issubscription) {
          dispatch("COMPLETE_SUBSCRIPTION", response.data.token).then(() => {});
        } else {
          commit("SET_PAYMENT_STATUS", response.data);
          if (response.data.ispaid) {
            dispatch("SEND_RECEIPT", response.data.token);
          }
        }
      })
      .catch((error) => {
        console.error("payment error", error);
      });
  },
  SEND_RECEIPT({ state, commit }, token) {
    let url = `/Contracts/payment/sendreceipt/${token}?traceId=${state.traceId}`;
    axios
      .get(url)
      .then(() => {
        commit("DELETE_TRACEID");
      })
      .catch((error) => {
        console.error("payment error", error);
      });
  },
  COMPLETE_SUBSCRIPTION({ commit, dispatch, state }, token) {
    axios
      .post(`/Contracts/completesubscription/${token}?traceId=${state.traceId}`)
      .then((response) => {
        commit("SET_PAYMENT_STATUS", response.data);
        dispatch("SEND_RECEIPT", token);
      })
      .catch((error) => {
        commit("SET_PAYMENT_STATUS", {
          issubscription: false,
          ispaid: false,
        });
        console.error("completsubscription error", error);
      });
  },
  CREATE_PAYMENTSUBSCRIPTION({ commit, state }, payload) {
    const hidepoweredby_parameter = payload.hidepoweredby ? '?hide-ait-logo=1' : '';

    const accepturl = `${process.env.VUE_APP_PAYMENT_ACCEPTURL}${hidepoweredby_parameter}`;
    const cancelurl = `${process.env.VUE_APP_PAYMENT_CANCELURL}${hidepoweredby_parameter}`;

    const data = {
      accepturl,
      cancelurl,
    };

    axios
      .post(
        `/Contracts/payment/recurring/${payload.token}?traceId=${state.traceId}`,
        data
      )
      .then((response) => {
        commit("SET_PAYMENT", response.data);
      })
      .catch((error) => {
        commit("SET_PAYMENT_STATUS", {
          issubscription: false,
          ispaid: false,
        });
        console.error("payment error", error);
      });
  },
  CREATE_PAYMENT({ commit, state }, payload) {
    const hidepoweredby_parameter = payload.hidepoweredby ? '?hide-ait-logo=1' : '';

    const accepturl = `${process.env.VUE_APP_PAYMENT_ACCEPTURL}${hidepoweredby_parameter}`;
    const cancelurl = `${process.env.VUE_APP_PAYMENT_CANCELURL}${hidepoweredby_parameter}`;

    const data = {
      accepturl,
      cancelurl,
    };

    axios
      .post(
        `/Contracts/payment/${payload.token}?traceId=${state.traceId}`,
        data
      )
      .then((response) => {
        commit("SET_PAYMENT", response.data);
      })
      .catch((error) => {
        console.error("payment error", error);
      });
  },

  CREATE_PAYINSTORE(
    { commit, state, rootGetters, rootState, dispatch },
    payload
  ) {
    commit("SET_CREATECONTRACTPROCESS", payload);

    const emailSubject = i18n.t("step4.emailSubject_inquiry");
    const companyId = state.companyId;
    const pickupDateTime = rootGetters["order/pickupDateTime"](
      rootState.order.pickupDate
    );
    const deliveryDateTime = rootGetters["order/deliveryDateTime"](
      rootState.order.deliveryDate
    );
    const rangeIncluded = rootGetters["order/getRangeIncluded"];

    let data = CreateContractObject(
      state,
      pickupDateTime,
      rangeIncluded,
      deliveryDateTime,
      rootState
    );
    if (state.car.priceData) {
      data.totalprice = state.car.priceData.totalprice;
      data.totalrentingprice = state.car.priceData.totalrentingprice;
      data.subscriptionprice = state.car.priceData.subscriptionprice;
      data.subscriptiontotalprice = state.car.priceData.subscriptiontotalprice;
    }

    axios
      .post(
        `/Contracts/payinstore/${companyId}?emailSubject=${emailSubject}&traceId=${state.traceId}`,
        data
      )
      .then((response) => {
        commit("SET_CREATECONTRACTPROCESS", {
          loading: false,
          choiceType: "payinstore",
          created: true,
          errorMessage: "",
          error: false,
        });
        dispatch("SEND_RECEIPT", response.data.token);
      })
      .catch((error) => {
        commit("SET_CREATECONTRACTPROCESS", {
          loading: false,
          choiceType: "payinstore",
          created: false,
          errorMessage: error,
          error: true,
        });
      });
  },

  CREATE_INQUIRY({ commit, state, rootGetters, rootState, dispatch }, payload) {
    commit("SET_CREATECONTRACTPROCESS", payload);

    const emailSubject = i18n.t("step4.emailSubject_inquiry");
    const companyId = state.companyId;
    const pickupDateTime = rootGetters["order/pickupDateTime"](
      rootState.order.pickupDate
    );
    const deliveryDateTime = rootGetters["order/deliveryDateTime"](
      rootState.order.deliveryDate
    );
    const rangeIncluded = rootGetters["order/getRangeIncluded"];

    let data = CreateContractObject(
      state,
      pickupDateTime,
      rangeIncluded,
      deliveryDateTime,
      rootState
    );
    if (state.car.priceData) {
      data.totalprice = state.car.priceData.totalprice;
      data.totalrentingprice = state.car.priceData.totalrentingprice;
      data.subscriptionprice = state.car.priceData.subscriptionprice;
      data.subscriptiontotalprice = state.car.priceData.subscriptiontotalprice;
    }

    axios
      .post(
        `/Contracts/inquiry/${companyId}?emailSubject=${emailSubject}&traceId=${state.traceId}`,
        data
      )
      .then(() => {
        commit("SET_CREATECONTRACTPROCESS", {
          loading: false,
          choiceType: "inquiry",
          created: true,
          errorMessage: "",
          error: false,
        });
        dispatch("DELETE_TRACEID");
      })
      .catch((error) => {
        commit("SET_CREATECONTRACTPROCESS", {
          loading: false,
          choiceType: "inquiry",
          created: false,
          errorMessage: error,
          error: true,
        });
      });
  },
  CREATE_CONTRACT(
    { commit, state, rootGetters, dispatch, rootState },
    payload
  ) {
    commit("SET_CREATECONTRACTPROCESS", payload);

    const companyId = state.companyId;

    const pickupDateTime = rootGetters["order/pickupDateTime"](
      rootState.order.pickupDate
    );
    const deliveryDateTime = rootGetters["order/deliveryDateTime"](
      rootState.order.deliveryDate
    );

    const rangeIncluded = rootGetters["order/getRangeIncluded"];

    const data = CreateContractObject(
      state,
      pickupDateTime,
      rangeIncluded,
      deliveryDateTime,
      rootState
    );
    try {
      axios
        .post(`/Contracts/${companyId}?traceId=${state.traceId}`, data)
        .then((response) => {
          if (response.status == 200) {
            commit("SET_CONTRACT", response.data);
            const payment_payload = {
              ...response.data,
              hidepoweredby: rootState.company.hidepoweredby,
            }
            if (payload.choiceType == "subscription") {
              dispatch("CREATE_PAYMENTSUBSCRIPTION", payment_payload);
            } else {

              dispatch("CREATE_PAYMENT", payment_payload);
            }

            commit("SET_CREATECONTRACTPROCESS", {
              loading: false,
              choiceType: "fullamount",
              created: true,
              errorMessage: "",
              error: false,
            });
          } else {
            commit("SET_CREATECONTRACTPROCESS", {
              loading: false,
              choiceType: "fullamount",
              created: false,
              error: true,
              errorMessage: "Der opstod en eller flere valideringsfejl.",
            });
          }
        });
      //         .catch(function (error) {
      //

      //           let errorMsg = error.response ? error.response.title : ""
      //           commit("SET_CREATECONTRACTPROCESS", {
      //             loading: false,
      //             choiceType: "fullamount",
      //             created: false,
      //             error: true,
      //             errorMessage: errorMsg,
      //           });
      //         });
    } catch (error) {
      commit("SET_CREATECONTRACTPROCESS", {
        loading: false,
        choiceType: "",
        created: false,
        error: true,
        errorMessage: "",
      });
    }
  },
  INIT_APPDATA({ commit, dispatch, state }, companyId) {
    commit("SET_COMPANYID", companyId);

    dispatch("company/LOAD_AGENCY_LOCATIONS", companyId, {
      root: true,
    });

    axios
      .get(`/Vehicle/types/${companyId}?traceId=${state.traceId}`)
      .then((response) => {
        commit("company/SET_VEHICLE_TYPES", response.data, {
          root: true,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  },
  SET_DEPARTMENT({ commit, dispatch, rootGetters }, departmentId) {
    const department = rootGetters["company/getDepartmentById"](departmentId);
    commit("SET_DEPARTMENT", department);

    dispatch("company/LOAD_START_DATES", null, {
      root: true,
    });
  },

  SET_DEPARTMENT_NAME({ commit }, cityname) {
    commit("SET_DEPARTMENT_NAME", cityname);
  },

  SET_PICKUP_AND_DELIVERY({ commit }, payload) {
    if (payload.deliveryDate) {
      commit("SET_DELIVERY_DATE", payload.deliveryDate);
    }
    if (payload.deliveryTime) {
      commit("SET_DELIVERY_TIME", payload.deliveryTime);
    }
    if (payload.pickupDate) {
      commit("SET_PICKUP_DATE", payload.pickupDate);
    }
    if (payload.pickupTime) {
      commit("SET_PICKUP_TIME", payload.pickupTime);
    }
  },

  setPeriodesOptions({ commit }, payload) {
    commit("M_SET_PERIODES_OPTIONS", payload);
  },

  SET_DELIVERY_DATE({ commit, dispatch }, payload) {
    commit("SET_DELIVERY_DATE", payload);
    if (payload) {
      dispatch("GET_SET_DAYSTOPAY");
    } else {
      commit("SET_DAYS_AND_MONTHS_TO_PAY", null);
    }
  },
  async GET_SET_DAYSTOPAY({ state, commit, rootGetters }) {
    if (
      _.isEmpty(state.pickupDate) ||
      _.isEmpty(state.deliveryDate) ||
      _.isEmpty(state.deliveryTime) ||
      _.isEmpty(state.pickupTime)
    )
      return;
    const pickupDateTime = rootGetters["order/pickupDateTime"](
      state.pickupDate
    );

    const deliveryDateTime = rootGetters["order/deliveryDateTime"](
      state.deliveryDate
    );

    var daysToPay = await GetDaysToPay(
      state.companyId,
      state.traceId,
      pickupDateTime,
      deliveryDateTime
    );
    var daysAndMonths = await GetDaysAndMonthsToPay(
      state.companyId,
      state.traceId,
      pickupDateTime,
      deliveryDateTime
    );

    if (daysAndMonths.error == false && daysAndMonths.data) {
      commit("SET_DAYS_AND_MONTHS_TO_PAY", daysAndMonths.data);
    } else {
      commit("SET_DAYS_AND_MONTHS_TO_PAY", null);
      commit("SET_DAYS_AND_MONTHS_TO_PAY_ERROR", true);
    }

    if (daysToPay.error == false && daysToPay.data) {
      commit("SET_DAYSTOPAY", daysToPay.data);
    } else {
      commit("SET_DAYSTOPAY", 0);

      commit("SET_DAYS_AND_MONTHS_TO_PAY_ERROR", true);
    }
  },

  SET_DELIVERY_TIME({ commit, dispatch }, payload) {
    commit("SET_DELIVERY_TIME", payload);
    if (payload) {
      dispatch("GET_SET_DAYSTOPAY");
    } else {
      commit("SET_DAYS_AND_MONTHS_TO_PAY", null);
    }
  },

  SET_PICKUP_DATE({ commit, dispatch }, payload) {
    commit("SET_PICKUP_DATE", payload);
    if (payload) {
      dispatch("GET_SET_DAYSTOPAY");
    } else {
      commit("SET_DAYS_AND_MONTHS_TO_PAY", null);
    }
  },

  SET_PICKUP_TIME({ commit, dispatch }, payload) {
    commit("SET_PICKUP_TIME", payload);
    if (payload) {
      dispatch("GET_SET_DAYSTOPAY");
      dispatch("company/LOAD_END_DATES", null, {
        root: true,
      });
    } else {
      commit("SET_DAYS_AND_MONTHS_TO_PAY", null);
    }
  },
  SETANDNEXT({ commit, rootGetters, dispatch }, payload) {
    const car = rootGetters["company/getVehicleById"](payload);
    commit("SET_CAR", car);
    dispatch("GET_INSURANCEPRICEFORCAR");
    commit("step/NEXT", null, {
      root: true,
    });
    dispatch("company/SET_AVAILABLE_ACCESSORIES", null, {
      root: true,
    });
    dispatch("company/SET_KILOMETER_OPTIONS", null, {
      root: true,
    });
  },
  GET_INSURANCEPRICEFORCAR({ state, commit, rootGetters }) {
    // TODO: GetInsurancePriceForCar from apiDataHelper
    // GetInsurancePriceForCar(companyId, traceId, startDate, endDate, rentingcarid, extrakms = [], accessories = [] )
    const pickupDateTime = rootGetters["order/pickupDateTime"](
      state.pickupDate
    );
    const deliveryDateTime = rootGetters["order/deliveryDateTime"](
      state.deliveryDate
    );

    let data = {
      startdate: pickupDateTime,
      enddate: deliveryDateTime,
      rentingcarid: state.car.id,
      extrakms: [],
      accessories: [],
      ownriskinsurance: true,
    };
    axios
      .post(
        `/Vehicle/detailedPrice/${state.companyId}?traceId=${state.traceId}`,
        data
      )
      .then((response) => {
        commit("SET_INSURANCEPRICEFORCAR", response.data);
      })
      .catch((error) => {
        console.error("get error detailedPrice", error);
        commit(null);
      });
  },
  SET_SEARCH({ commit }, payload) {
    commit("SET_CITY", payload.city);
    commit("SET_PICKUP_DATE", payload.pickupDate);
    commit("SET_DELIVERY_DATE", payload.deliveryDate);
  },
  SET_RANGE({ commit, rootGetters }, payload) {
    const kilometerOption = rootGetters["company/getKilometerOptionByKm"](
      payload
    );
    commit("SET_RANGE", kilometerOption);
  },
  SET_RANGE_BY_RANGE({ commit }, payload) {
    commit("SET_RANGE", payload);
  },
  SET_RANGE_BY_ID({ commit, rootGetters }, payload) {
    const kilometerOption = rootGetters["company/getKilometerOptionById"](
      payload
    );
    commit("SET_RANGE", kilometerOption);
  },
  SET_ACCESSORIES({ commit, rootGetters }, payload) {
    const getAccessoryById = rootGetters["company/getAccessoryById"];
    const accessories = payload.map((x) => getAccessoryById(x));
    commit("SET_ACCESSORIES", accessories);
  },
  SET_ACCESSORIESBYITEM({ commit }, accessories) {
    commit("SET_ACCESSORIES", accessories);
  },
  setDisableOnlinePayment({ commit }, payload) {
    commit("SET_DISABLE_ONLINE_PAYMENT", payload);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
