<template>
  <v-container>
    <v-row class="mt-12">
      <v-col cols="12">
        <h2 class="text-center">{{ $t("step1.title") }}</h2>
        <p v-if="!hasCarType">
          {{ $t("step1.error_msg") }}
        </p>
      </v-col>
      <v-col cols="12" v-if="$store.state.company.apperror.availablevehicles">
          <Er-ErrorMsg >{{ $t("general.server_error") }}</Er-ErrorMsg>
      </v-col>
      <v-col cols="12">
        <CarGrid :car-list="carList" :vehicle-types="vehicleTypes" :vehicles-in-focus="vehiclesInFocus" :months-view="monthsView" @selected="selectCar"></CarGrid>
      </v-col>
    </v-row>
    <v-row class="mt-10">
      <v-col  class="pa-2 pr-xl-12">
        <v-btn block x-large rounded @click="previousStep">
          {{ $t("general.back") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CarGrid from "../components/CarGrid";
import Scroll from '@/Helpers/Scroll';
import GTMMessage from "@/Helpers/GTMMessage";
import { mapGetters } from "vuex";

export default {
  name: "Step1",
  components: { CarGrid },
   mounted() {
    Scroll.sendScroll(this.$store.state.order.container, this.$store.state.order.headOffset, this.$store.state.order.host);
  },
  methods: {
    previousStep() {
      if (this.$store.state.order.container && this.$store.state.order.host) {
        Scroll.sendScroll(
        this.$store.state.order.container,
        this.$store.state.order.headOffset,
        this.$store.state.order.host
      );
      }else {
        document.getElementById('easy-rent-app').scrollIntoView({ behavior: 'smooth' });
      }
      GTMMessage.changeStep('rentalcarlist', 'previous', this.$store.state.order.host,this.$store.state.order.traceId);
      this.$store.commit("step/PREVIOUS");
    },
      selectCar(car){
     this.$store.dispatch("order/SETANDNEXT", car.id);
    }
  },
  computed: {
    ...mapGetters({
      carList: "company/getCarList",
      vehicleTypes: "company/vehicleTypes",
      monthsView: "order/getMonthsView",
      selectCarFromBruttoList: "order/getSelectCarFromBruttoList",
    }),
    hasCarType() {
      return this.cars === undefined || this.cars?.length > 0;
    },
    vehiclesInFocus(){
     return this.selectCarFromBruttoList ? [this.selectCarFromBruttoList.id]: [];
    }
  },
};
</script>
