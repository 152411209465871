var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"payment-view",attrs:{"cols":"12","md":"7"}},[(_vm.showInquiry)?_c('h1',{staticClass:"text-center mb-4"},[_vm._v(" "+_vm._s(_vm.$t("step4.title_inquiry"))+" ")]):_c('h1',{staticClass:"text-center mb-4"},[_vm._v(_vm._s(_vm.$t("step4.title")))]),(_vm.showInquiry == false)?_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("step4.subtitle"))+" ")]):_vm._e(),(_vm.createContractProcess.error)?_c('Er-ErrorMsg',[_vm._v(_vm._s(_vm.$t("general.server_error")))]):_vm._e(),(
          _vm.createContractProcess.created &&
          (_vm.createContractProcess.choiceType == 'fullamount' ||
            _vm.createContractProcess.choiceType == 'subscription')
        )?_c('v-alert',{attrs:{"outlined":"","prominent":"","type":"success"}},[_c('span',{staticClass:"loading"},[_vm._v(" "+_vm._s(_vm.$t("step4.process_end_message")))])]):_vm._e(),(
          _vm.createContractProcess.created &&
          (_vm.createContractProcess.choiceType == 'inquiry' ||
            _vm.createContractProcess.choiceType == 'payinstore')
        )?_c('v-alert',{attrs:{"outlined":"","prominent":"","type":"success"}},[(_vm.createContractProcess.choiceType == 'inquiry')?_c('span',[_vm._v(" "+_vm._s(_vm.$t("step4.payment_cards.inquiry.inquiry_created")))]):_vm._e(),(_vm.createContractProcess.choiceType == 'payinstore')?_c('span',[_vm._v(" "+_vm._s(_vm.$t("step4.payment_cards.payinstore.created")))]):_vm._e()]):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"payment-options_title text-center"},[_c('v-icon',{attrs:{"color":"black","size":"30"}},[_vm._v("payment")]),_vm._v(" "+_vm._s(_vm.$t("step4.payment_cards.title"))+" ")],1)])],1),_c('v-row',{staticClass:"payment-options",attrs:{"justify":"center"}},[(_vm.showFullamount && !_vm.showInquiry)?_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12","sm":"10","md":"12","lg":"6"}},[_c('PaymentOptionsCard',{attrs:{"title":_vm.$t('step4.payment_cards.fullamount.title'),"text":_vm.$t('step4.payment_cards.fullamount.text'),"confirmBtnText":_vm.$t('step4.payment_cards.fullamount.confirm'),"price":this.$store.state.order.car.priceData.totalprice,"btnDisabled":(_vm.createContractProcess.loading &&
                _vm.createContractProcess.choiceType != 'fullamount') ||
              _vm.createContractProcess.created,"loading":_vm.createContractProcess.loading &&
              _vm.createContractProcess.choiceType == 'fullamount',"disclaimerText":_vm.$t('step4.payment_cards.fullamount.disclaimer')},on:{"onBookingClick":function($event){return _vm.onCreateContractClick('fullamount')}}})],1):_vm._e(),(_vm.showInquiry)?_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12","sm":"10","md":"12","lg":"6"}},[_c('PaymentOptionsCard',{attrs:{"title":_vm.$t('step4.payment_cards.inquiry.title'),"text":_vm.$t('step4.payment_cards.inquiry.text'),"confirmBtnText":_vm.$t('step4.payment_cards.inquiry.confirm'),"price":this.$store.state.order.car.priceData.totalprice,"btnDisabled":(_vm.createContractProcess.loading &&
                _vm.createContractProcess.choiceType != 'inquiry') ||
              _vm.createContractProcess.created,"loading":_vm.createContractProcess.loading &&
              _vm.createContractProcess.choiceType == 'inquiry',"disclaimerText":_vm.$t('step4.payment_cards.inquiry.disclaimer')},on:{"onBookingClick":function($event){return _vm.onInquiryClick()}}})],1):_vm._e(),(_vm.showSubscription)?_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12","sm":"10","md":"12","lg":"6"}},[_c('PaymentOptionsCard',{attrs:{"title":_vm.$t('step4.payment_cards.subscription.title'),"text":_vm.$t('step4.payment_cards.subscription.text'),"confirmBtnText":_vm.$t('step4.payment_cards.subscription.confirm'),"price":this.$store.state.order.car.priceData.subscriptionprice,"addetPriceText":_vm.$t('component.asidecar.pr_month_short'),"additionalPriceText":_vm.car.priceData.totalprice != _vm.car.priceData.subscriptiontotalprice
                ? _vm.$t('step4.payment_cards.subscription.additionalPriceText-payment') +
                  _vm.formatPrice(
                    _vm.car.priceData.totalprice - _vm.car.priceData.subscriptiontotalprice
                  )
                : '',"btnDisabled":(_vm.createContractProcess.loading &&
                _vm.createContractProcess.choiceType != 'subscription') ||
              _vm.createContractProcess.created,"loading":_vm.createContractProcess.loading &&
              _vm.createContractProcess.choiceType == 'subscription',"disclaimerText":_vm.$t('step4.payment_cards.subscription.disclaimer')},on:{"onBookingClick":function($event){return _vm.onCreateContractClick('subscription')}}})],1):_vm._e(),(_vm.showPayInStore && !_vm.showInquiry)?_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12","sm":"10","md":"12","lg":"6"}},[_c('PaymentOptionsCard',{attrs:{"title":_vm.$t('step4.payment_cards.payinstore.title'),"text":_vm.$t('step4.payment_cards.payinstore.text'),"confirmBtnText":_vm.$t('step4.payment_cards.payinstore.confirm'),"price":this.$store.state.order.car.priceData.totalprice,"addetPriceText":'',"btnDisabled":(_vm.createContractProcess.loading &&
                _vm.createContractProcess.choiceType == 'payinstore') ||
              _vm.createContractProcess.created,"loading":_vm.createContractProcess.loading &&
              _vm.createContractProcess.choiceType == 'payinstore',"created":_vm.createContractProcess.created == false,"disclaimerText":_vm.$t('step4.payment_cards.payinstore.disclaimer')},on:{"onBookingClick":function($event){return _vm.onPayInStoreClick()}}})],1):_vm._e()],1)],1),_c('v-col',{staticClass:"d-none d-sm-flex justify-center",attrs:{"cols":"1","justify":"center"}},[_c('v-divider',{attrs:{"vertical":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ChosenCompact',{attrs:{"car":_vm.car}})],1)],1),_c('v-row',{staticClass:"mt-6"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"block":"","x-large":"","rounded":"","disabled":_vm.createContractProcess.created},on:{"click":_vm.previousStep}},[_vm._v(" "+_vm._s(_vm.$t("general.back"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }