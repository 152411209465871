const environment = process.env.NODE_ENV;

const post = (dataLayerData, host) => {
    dataLayerData.event = "easyrent-event";
    let currentHost = host;
    if (environment === 'development' || host === '') {
      currentHost = document.location.origin;
    }
    parent.postMessage({ action: 9, namespace: 'bt', data: dataLayerData }, currentHost);
    return true;
  };
  
  const changeStep = (action, category , host, uniqueid) => {
    var obj = {
        uniqueid: uniqueid,
        eventAction: action,
        eventCategory: category
    }
     post(obj, host);
    return true;
  };



  export default {
    post,
    changeStep
  };
  