<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="7" class="payment-view">
        <h1 v-if="showInquiry" class="text-center mb-4">
          {{ $t("step4.title_inquiry") }}
        </h1>
        <h1 class="text-center mb-4" v-else>{{ $t("step4.title") }}</h1>
        <p v-if="showInquiry == false" class="text-center">
          {{ $t("step4.subtitle") }}
        </p>
        <Er-ErrorMsg v-if="createContractProcess.error">{{
          $t("general.server_error")
        }}</Er-ErrorMsg>

        <v-alert
          v-if="
            createContractProcess.created &&
            (createContractProcess.choiceType == 'fullamount' ||
              createContractProcess.choiceType == 'subscription')
          "
          outlined
          prominent
          type="success"
        >
          <span class="loading"> {{ $t("step4.process_end_message") }}</span>
        </v-alert>
        <v-alert
          v-if="
            createContractProcess.created &&
            (createContractProcess.choiceType == 'inquiry' ||
              createContractProcess.choiceType == 'payinstore')
          "
          outlined
          prominent
          type="success"
        >
          <span v-if="createContractProcess.choiceType == 'inquiry'">
            {{ $t("step4.payment_cards.inquiry.inquiry_created") }}</span
          >
          <span v-if="createContractProcess.choiceType == 'payinstore'">
            {{ $t("step4.payment_cards.payinstore.created") }}</span
          >
        </v-alert>

        <v-row>
          <v-col cols="12">
            <h2 class="payment-options_title text-center">
              <v-icon color="black" size="30">payment</v-icon>
              {{ $t("step4.payment_cards.title") }}
            </h2>
          </v-col>
        </v-row>

        <v-row class="payment-options" justify="center">
          <v-col
            v-if="showFullamount && !showInquiry"
            cols="12"
            sm="10"
            md="12"
            lg="6"
            class="d-flex flex-column"
          >
            <PaymentOptionsCard
              :title="$t('step4.payment_cards.fullamount.title')"
              :text="$t('step4.payment_cards.fullamount.text')"
              :confirmBtnText="$t('step4.payment_cards.fullamount.confirm')"
              :price="this.$store.state.order.car.priceData.totalprice"
              :btnDisabled="
                (createContractProcess.loading &&
                  createContractProcess.choiceType != 'fullamount') ||
                createContractProcess.created
              "
              :loading="
                createContractProcess.loading &&
                createContractProcess.choiceType == 'fullamount'
              "
              :disclaimerText="$t('step4.payment_cards.fullamount.disclaimer')"
              @onBookingClick="onCreateContractClick('fullamount')"
            ></PaymentOptionsCard>
          </v-col>

          <v-col
            v-if="showInquiry"
            cols="12"
            sm="10"
            md="12"
            lg="6"
            class="d-flex flex-column"
          >
            <PaymentOptionsCard
              :title="$t('step4.payment_cards.inquiry.title')"
              :text="$t('step4.payment_cards.inquiry.text')"
              :confirmBtnText="$t('step4.payment_cards.inquiry.confirm')"
              :price="this.$store.state.order.car.priceData.totalprice"
              :btnDisabled="
                (createContractProcess.loading &&
                  createContractProcess.choiceType != 'inquiry') ||
                createContractProcess.created
              "
              :loading="
                createContractProcess.loading &&
                createContractProcess.choiceType == 'inquiry'
              "
              :disclaimerText="$t('step4.payment_cards.inquiry.disclaimer')"
              @onBookingClick="onInquiryClick()"
            ></PaymentOptionsCard>
          </v-col>

          <v-col
            v-if="showSubscription"
            cols="12"
            sm="10"
            md="12"
            lg="6"
            class="d-flex flex-column"
          >
            <PaymentOptionsCard
              :title="$t('step4.payment_cards.subscription.title')"
              :text="$t('step4.payment_cards.subscription.text')"
              :confirmBtnText="$t('step4.payment_cards.subscription.confirm')"
              :price="this.$store.state.order.car.priceData.subscriptionprice"
              :addetPriceText="$t('component.asidecar.pr_month_short')"
              :additionalPriceText="
                car.priceData.totalprice != car.priceData.subscriptiontotalprice
                  ? $t('step4.payment_cards.subscription.additionalPriceText-payment') +
                    formatPrice(
                      car.priceData.totalprice - car.priceData.subscriptiontotalprice
                    )
                  : ''
              "
              :btnDisabled="
                (createContractProcess.loading &&
                  createContractProcess.choiceType != 'subscription') ||
                createContractProcess.created
              "
              :loading="
                createContractProcess.loading &&
                createContractProcess.choiceType == 'subscription'
              "
              :disclaimerText="$t('step4.payment_cards.subscription.disclaimer')"
              @onBookingClick="onCreateContractClick('subscription')"
            ></PaymentOptionsCard>
          </v-col>

          <v-col
            v-if="showPayInStore && !showInquiry"
            cols="12"
            sm="10"
            md="12"
            lg="6"
            class="d-flex flex-column"
          >
            <PaymentOptionsCard
              :title="$t('step4.payment_cards.payinstore.title')"
              :text="$t('step4.payment_cards.payinstore.text')"
              :confirmBtnText="$t('step4.payment_cards.payinstore.confirm')"
              :price="this.$store.state.order.car.priceData.totalprice"
              :addetPriceText="''"
              :btnDisabled="
                (createContractProcess.loading &&
                  createContractProcess.choiceType == 'payinstore') ||
                createContractProcess.created
              "
              :loading="
                createContractProcess.loading &&
                createContractProcess.choiceType == 'payinstore'
              "
              :created="createContractProcess.created == false"
              :disclaimerText="$t('step4.payment_cards.payinstore.disclaimer')"
              @onBookingClick="onPayInStoreClick()"
            ></PaymentOptionsCard>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="1" justify="center" class="d-none d-sm-flex justify-center">
        <v-divider vertical></v-divider>
      </v-col>
      <v-col cols="12" md="4">
        <ChosenCompact :car="car"></ChosenCompact>
      </v-col>
    </v-row>

    <v-row class="mt-6">
      <v-col cols="4">
        <v-btn
          block
          x-large
          rounded
          :disabled="createContractProcess.created"
          @click="previousStep"
        >
          {{ $t("general.back") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import ChosenCompact from "../components/ChosenCompact.vue";

import PaymentOptionsCard from "../components/Fragments/PaymentOptionsCard.vue";
import { priceHelperMixin } from "../Helpers/price";
import Scroll from "@/Helpers/Scroll";
import { DateTime, Settings as LuxonSettings } from "luxon";
import GTMMessage from "@/Helpers/GTMMessage";

export default {
  name: "Step4",
  components: { ChosenCompact, PaymentOptionsCard },
  mixins: [priceHelperMixin],
  mounted() {
    LuxonSettings.defaultLocale = "en";

    Scroll.sendScroll(this.$store.state.order.container, this.$store.state.order.headOffset, this.$store.state.order.host);
  },
  data() {
    return {
      paymentUrl: "",
    };
  },
  methods: {
    onInquiryClick() {
      const dataLayerData = {
        uniqueid: this.$store.state.order.traceId,
        eventAction: "inquiry-click",
        eventCategory: "confirmation",
      };
      GTMMessage.post(dataLayerData, this.$store.state.order.host);
      this.$store.dispatch("order/CREATE_INQUIRY", {
        loading: true,
        choiceType: "inquiry",
      });
    },
    onPayInStoreClick() {
      const dataLayerData = {
        uniqueid: this.$store.state.order.traceId,
        eventAction: "payinstore-click",
        eventCategory: "confirmation",
      };
      GTMMessage.post(dataLayerData, this.$store.state.order.host);
      this.$store.dispatch("order/CREATE_PAYINSTORE", {
        loading: true,
        choiceType: "payinstore",
      });
    },
    onCreateContractClick(type) {
      const dataLayerData = {
        uniqueid: this.$store.state.order.traceId,
        eventAction: "create-contract-click",
        eventCategory: "confirmation",
        contractType: type,
      };
      GTMMessage.post(dataLayerData, this.$store.state.order.host);

      this.$store.dispatch("order/CREATE_CONTRACT", {
        loading: true,
        choiceType: type,
      });
    },
    previousStep() {
      GTMMessage.changeStep("confirmation", "previous", this.$store.state.order.host, this.$store.state.order.traceId);
      this.$store.commit("step/PREVIOUS");
    },
  },
  watch: {
    payment: {
      deep: true,
      handler(val) {
        this.paymentUrl = val;
        setTimeout(() => {
          window.location.replace(val);
        }, 2000);
      },
    },
  },
  computed: {
    showInquiry: {
      get() {
        if (this.$store.state.order.pickupDate && this.$store.state.order.pickupTime) {
          const now = DateTime.local();
          const then = DateTime.fromISO(
            this.$store.state.order.pickupDate + "T" + this.$store.state.order.pickupTime,
            { locale: "en" }
          );

          const diff = then.diff(now, ["days"]);
          return diff.days <= 1;
        }
        return false;
      },
    },
    showSubscription: {
      get() {
        if (
          this.$store.state.order.onlinepayment == true &&
          this.$store.state.order.car.priceData.numberofsubscriptionperiods > 1 &&
          this.$store.state.order.disableOnlinePayment == false
        )
          return true;

        return false;
      },
    },
    showFullamount: {
      get() {
        // if (this.$store.state.company.test == true) return false;

        if (
          this.$store.state.order.onlinepayment == true &&
          this.$store.state.order.companysettings.forceabonnement &&
          this.$store.state.order.car.priceData.numberofsubscriptionperiods > 1
        )
          return false;

        if (this.$store.state.order.disableOnlinePayment == true) {
          return false;
        }
        return true;
      },
    },
    showPayInStore: {
      get() {
        return this.$store.state.order.payinstoreoption;
      },
    },
    payment: {
      get() {
        return this.$store.state.order.payment.paymenturl;
      },
    },
    ...mapState({ car: (state) => state.order.car }),
    ...mapGetters({
      createContractProcess: "order/contractProcess",
    }),
  },
};
</script>
<style lang="scss" scoped>
.payment-options {
  align-content: center;
  .discount {
    position: absolute;
    top: 1em;
    right: 1em;
  }
  .price-list {
    flex-grow: 0;
    margin-top: auto;
  }
}
</style>
