import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import store from "./store/store";
import router from './router';
import VueGtm from 'vue-gtm';
import parameters from "./Helpers/contentdataParameters.js";

import './Helpers/sortDa.js';

Vue.config.productionTip = false;

const gtmid = parameters.data.gtmid ||  'GTM-5ZSPN5K' ;
const gtmDebug = process.env.NODE_ENV === 'development' ? true : false;
if (gtmid) {
  Vue.use(VueGtm, {
    id: gtmid,
    debug: gtmDebug,
    vueRouter: router
  });
}

import ER_ErrorMsg from '@/components/Fragments/EasyRentErrorMsg'
Vue.component('Er-ErrorMsg', ER_ErrorMsg)

new Vue({
  vuetify,
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");
