<template>
  <div class="brutto-list" v-if="vehicles.length > 0">
    <template>
      <CarGrid :car-list="filteredItems" :vehicle-types="vehicleTypes" :months-view="restrictToPeriods"
        @selected="selectCar" :is-brutto-list="true">
        <template #menuend>
          <div :class="['show-only-available-switch', { 'showavailablenow': showavailablenow }]">
            <span @click="showavailablenow = true" :class="{ 'primary--text': showavailablenow }">{{
              $t("step0.showAvailableSwitch") }}</span>
            <v-switch v-model="showavailablenow"></v-switch>
            <span @click="showavailablenow = false" :class="{ 'primary--text': !showavailablenow }">{{
              $t("step0.showAllSwitch") }}</span>
          </div>
        </template>
      </CarGrid>
    </template>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import { priceHelperMixin } from "../Helpers/price";
import GTMMessage from "@/Helpers/GTMMessage";
import CarGrid from "./CarGrid";
import { bruttolistvehicles } from "../Helpers/apiDataHelper";

export default Vue.component("BruttoListOfVehicles", {
  components: { CarGrid },
  props: [],
  mixins: [priceHelperMixin],
  data() {
    return {
      showavailablenow: true,
      vehicles: [],
    };
  },
  async beforeMount() {
    const bruttolistReq = await bruttolistvehicles(
      this.$store.state.order.companyId,
      null,
      this.$store.state.order.traceId
    );
    let bruttolist = bruttolistReq.data;
    if (
      this.$store.state.order.forcelocations.force ||
      this.$store.state.order.forceofficeid.force
    ) {
      var id =
        this.$store.state.order.forcelocations.id ||
        this.$store.state.order.forceofficeid.id;
      bruttolist = bruttolist.filter((x) => x.branchofficeoid == id);
    }
    bruttolist = bruttolist.map((x) => {
      x.vehiclemodel = {
        name: x.carmodelname,
        vehiclebrand: {
          name: x.carbrandname,
        },
      };
      x.vehicleclass = {
        name: x.carclassname,
      };
      x.reducedData = true;
      x.hasDetailedPrice = true;
      x.image = x.pictureurl;
      x.shouldHide = false;
      // x.isavailablenow = x.id % 2 == 0 ? false : true; // for testing
      return x;
    });

    this.vehicles = bruttolist;
  },
  mounted() { },
  computed: {
    ...mapGetters({
      // carList: "company/getCarList",
      vehicleTypes: "company/vehicleTypes",
      monthsView: "order/getMonthsView", // after user input
      restrictToPeriods: "order/getRestrictToPeriods", // before user input
    }),
    filteredItems() {
      return this.vehicles.filter((item) => {
        const showIfAvailable = this.showavailablenow
          ? item.isavailablenow == this.showavailablenow
          : true;
        if (this.restrictToPeriods == true && item.priceprmonthincltax > 0 && showIfAvailable)
          return item;
        else if (
          this.restrictToPeriods == false &&
          item.priceprday > 0 &&
          showIfAvailable
        )
          return item;
      });
    },
  },
  methods: {
    selectCar(car) {
      const dataLayerData = {
        uniqueid: this.$store.state.order.traceId,
        eventAction: "selected",
        eventCategory: "rentalcarlist",
        rentalcarId: car.id,
        rentalcarClass: car.vehicleclass ? car.vehicleclass.name : "",
        rentalcarModel: car.vehiclemodel ? car.vehiclemodel.name : "",
        rentalcarBrand:
          car.vehiclemodel && car.vehiclemodel.vehiclebrand
            ? car.vehiclemodel.vehiclebrand.name
            : "",
      };

      GTMMessage.post(dataLayerData, this.$store.state.order.host);

      this.$emit("selectCar", car);
    },
  },
});
</script>

<style lang="scss">
.brutto-list {
  .show-only-available-switch {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    &.showavailablenow {

      .v-input--selection-controls__ripple,
      .v-input--switch__thumb {
        transform: translate(0, 0) !important;
      }
    }

    .v-input--selection-controls__ripple,
    .v-input--switch__thumb {
      transform: translate(20px, 0) !important;
    }

    // .v-input--selection-controls {
    //   margin-top: 0;
    //   padding-top: 0;
    // }


    .v-input--switch,
    .v-input--selection-controls__ripple,
    .v-input--switch__track,
    .v-input--switch__thumb {
      color: var(--v-primary-base) !important;
      caret-color: var(--v-primary-base) !important;
    }
  }

  .car-grid {
    .menu-items {
      justify-content: start;
      margin-top: 2rem;
      margin-bottom: 0px;
      padding-bottom: 0px;

      .show-only-available-switch {
        margin-left: auto;
        margin-right: 1rem;

        .v-input__slot {
          flex-direction: row-reverse;

          .v-label {
            margin-right: 14px;
          }
        }
      }
    }
  }
}
</style>
