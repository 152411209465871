<template>
  <v-row class="available-months-options">
    <v-col cols="9">
      <Datepicker :key="pickupDate" :label="!pickupDate
        ? $t('component.availableMonthsOptions.pickupDate')
        : $t('step0.input.pickupTitle')
        " :loading.sync="loadingstates.pickupDate" v-model="pickupDate" @monthchange="monthchangepickup" no-title
        required :rules="[(v) => !!v || $t('validation.required')]"
        :disabled="$store.state.company.availableStartDays.length == 0" :allowed-dates="pickupAllowedDates" />
    </v-col>
    <v-col cols="3">
      <v-select :items="periodesOptions" v-model="periodeChosenKey" @change="onChangePeriode" item-value="value"
        item-text="text" :disabled="periodesOptions == null || !pickupDate" required
        :label="$t('component.availableMonthsOptions.periodesOptionslabel')"
        :rules="[(v) => v !== undefined || $t('validation.required')]">
      </v-select>
    </v-col>
    <v-col cols="12" v-if="$store.state.order.periodesOptionsError">
      {{ $t("component.availableMonthsOptions.periodesOptionsError") }}
    </v-col>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { DateTime } from "luxon";

import { GetAvailablePeriods } from "../Helpers/apiDataHelper";

import Datepicker from "./Fragments/Datepicker";
export default {
  name: "AvailableMonthsOptions",
  mixins: [],
  components: { Datepicker },
  props: {},
  data: function () {
    return {};
  },
  methods: {
    monthchangepickup(date) {


      this.$store.dispatch("company/GET_MONTHCHANGE_PICKUP", date);
    },
    async onChangePeriode() {
      const openingHours = this.getPickupOpeninghoursByDate(
        this.$store.state.order.pickupDate
      );
      const pickupDateTime = DateTime.fromISO(
        `${this.$store.state.order.pickupDate}T${openingHours[0]}`
      );
      let selectCarFromBruttoListId = this.$store.state.order.selectCarFromBruttoList ? this.$store.state.order.selectCarFromBruttoList.id : null;

      // If periodeChosenKey starts with an m, its a month. If it starts with a u, its a week.
      const isMonth = this.periodeChosenKey.substring(0, 1) == "m";

      const params = [
        this.$store.state.order.companyId,
        this.$store.state.order.traceId,
        pickupDateTime,
        [Number(this.periodeChosenKey.substring(1))],
        this.$store.state.order.deliveryTimefromRentlog,
        true,
        this.officeId,
        selectCarFromBruttoListId,
        isMonth,
      ];
      const _periodesOptions = await GetAvailablePeriods(...params);

      if (
        _periodesOptions.error == false &&
        _periodesOptions.data &&
        _periodesOptions.data.hasperiodes
      ) {
        this.$store.commit("order/SET_PERIODES_OPTIONS_ERROR", false);

        let periodeChosen = null;
        if(_periodesOptions.data.periodesofmonths){
          periodeChosen = _periodesOptions.data.periodesofmonths[0];
        } else if(_periodesOptions.data.periodesofweeks){
          periodeChosen = _periodesOptions.data.periodesofweeks[0];
        }
        const startDate = periodeChosen.startdate.substring(0, 10);
        const startTime = periodeChosen.startdate.substring(11, 16);
        const endDate = periodeChosen.enddate.substring(0, 10);
        const endTime = periodeChosen.enddate.substring(11, 16);
        this.$store.dispatch("order/SET_PICKUP_AND_DELIVERY", {
          pickupTime: startTime,
          pickupDate: startDate,
          deliveryTime: endTime,
          deliveryDate: endDate,
        });
        this.$store.dispatch("order/GET_SET_DAYSTOPAY");
      } else {
        this.$store.commit("order/SET_PERIODES_OPTIONS_ERROR", true);
        this.$store.dispatch("order/SET_PICKUP_AND_DELIVERY", {
          deliveryTime: "",
          deliveryDate: "",
        });
      }
    },
  },
  computed: {
    periodesOptions() {
      let po = [];

      this.periodsFromProp.forEach(period => {
        const unitFirstLetter = period.unit?.substring(0, 1);
        po.push({ text: `${period.value} ${period.unit}`, value: unitFirstLetter + period.value });
      });

      return po;
    },
    periodeChosenKey: {
      get() {
        return this.$store.state.order.periodeChosenKey;
      },
      set(value) {
        this.$store.dispatch("order/setPeriodeChosenKey", value);
      },
    },
    pickupDate: {
      get() {
        return this.$store.state.order.pickupDate;
      },
      async set(value) {
        this.$store.commit("order/SET_PERIODES_OPTIONS_ERROR", false);

        this.$store.dispatch("order/setPeriodeChosenKey", undefined);

        this.$store.dispatch("order/SET_PICKUP_AND_DELIVERY", {
          pickupTime: null,
          pickupDate: value,
          deliveryTime: null,
          deliveryDate: null,
        });
      },
    },
    ...mapState({}),
    ...mapGetters({
      loadingstates: "company/loadingstates",
      pickupAllowedDates: "company/isPickupdayAllowed",
      getPickupOpeninghoursByDate: "company/getPickupOpeninghoursByDate",
      officeId: "order/getOfficeId",
      periodsFromProp: "order/getPeriodesOptions",
    }),
  },
};
</script>
<style lang="scss" scoped></style>
