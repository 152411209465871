<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="4" align-self="center">
        <v-row justify="center">
          <CarIcon class="caricon"></CarIcon>
        </v-row>
      </v-col>
      <v-col cols="1" class="d-none d-sm-flex justify-center">
        <v-divider vertical></v-divider>
      </v-col>
      <v-col cols="12" sm="7" justify="center">
        <Er-ErrorMsg
          v-if="$store.state.company.apperror.departments || $store.state.order.daysAndMonthsToPayError || $store.state.company.apperror.operationalError">{{
            $t("general.server_error")
          }}</Er-ErrorMsg>

        <v-form ref="form" id="step1form" lazy-validation>
          <h1 class="header">{{ $t("step0.title") }}</h1>
          <p class="header-sub">{{ $t("step0.subtitle") }}</p>
          <v-row v-if="selectCarFromBruttoList">
            <v-col>
              <span class="mr-3">{{ $t("step0.selectCarFromBrutto") }}</span>
              <v-chip class="mr-3">{{ selectCarFromBruttoList.carbrandname }}
                {{ selectCarFromBruttoList.carmodelname }}</v-chip>
              <v-btn color="primary" depressed elevation="2" icon x-small
                @click="deselect_CarFromBruttoList"><v-icon>close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="locationsOptions && !loadingstates.locations">
            <v-col cols="12">
              <v-select :items="locationsOptions" v-model="locationsStart" item-value="name" item-text="name" :disabled="$store.state.order.forcelocations.force &&
                $store.state.order.forcelocations.id > 0
                " required prepend-inner-icon="place" :label="$t('step0.input.place')"
                :rules="[(v) => v !== undefined || $t('validation.required')]" :no-data-text="$t('step0.input.noDataText')" @input="setSelectedLocationOrCity(locationsStart)"></v-select>
            </v-col>
          </v-row>
          <v-row v-else-if="cityOptions && !loadingstates.departments">
            <v-col cols="12">
              <v-select :items="cityOptions" v-model="city" item-value="id" item-text="city" :disabled="$store.state.order.forceofficeid.force &&
                $store.state.order.forceofficeid.officeid > 0
                " required prepend-inner-icon="place" :label="$t('step0.input.place')"
                :rules="[(v) => v !== undefined || $t('validation.required')]" :no-data-text="$t('step0.input.noDataText')" @input="setSelectedLocationOrCity(city)"></v-select>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12">
              <v-select :disabled="true" prepend-inner-icon="place" :label="$t('step0.input.place')"
                :rules="[(v) => v !== undefined || $t('validation.required')]"></v-select>
            </v-col>
          </v-row>

          <v-row
            v-if="$store.state.order.restrictToPeriods == false && $store.state.order.periodsFromProp && $store.state.order.showPeriodsView">
            <v-col>
              <v-checkbox class="showMeMonthsOptions" v-model="chosenShowMonthsOptions"
                :label="$t('step0.periodsMonthsOptionsLabel')"
                :messages="$t('step0.periodsMonthsOptionsText')"></v-checkbox>
              <br>
            </v-col>
          </v-row>
          <template v-if="$store.state.order.restrictToPeriods || chosenShowMonthsOptions">
            <AvailableMonthsOptions></AvailableMonthsOptions>
            <!-- <pre>
             periodesOptionsError {{$store.state.order.periodesOptionsError }}
            officeInfo  {{ typeof officeInfo  }}
            officeInfoisEmpty {{officeInfoisEmpty}}
            officeInfo  {{Object.keys(officeInfo|| {}).length === 0    }}
            locationsStart {{locationsStart}}
            pickupDate  {{pickupDate }}
            deliveryDate  {{deliveryDate }}
            periodeChosenKey  {{$store.state.order.periodeChosenKey}}
            </pre> -->
            <v-row
              v-if="!$store.state.order.periodesOptionsError && (officeInfo || locationsStart) && pickupDate && deliveryDate && $store.state.order.periodeChosenKey">
              <v-col>
                <p v-if="officeInfoisEmpty == false" v-html="$t(
                  'step0.periodeInfoText', {
                    address: officeInfo.address,
                    city: officeInfo.city,
                    pickupDate: formatDate(pickupDate),
                    deliveryDate: formatDate(deliveryDate),
                    periodeChosenKey: Number($store.state.order.periodeChosenKey.substring(1)),
                    periodLabel: $store.state.order.periodeChosenKey.substring(0,1) === 'm' ? Number($store.state.order.periodeChosenKey.substring(1)) > 1 ? $t('general.months_plural') : $t('general.months_singular') : Number($store.state.order.periodeChosenKey.substring(1)) > 1 ? $t('general.weeks_plural') : $t('general.weeks_singular')
                  }
                  // TODO -> periodLabel: This should come from same label, separated with a pipe, but it doesn't work atm
                )
                  "></p>
                <p v-else v-html="$t(
                  'step0.periodeInfoTextlocationsStart', {
                  city: locationsStart,
                  pickupDate: formatDate(pickupDate),
                  deliveryDate: formatDate(deliveryDate),
                  periodeChosenKey: $store.state.order.periodeChosenKey
                }
                )
                  "></p>
              </v-col>
            </v-row>
          </template>

          <template v-if="$store.state.order.restrictToPeriods == false
            && !chosenShowMonthsOptions">
            <v-row>
              <v-col class="py-0" v-if="this.deliveryTime
                && this.deliveryDate
                && this.pickupTime
                && this.pickupDate">
                <div v-if="monthsView && daysAndMonths">
                  <p v-if="daysAndMonths.fullmonths > 0">
                    {{ daysAndMonths.fullmonths }} {{ $tc("general.months", daysAndMonths.fullmonths) }}
                    <span v-if="daysAndMonths.fullmonthsdaysleft">
                      {{ $t("general.and") }}
                      {{ daysAndMonths.fullmonthsdaysleft }}
                      {{ $tc("general.days", daysAndMonths.fullmonthsdaysleft) }}
                    </span>
                    {{ $t("general.is_chosen") }}
                  </p>
                </div>
                <div v-else-if="!monthsView && daysToPay">
                  <p>{{ $tc("step0.count_total_days", daysToPay) }}</p>
                </div>

                <!-- <div v-if="daysAndMonths != null" >
               <p v-if="daysAndMonths.fullmonths > 0" class="mb-0">
                  {{daysAndMonths.fullmonths}} {{ $tc("general.months", daysAndMonths.fullmonths ) }}
                  <span v-if="daysAndMonths.fullmonthsdaysleft">
                    {{ $t("general.and") }}
                    {{daysAndMonths.fullmonthsdaysleft}} 
                    {{ $tc("general.days", daysAndMonths.fullmonthsdaysleft ) }}
                  </span>
                  {{ $t("general.is_chosen") }}
                </p>
                <p v-else>
                  {{ $tc("step0.count_total_days", daysToPay  ) }}
                </p>
              </div> -->
              </v-col>
            </v-row>
            <PickupAndDeliveryOptions></PickupAndDeliveryOptions>
          </template>

          <v-btn block rounded x-large color="primary" :disabled="!hunkyDoryCheck" @click="nextStep">{{
            $store.state.order.showBruttoList ? $t("step0.btn.next_brutto") : $t("step0.btn.next") }}</v-btn>
        </v-form>
      </v-col>
      <v-col v-if="$store.state.order.showBruttoList">
        <BruttoListOfVehicles @selectCar="onSelectCar"></BruttoListOfVehicles>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import AvailableMonthsOptions from "../components/AvailableMonthsOptions";
import PickupAndDeliveryOptions from "../components/PickupAndDeliveryOptions.vue";
import BruttoListOfVehicles from "../components/BruttoListOfVehicles.vue";
import CarIcon from "../assets/Icons/CarIcon.vue";
import Scroll from "@/Helpers/Scroll";
import GTMMessage from "@/Helpers/GTMMessage";
import { sortDa } from "@/Helpers/sortDa";
import { DateTime, Settings as LuxonSettings } from "luxon";

export default {
  name: "Step0",
  components: { PickupAndDeliveryOptions, CarIcon, AvailableMonthsOptions, BruttoListOfVehicles },
  data: function () {
    return {

    };
  },
  mounted() {
    LuxonSettings.defaultLocale = "en";
  },
  computed: {
    locationsOptions() {
      const startlocations = this.$store.state.company.locations.startlocations;
      if (this.selectCarFromBruttoList) {
        const locations = startlocations.filter((item) => {
          if (!item.branchoffice || (item.branchoffice && item.branchoffice.id == this.selectCarFromBruttoList.branchofficeoid)) {
            return item;
          }
        }).map(x => x.name);

        return sortDa(locations)
      }

      return sortDa(startlocations.map(x => x.name));
    },
    cityOptions() {
      
      const departments = this.$store.state.company.departments;
      let sortedCities = [];
      // Wanted return format:  [ { "id": 18, "city": "Aarhus" }, { "id": 19, "city": "Silkeborg " } ]
      
      if (this.selectCarFromBruttoList) {
        const locations = departments.filter((item) => {
          return item.id == this.selectCarFromBruttoList.branchofficeoid;
        }).map(x => x.name);

        sortedCities = sortDa(locations);
      } else {
        sortedCities = sortDa(departments.map(x => x.city));
      }

      return sortedCities.map(x => {
        return { id: departments.find(y => y.city == x).id, city: x }
      });
    },
    daysAndMonths: {
      get() {
        return this.$store.state.order.daysAndMonthsToPay;
      },
    },

    minimumRentalPeriod: {
      get() {
        const pickupDate = this.$store.state.order.pickupDate;
        const period =
          this.$store.getters["order/getMinimumRentalPeriodDate"](pickupDate);
        return period;
      },
    },
    chosenShowMonthsOptions: {
      get() {
        return this.$store.state.order.chosenShowMonthsOptions;
      },
      set(value) {
        this.$store.commit("order/SET_PERIODES_OPTIONS_ERROR", false);
        this.$store.dispatch("order/setChosenShowMonthsOptions", value);
      }
    },
    hunkyDoryCheck() {

      let daysORMonthsToPayIsOk = false;
      if (this.monthsView) {
        daysORMonthsToPayIsOk = this.minimumRentalPeriodText.value <= this.$store.state.order.daysAndMonthsToPay.totaldays;
      } else {
        daysORMonthsToPayIsOk = this.minimumRentalPeriodText.value <= this.daysToPay;
      }

      return (
        !this.$store.state.order.periodesOptionsError &&
        this.deliveryTime &&
        this.deliveryDate &&
        this.pickupTime &&
        this.pickupDate &&
        this.$store.state.order.daysAndMonthsToPay &&
        daysORMonthsToPayIsOk &&
        (this.locationsStart || this.city)
      );
    },
    isTimeSpanIn24: {
      get() {
        if (
          this.$store.state.order.pickupDate &&
          this.$store.state.order.pickupTime
        ) {
          //   const pickupTime = this.$store.state.order.pickupTime;

          const now = DateTime.local();
          const then = DateTime.fromISO(
            this.$store.state.order.pickupDate +
            "T" +
            this.$store.state.order.pickupTime
          );

          // todo fejl med dk / en ?
          const diff = then.diff(now, ["days"]);

          return diff.days <= 1;
        }
        return false;
      },
    },
    locationsStart: {
      get() {
        return this.$store.state.order.startlocation;
      },
      set(value) {
        this.resetTimesAndDates();

        this.$store.dispatch("order/SET_STARTLOCATION", value);
      },
    },
    city: {
      get() {
        if (this.$store.state.order.department) {
          const department = this.$store.state.order.department;
          return { id: department.id, city: department.city };
        }
        return null;
      },
      set(value) {
        this.resetTimesAndDates();

        this.$store.dispatch("order/SET_DEPARTMENT", value);
      },
    },
    pickupDate: {
      get() {
        return this.$store.state.order.pickupDate;
      },
      set(value) {
        this.$store.dispatch("order/SET_PICKUP_DATE", value);

        this.$store.dispatch("order/SET_DELIVERY_DATE", null);

      },
    },
    deliveryDate: {
      get() {
        return this.$store.state.order.deliveryDate;
      },
      set(value) {
        this.$store.dispatch("order/SET_DELIVERY_DATE", value);
        this.$store.dispatch("order/SET_DELIVERY_TIME", null);
      },
    },
    pickupTime: {
      get() {
        return this.$store.state.order.pickupTime;
      },
      set(value) {
        this.$store.dispatch("order/SET_PICKUP_TIME", value);
      },
    },
    deliveryTime: {
      get() {
        return this.$store.state.order.deliveryTime;
      },
      set(value) {
        this.$store.dispatch("order/SET_DELIVERY_TIME", value);
      },
    },
    officeInfoisEmpty: {
      get() {
        return Object.keys(this.officeInfo || {}).length === 0;
      }
    },
    ...mapGetters({
      minimumRentalPeriodText: "order/getMinimumRentalPeriodText",
      loadingstates: "company/loadingstates",
      // cityOptions: "company/cities",
      vehicleTypes: "company/vehicleTypes",
      pickupAllowedDates: "company/isPickupdayAllowed",
      deliveryAllowedDates: "company/isDeliverydayAllowed",
      getDeliveryOpeninghoursByDate: "company/getDeliveryOpeninghoursByDate",
      getPickupOpeninghoursByDate: "company/getPickupOpeninghoursByDate",
      // deliveryTimeRange: "company/deliveryTimeRange", 
      officeInfo: "order/getOfficeInfo",
      selectCarFromBruttoList: "order/getSelectCarFromBruttoList",
      monthsView: "order/getMonthsView",
      daysToPay: "order/daysToPay",
    }),
  },
  methods: {
    setSelectedLocationOrCity(element) {
      const cityname = element.city ? element.city.trim() : element.trim();
      this.$store.dispatch("order/SET_DEPARTMENT_NAME", cityname);
    },
    onSelectCar(car) {
      this.resetTimesAndDates();
      this.pickupDate = null;
      this.$store.dispatch("order/SET_DEPARTMENT", {});


      this.$store.dispatch("order/setSelectCarFromBruttoList", car)
      this.$store.dispatch("order/SET_DEPARTMENT", car.branchofficeoid);


      if (this.$store.state.order.container && this.$store.state.order.host) {
        Scroll.sendScroll(
          this.$store.state.order.container,
          this.$store.state.order.headOffset,
          this.$store.state.order.host
        );
      } else {
        document.getElementById('easy-rent-app').scrollIntoView({ behavior: 'smooth' });
      }

    },
    deselect_CarFromBruttoList() {
      this.resetTimesAndDates();
      this.$store.dispatch("order/setSelectCarFromBruttoList", undefined)
      if (!this.$store.state.order.forcelocations.force || !this.$store.state.order.forceofficeid.force) {
        this.$store.dispatch("order/SET_DEPARTMENT", {});
      }
    },
    formatDate(date) {
      return DateTime.fromISO(date).setLocale(this.$store.state.order.culture).toLocaleString(DateTime.DATE_FULL).toString();
    },
    monthchangepickup(date) {
      this.$store.dispatch("company/GET_MONTHCHANGE_PICKUP", date);
    },
    monthchangedelivery(date) {
      this.$store.dispatch("company/GET_MONTHCHANGE_DELIVERY", date);
    },
    resetTimesAndDates() {


      this.$store.dispatch("order/SET_PICKUP_DATE", null);
      this.$store.dispatch("order/SET_PICKUP_TIME", null);

      this.$store.dispatch("order/SET_DELIVERY_DATE", null);
      this.$store.dispatch("order/SET_DELIVERY_TIME", null);
    },
    nextStep() {
      if (!this.$refs.form.validate()) return;
      GTMMessage.changeStep("home", "next", this.$store.state.order.host, this.$store.state.order.traceId);
      this.$store.dispatch("company/GET_CARS");
    },
  },
};
</script>
<style lang="scss" scoped>
.header,
.header-sub {
  text-align: center;
}

.showMeMonthsOptions .v-messages__message {
  color: lightgray !important;
  ;
}
</style>
<style lang="scss" >
.showMeMonthsOptions .v-messages__message {
  color: gray !important;
  ;
}
</style>