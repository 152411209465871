const state = {
    currentStep: 0,
}

const getters = {

}

const mutations = {
    NEXT(state) {
        state.currentStep++;
    },
    PREVIOUS(state) {
        state.currentStep--;
    }
}

const actions = {

}

export default {
    state,
    getters,
    actions,
    mutations
};