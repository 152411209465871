<template>
  <v-container>
      <div class="ribbon ribbon-top-right"><span>DEMO</span></div>
      
      <v-row>
          <v-col cols="12" class="text-center"> 
              <h1>Reepay demo page</h1>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
              <v-card  class="d-flex justify-center px-12 py-12">  
                <v-btn color="error" class="mr-2"  to="/paycancelled/token">
                    Cancel
                </v-btn>
                <v-btn class="success" to="/payaccepted/token">
                    Pay
                </v-btn>
              </v-card>
          </v-col>
      </v-row>
   </v-container>
</template>

<script>
export default {
  name: "ReePay",
};
</script>