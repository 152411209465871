<template>
  <v-stepper alt-labels :value="currentStep">
    <v-stepper-header>
      <template v-for="(s, index) in steps">
        <v-stepper-step :key="`${index}-step`" :step="index + 1" :complete="currentStep > index + 1">
          {{ s }}
        </v-stepper-step>
        <v-divider :key="`${index}-divider`" v-if="index + 1 !== steps.length"></v-divider>
      </template>
    </v-stepper-header>
  </v-stepper>
</template>
<script>
import Vue from "vue";
export default Vue.component("Stepper", {
  props: ["currentStep", "steps"],
});
</script>
