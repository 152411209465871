import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";

// import da from "../locales/vuetify/da.json";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: { customProperties: true },
    },
    // lang: {
    //     locales: {
    //         da
    //     },
    //     current: 'da',
    // }
});