
import { DateTime  } from "luxon";


export function test() {
    return "test true";
}


export function addParameterToURL(paramKey, paramValue) {
    let url = window.location.href;
    
    // Check if the parameter already exists in the URL
    const existingParamIndex = url.indexOf(paramKey + '=');
    
    // If the parameter exists, we replace it
    if (existingParamIndex >= 0) {
        let startOfExistingValue = existingParamIndex + paramKey.length + 1; // 1 for '='
        let endOfExistingValue = url.indexOf('&', startOfExistingValue); // Find the end of the parameter value
        endOfExistingValue = endOfExistingValue < 0 ? url.length : endOfExistingValue; // If there's no end, use the end of the URL
        
        url = url.substring(0, startOfExistingValue) + paramValue + url.substring(endOfExistingValue); // Replace the existing value
        
    } else {
        
        // If the parameter doesn't exist, we add it
        // First we need to find out if there are other parameters. If there are, we start with '&', otherwise we start with '?'
        const separator = url.indexOf('?') >= 0 ? '&' : '?';
        url += separator + paramKey + '=' + paramValue;
        
    }

    // Update the URL without refreshing the page
    history.pushState(null, null, url);
    
}

export function formatDate(date, format, locales) {
    date = date || new Date;
    format = format || "d-LL-yyyy";
    locales = locales || "da";

    return DateTime.fromISO(date)
        .setLocale(locales)
        .toFormat(format);
}
export function duration(start, end) {
    const date1 = DateTime.fromISO(end)
    const date2 = DateTime.fromISO(start)

    const diff = date1.diff(date2, ["years", "months", "days", "hours"])
    const diffTotalDays = date1.diff(date2, ["days"])
    var obj = {
        ...diff.toObject(),
        totaldays: diffTotalDays.toObject().days
    }
   
    return obj
}
export function timeformat(time) {
   let date =  "2000-01-01T" + time;

    return DateTime.fromISO(date, {locale:"da"})
        .toLocaleString({ hour: '2-digit', minute: '2-digit', hour12: false } );
}


export const dateHelperMixin = {
    methods: {
        formatDate: function (date, format, locales) {
            return formatDate(date, format, locales)
        },
        duration: function (start, end) {
            return duration(start, end)
        },
        timeformat: function (time) {
            return timeformat(time)
        },
        addParameterToURL: function (paramKey, paramValue) {
            return addParameterToURL(paramKey, paramValue)
        }, 
        test: function () {
            return test()
        }
    }
}
