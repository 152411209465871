<template>
  <div class="accessories-picker-list">
    <v-row v-for="a in availableAccessories" :key="a.id + '' + a.quantityvalue" class="accessories-picker-item d-flex">
      <v-col>
        <v-checkbox v-model="selectedAccessories" :value="a.id" :label="a.name" class="flex-column align-start">
          <template slot="label">

            <div v-if="shouldShow(a)">
              <b>
                <template v-if="monthsView && a.pricetype == 'DayPrice'">
                  {{ a.name }}
                  ( {{ formatPrice(a.priceprmonthincltax) }}
                  <template v-if="a.pricetype == 'DayPrice'">
                    {{ $tc("general.pr_months", 1) }}
                  </template>
                  )
                </template>
                <template v-else>
                  {{ a.name }}
                  ( {{ formatPrice(a.priceincltax) }}
                  <template v-if="a.pricetype == 'DayPrice'">
                    {{ $t("general.per_day") }}
                  </template>
                  )
                </template>
              </b>

              <br />
              <small>{{ a.description }}</small>
            </div>
          </template>
        </v-checkbox>


        <template v-if="a.accessorytype != 'extradriver' && a.quantity > 1">
          <PlusMinusField :max="a.infinitquantity ? undefined : a.quantity" @input="(v) => setQuantityValue(v, a)"
            :value="a.quantityvalue ? a.quantityvalue : 0" />
        </template>



      </v-col>

      <v-col cols="12" v-if="a.accessorytype == 'extradriver' && ifInSelectedAccessories(a.id)">
        <ExtraDriverField :extraDriversData="extraDrivers" @extradriverchange="extradriverOnChange" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import PlusMinusField from "../components/Fragments/PlusMinusField";
import { priceHelperMixin } from "../Helpers/price";
import ExtraDriverField from "../components/Fragments/ExtraDriverField";

export default {
  name: "AccessoriesPicker",
  props: ["car"],
  mixins: [priceHelperMixin],
  components: { PlusMinusField, ExtraDriverField },
  data() {
    return {};
  },
  methods: {
    shouldShow(acc) {
      if (this.monthsView == true && acc.pricetype == 'DayPrice' && acc.priceprmonthincltax > 0) return true;
      if (this.monthsView == true && acc.pricetype != 'DayPrice' && acc.priceincltax > 0) return true;
      if (acc.priceincltax > 0) return true;
      return false;
    },
    ifInSelectedAccessories(id) {
      return !!this.selectedAccessories.find((x) => x == id);
    },
    extradriverOnChange(extradrivers) {
      const isMint = extradrivers.length != this.extraDrivers.length;
      this.$store.dispatch("order/setExtraDrivers", extradrivers);
      if (isMint) {
        this.$store.dispatch("company/GET_PRICE_FOR_VEHICLE", this.car.id);
      }
    },
    setQuantityValue(value, acc) {
      acc.quantityvalue = value;
      var accessories = this.$store.state.order.accessories;
      var item = accessories.find((x) => x.id == acc.id);

      if (item && value == 0) {
        item.quantityvalue = value;
        this.$store.dispatch(
          "order/SET_ACCESSORIESBYITEM",
          accessories.filter((x) => x.id != item.id)
        );
      } else if (item) {
        item.quantityvalue = value;
        this.$store.dispatch("order/SET_ACCESSORIESBYITEM", accessories);
      } else if (!item && value != 0) {
        this.$store.dispatch("order/SET_ACCESSORIESBYITEM", [
          ...accessories,
          acc,
        ]);
      }

      this.$store.dispatch("company/GET_PRICE_FOR_VEHICLE", this.car.id);
    },
  },
  computed: {
    selectedAccessories: {
      get() {
        return this.$store.state.order.accessories.map((x) => x.id);
      },
      /**
       * @param {array[]} accIdArray
       */
      set(accIdArray) {
        for (let index = 0; index < accIdArray.length; index++) {
          let acc = this.availableAccessories.find(
            (x) => x.id == accIdArray[index]
          );
          if (!acc.quantityvalue || acc.quantityvalue == 0) {
            acc.quantityvalue = 1;
          }
        }
        this.$store.dispatch(
          "order/SET_ACCESSORIESBYITEM",
          this.availableAccessories.filter((x) =>
            accIdArray.some((i) => x.id == i)
          )
        );

        this.$store.dispatch("company/GET_PRICE_FOR_VEHICLE", this.car.id);
      },
    },
    ...mapGetters({
      monthsView: "order/getMonthsView",
      availableAccessories: "company/availableAccessories",
      extraDrivers: "order/getExtraDrivers",
    }),
  },
};
</script>
<style lang="scss">
.accessories-picker-item {
  .col {
    display: flex;
    justify-content: space-between;

    .minusplusnumber {
      margin-top: 16px;

      margin-right: 0%;

      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        margin-right: 30%;
      }

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        margin-right: 0%;
      }

      @media #{map-get($display-breakpoints, 'xl-only')} {
        margin-right: 50%;
      }

    }
  }
}
</style>
