<template>
  <v-card v-if="car.shouldHide == false" outlined max-width="400"
    :class="['mb-2 mb-xl-4 rounded-xl', { 'vehicle-in-focus': focus }]">
    <v-img class="align-end" height="200" lazy-src="/imagecomingsoondefault.png"
      :src="car.image || '/imagecomingsoondefault.png'">
    </v-img>
    <v-card-title class="mb-3">
      <span>
        {{ car.vehiclemodel.vehiclebrand.name }} {{ car.vehiclemodel.name }}
      </span>
      <span v-if="car.isavailablenow == true && isBruttoList" class="ml-2 available-now">
        {{ $t("general.available_now") }}
      </span>
    </v-card-title>
    <v-card-subtitle>
      <div class="d-flex">

        <div v-if="car.reducedData == true">

          <div v-if="monthsView">
            <span> {{ $t("general.price") }}: </span>
            <b class="text-body-1 font-weight-bold">
              {{ formatPrice(car.priceprmonthincltax) }}
            </b>
            <span>
              {{ $tc("general.pr_months", 1) }}
            </span>
            <br />
            <small v-if="car.priceprday && getInverseHidePrice('months')">
              {{ formatPrice(car.priceprday) }}
              {{ $t("general.per_day") }}
            </small>
          </div>
          <div v-else>
            <span> {{ $t("general.price") }}: </span>
            <b class="text-body-1 font-weight-bold">
              {{ formatPrice(car.priceprday) }}
            </b>
            <span>{{ $t("general.per_day") }}</span>
            <br />
            <small v-if="car.priceprmonthincltax && getInverseHidePrice('days')">
              {{ $t("general.or") }}
              {{ formatPrice(car.priceprmonthincltax) }}
              {{ $tc("general.pr_months", 1) }}
            </small>
          </div>


        </div>
        <div v-else-if="car.hasDetailedPrice">
          <div v-if="monthsView">
            <span> {{ $t("general.price") }}</span>
            <b class="text-body-1 font-weight-bold">
              {{ formatPrice(car.priceData.subscriptionprice) }}
            </b>
            <span>
              {{ $tc("general.pr_months", 1) }}
            </span>
            <br />
            <small v-if="car.priceData.rentingpriceperday && getInverseHidePrice('months')">
              {{ formatPrice(car.priceData.rentingpriceperday) }}
              {{ $t("general.per_day") }}
            </small>
          </div>
          <div v-else>
            <span> {{ $t("general.price") }}: </span>
            <b class="text-body-1 font-weight-bold">
              {{ formatPrice(car.priceData.rentingpriceperday) }}
            </b>
            <span>{{ $t("general.per_day") }}</span>
            <br />
            <small v-if="car.priceData.subscriptionprice && getInverseHidePrice('days')">
              {{ $t("general.or") }}
              {{ formatPrice(car.priceData.subscriptionprice) }}
              {{ $tc("general.pr_months", 1) }}
            </small>
          </div>
        </div>
        <div v-else>
          <span class="loading">
            {{ $t("component.cargrid.price_from") }}
          </span>
        </div>
      </div>
      <div class="d-flex">
        <span class="transition-swing text-caption">
          {{ $t("component.cargrid.price_text") }}
        </span>
      </div>
      <CarInfoIcons :car="car" />
    </v-card-subtitle>

    <v-card-actions class="d-flex justify-center">
      <button class="
              fill-80
              v-btn v-btn--contained v-btn--rounded
              theme--light
              v-size--default
              primary
            " @click="selectCar(car)">
        {{ isBruttoList ? $t("step1.btn.book_brutto") : $t("step1.btn.book") }}
      </button>
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import { priceHelperMixin } from "../../Helpers/price";
import CarInfoIcons from "./CarInfoIcons";

export default Vue.component("vehicleCard", {
  components: { CarInfoIcons },
  props: ["car", "monthsView", "focus", "isBruttoList"],
  mixins: [priceHelperMixin],
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
    })
  },
  methods: {
    selectCar(car) {
      this.$emit("selected", car);
    },
    getInverseHidePrice(typeGiven) {
      return this.$store.getters["order/getInverseHidePrice"](typeGiven);
    },
  },
});
</script>

<style lang="scss" scoped>
.fill-80 {
  width: 80%;
  max-width: 80%;
}

.vehicle-in-focus {
  box-shadow: 0px 0px 8px 3px var(--v-primary-base);
}

.v-image__image--preload {
  filter: none;
}

.v-card__title {
  display: flex;
  justify-content: space-between;

  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .available-now {
    font-size: .7em;
    top: 0;
    left: 10px;
    opacity: .8;
    color: var(--v-primary-base);
  }
}
</style>
