<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="4" align-self="center">
        <v-row justify="center">
          <CarIcon class="caricon"></CarIcon>
        </v-row>
      </v-col>
      <v-col cols="1" class="d-none d-sm-flex">
        <v-divider vertical></v-divider>
      </v-col>
      <v-col cols="12" sm="7" justify="center">
        <h1 class="header">{{ $t("step0.title") }}</h1>

        <h2 class="loading" v-if="createContractProcess.loading">
          {{ $t("cancelled_page.loading") }}
        </h2>
        <Er-ErrorMsg v-if="createContractProcess.errorMessage.toString().length > 0" 
                            >{{ $t("cancelled_page.error") }}</Er-ErrorMsg>
        <h2 v-if="createContractProcess.cancelled">
           {{ $t("cancelled_page.cancelled") }}
        </h2>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// delete /api/v1/Contracts/{paymentId}/{paymentHandle}
// http://localhost:8080/paycancelled?id=cs_631f15a0ffd1972f60a4af9cdba4a154&invoice=Cnr1657_Onr_nr_0&customer=cust-0027

import CarIcon from "../assets/Icons/CarIcon.vue";
import { mapGetters } from "vuex";
import GTMMessage from "@/Helpers/GTMMessage";


export default {
  name: "CheckoutCancelled",
  components: { CarIcon },
  data() {
    return {
      traceId: null,
    };
  },
  methods: {
    sendGTMMessage() {
      const dataLayerData = {
        uniqueid: this.traceId,
        eventAction: "CheckoutCancelled",
        eventCategory: "cancelled",
      };
      GTMMessage.post(dataLayerData, this.$store.state.order.host);
    },
  },
  beforeMount() {
    let traceId = "";
    if (localStorage.traceId) {
      this.$store.dispatch("order/TRACEID", localStorage.traceId)
      traceId = localStorage.traceId;
    } else  {
      this.$store.dispatch("order/TRACEID", undefined)
    }
    this.traceId = traceId;
    this.sendGTMMessage();
    localStorage.removeItem("host");
    const query = this.$route.query;
    this.$store.dispatch("order/DELETE_ORDER", {
      customer: query.customer
    });
  },
  computed: {
    ...mapGetters({
      createContractProcess: "order/contractProcess",
    }),
  },
};
</script>
