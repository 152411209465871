<template>
  <div class="ExtraDriverField">
    <v-row v-for="(item, index) in extraDrivers" :key="index">
      <v-col>
        <v-text-field v-model="item.driverName" :rules="[rules.required]" :label="driverNameLabel" small
          outlined></v-text-field>
      </v-col>
      <v-col>
        <v-text-field v-model="item.licenseNr" :rules="[rules.required, rules.licenseNrlength]" :label="licenseNrLabel"
          small outlined></v-text-field>
      </v-col>
      <v-col class="pt-6">
        <v-btn v-if="extraDrivers.length > 1" @click="deleteItem(index)" color="secondary" icon outlined rounded small>
          <v-icon>mdi-minus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-center" cols="8">
        <v-btn v-if="max ? max >= extraDrivers.length : true" @click="addItem" color="primary" icon outlined rounded
          small>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-spacer />
    </v-row>
  </div>
</template>
<script>

export default {
  name: "ExtraDriverField",
  props: {
    extraDriversData: {
      default: () => [{ driverName: "", licenseNr: "" }],
      type: Array,
    },
    driverNameLabel: {
      default: "Fører navn",
      type: String,
    },
    licenseNrLabel: {
      default: "Kørekort nr.",
      type: String,
    },
    max: {
      default: undefined,
      type: Number,
    },
  },
  data() {
    return {
      extraDrivers: [],
      rules: {
        required: (value) => !!value || this.$t("validation.required"),
        licenseNrlength: (value) => !!value || this.$t("validation.required"),
      },
    };
  },
  methods: {
    deleteItem(index) {
      this.extraDrivers.splice(index, 1);
    },
    addItem() {
      this.extraDrivers.push({ driverName: "", licenseNr: "" });
    },
  },
  watch: {
    extraDrivers: {
      deep: true,
      handler: function () {
        const extradrivers = this.extraDrivers.filter(
          (x) => x.driverName.length > 1
        );

        if (extradrivers && extradrivers.length > 0) {
          this.$emit("extradriverchange", extradrivers);
        }
      },
    },
  },
  created: function () {
    if (!this.extraDriversData || this.extraDriversData.length == 0) {
      this.extraDrivers = [{ driverName: "", licenseNr: "" }];
    } else {
      this.extraDrivers = this.extraDriversData;
    }
  },
}
</script>
<style lang="scss" scoped></style>