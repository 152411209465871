
export function formatPrice(price, locales = null, style = null, currency = null) {
    
    locales = locales || "da-DK";
    style = style || "currency";
    currency = currency||"DKK";
    let value  = new Intl.NumberFormat(locales, {
        style: style,
        currency: currency,
        minimumFractionDigits: price % 1 == 0?  0 : 2
    }).format(price);
    return value;
 }


export const  priceHelperMixin =  {
    methods: {
        formatPrice: function (price, locales = null, style = null, currency = null) {
            return formatPrice(price, locales, style, currency)
        }
      }
 }
