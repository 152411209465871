import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import tempReePay from "@/views/tempReePay.vue";
import CheckoutAccepted from "@/views/CheckoutAccepted.vue";
import CheckoutCancelled from "@/views/CheckoutCancelled.vue";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/reepay",
    name: "Reepay",
    component: tempReePay,
  },
  {
    path: "/payaccepted",
    name: "payaccepted",
    component: CheckoutAccepted,
  },
  {
    path: "/paycancelled",
    name: "paycancelled",
    component: CheckoutCancelled,
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
