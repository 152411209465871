import axios from "../../settings/axios.js";
import { GetCheckavAilability, GetOpeningHours } from "../../Helpers/apiDataHelper";
import { DateTime } from "luxon";

// import store from "../store.js";

/**
             $$\                $$\               
            $$ |               $$ |              
 $$$$$$$\ $$$$$$\    $$$$$$\ $$$$$$\    $$$$$$\  
$$  _____|\_$$  _|   \____$$\\_$$  _|  $$  __$$\ 
\$$$$$$\    $$ |     $$$$$$$ | $$ |    $$$$$$$$ |
 \____$$\   $$ |$$\ $$  __$$ | $$ |$$\ $$   ____|
$$$$$$$  |  \$$$$  |\$$$$$$$ | \$$$$  |\$$$$$$$\ 
\_______/    \____/  \_______|  \____/  \_______|
 */

const state = {
  apperror:{
    operationalError: false,
    departments: false,
    availablevehicles: false
  },
  loadingstates:{
    locations:true,
    departments:true,
    pickupDate: false,
    deliveryDates: false
  },
  test: false,
  hidepoweredby: false,
  detailedPriceError : false,
  openinghours:[],
  openinghoursdelivery:[],
  departments: [],
  vehicleTypes: [],
  availableStartDays: [],
  availableEndDays: [],
  availablevehicles: [],
  kilometerOptions: [],
  availableAccessories: [],
  locations:{
    startlocations:[],
    endlocations:[]
  }
};
/*
                     $$\     $$\                                   
                     $$ |    $$ |                                  
 $$$$$$\   $$$$$$\ $$$$$$\ $$$$$$\    $$$$$$\   $$$$$$\   $$$$$$$\ 
$$  __$$\ $$  __$$\\_$$  _|\_$$  _|  $$  __$$\ $$  __$$\ $$  _____|
$$ /  $$ |$$$$$$$$ | $$ |    $$ |    $$$$$$$$ |$$ |  \__|\$$$$$$\  
$$ |  $$ |$$   ____| $$ |$$\ $$ |$$\ $$   ____|$$ |       \____$$\ 
\$$$$$$$ |\$$$$$$$\  \$$$$  |\$$$$  |\$$$$$$$\ $$ |      $$$$$$$  |
 \____$$ | \_______|  \____/  \____/  \_______|\__|      \_______/ 
$$\   $$ |                                                         
\$$$$$$  |                                                         
 \______/       

*/
const getters = {
  getapperrors:(state)=> state.apperror,
  loadingstates:(state) => state.loadingstates,
  locationsOptions: (state) => {
    return state.locations.startlocations.map(x=> x.name );
  },
  cities: (state) => {
    return state.departments.map(x=>({ id: x.id, city:x.city }));
  },
  vehicleTypes: (state) => {
    return [...state.vehicleTypes];
  },
  getDeliveryOpeninghoursByDate:  (...args) => (date)  => {
    const state = args[0];
    const rootState = args[2];
      // const rootGetters = args[3];
    
    if (date == "" || date == null) return [];
    const today = DateTime.local();
    const theDate = DateTime.fromISO(`${date}T00:00`);
    const pickupTime = rootState.order.pickupTime;
    const pickupDate = rootState.order.pickupDate;
    const deliveryTimefromRentlog = rootState.order.deliveryTimefromRentlog;


    const pickupDateTime = DateTime.fromISO(`${pickupDate}T${pickupTime}`);
    const isToday = today.month === theDate.month && today.day === theDate.day;
    const isSameDay = pickupDateTime.month === theDate.month && pickupDateTime.day === theDate.day;
    let openinghour = state.openinghours.find(z => {
      return z.date == date
    })
    // if (!openinghour) {
    //   const officeId = rootGetters["order/getOfficeId"]
    //   let  companyId =  rootState.order.companyId;
    //   let  traceId = rootState.order.traceId; 
    //   let  sDate =  theDate.toISO(); 
    //   let  eDate =  theDate.toISO(); 
    //   let  branchofficeId =  officeId;
    //   let oh = await GetOpeningHours(companyId, branchofficeId, traceId, sDate, eDate)
    //   
    //   openinghour =   oh.data[0]
    // }

    if (isToday || isSameDay) { // null check
      if (openinghour && openinghour.opened) {
          let close = DateTime.fromISO(`${date}T${openinghour.close}`);
          if (today > close) {
            return [];
          }
      }
    }

    let time = deliveryTimefromRentlog?  DateTime.fromISO(`${date}T${openinghour.open}`)  :DateTime.fromISO(`${date}T00:00`);
    const timeclose =deliveryTimefromRentlog?   DateTime.fromISO(`${date}T${openinghour.close}`) : DateTime.fromISO(`${date}T23:30`);
    if (pickupTime) { // reset check
      if (isToday || isSameDay) {
        const pts = pickupTime.split(':');
        time = time.set({ hour: pts[0], minute: pts[1], second: 0, millisecond: 0 })
      }
    }

    let openinghours = []

    // if is not same day, add pickup time add to opening hours
    if (!isSameDay) {
      openinghours.push(time.toLocaleString({ hour: '2-digit', minute: '2-digit', hour12: false }))
    }
    while (time <= timeclose) {
      time = time.plus({ minutes: 60 });
      if (time.day > theDate.day || time > timeclose) {
        break;
      }
      openinghours.push(time.toLocaleString({ hour: '2-digit', minute: '2-digit', hour12: false }))
    }
    return openinghours;

  },
  getPickupOpeninghoursByDate: (state) => (date) => {
    if (date == "" || date == null) return [];
    if (state.openinghours && state.openinghours.length > 0) {
      const openinghour = state.openinghours.find(z => {
        return z.date == date
      })
      if (openinghour && openinghour.opened) {

        const today = DateTime.local();
        const theDate = DateTime.fromISO(`${date}T00:00`);
        const isToday = today.month === theDate.month && today.day === theDate.day;

        if (isToday) {
          let close = DateTime.fromISO(`${date}T${openinghour.close}`);
          if (today > close) {
            return [];
          }
        }
        
        let startTime = isToday ? `${today.hour + 1}:00` : openinghour.open;
        let openinghours = []
        let time = DateTime.fromISO(`${openinghour.date}T${startTime}`);
        let timeclose = DateTime.fromISO(`${openinghour.date}T${openinghour.close}`);
        while (time <= timeclose) {
          openinghours.push(time.toLocaleString({ hour: '2-digit', minute: '2-digit', hour12: false }))
          time = time.plus({ minutes: 15 });
        }
        return openinghours;
      }
    }
  },
  getDepartmentById: (state) => (id) =>
    state.departments.find((x) => x.id === id),
  getDepartmentByName: (state) => (name) =>
    state.departments.find((x) => x.name === name),
  getVehicleById: (state) => (id) =>
    state.availablevehicles.find((x) => x.id === id),
  isPickupdayAllowed: (...args) => (date) => {
    const state = args[2].company;
    const rootState = args[2];

    const toDay =  DateTime.local();
    const givenDate =  DateTime.fromISO(date);
   

    if (rootState.order.periodStartBuffer > 0) {
      let bufferDateTime = toDay;
      bufferDateTime = bufferDateTime.plus({ days: rootState.order.periodStartBuffer});
      if (givenDate < bufferDateTime) {
        return false;
      }
     }

  
    const toDay2Test = toDay.startOf('day');
    const givenDate2Test = givenDate.startOf('day');
    if (toDay2Test >= givenDate2Test) {
      if (!toDay2Test.equals(givenDate2Test)) {
        return false;
      }
    }

    
    var theDate = state.availableStartDays.find(x =>  date === x.daterange.startdate.substring(0, 10))
    if (theDate) {
      if (theDate.numberofavailablecars > 0) {
        if (state.openinghours && state.openinghours.length > 0) {
          const openinghour = state.openinghours.find(z => {
            return z.date == theDate.daterange.startdate.substring(0, 10)
          })
          
          if (openinghour && openinghour.opened) return true
        }
      }
      return false
    }
  },
  isDeliverydayAllowed: (...args) => (date) => {
    
    const theDate =  DateTime.fromISO(date);
    const rootState = args[2];
    const pickupDate =  DateTime.fromISO(rootState.order.pickupDate);
   
    const theDate2Test = theDate.startOf('day');
    const pickupDate2Test = pickupDate.startOf('day');
    if (pickupDate2Test >= theDate2Test) {
      if (!pickupDate2Test.equals(theDate2Test)) {
        return false;
      }
    }
    let deliverydate = args[0].availableEndDays.find( candidate => {
        let enddate =   DateTime.fromISO(candidate.daterange.enddate);
        //enddate = enddate.plus( { hour: -2 } )
        if ( enddate.year === theDate.year && candidate.numberofavailablecars > 0) {
            const isTheDate = enddate.month === theDate.month && enddate.day === theDate.day;
            if (isTheDate) {
              return candidate
            }
        }
    });
    if (deliverydate) {
      if (state.openinghours && state.openinghours.length > 0) {
          const openinghour = state.openinghours.find(candidate => {
            return candidate.date == deliverydate.daterange.enddate.substring(0, 10)
          })
          if (openinghour && openinghour.opened)  return true
      }
    }
    return false;
  },
  getDeliveryday: (state) => (date) => {
    return state.availableEndDays.find(
      (x) => x.numberofavailablecars > 0 && date === x.daterange.enddate.substring(0, 10) 
    );
  },
  getCarList: (state) => {
    return state.availablevehicles
  },
  kilometerOptions: (state) => {
    return state.kilometerOptions;
  },
  kilometerOptionNames: (state) => {
    return state.kilometerOptions.map((x) => x.name);
  },
  kilometerOptionsValues: (state) => {
    return state.kilometerOptions.map((x) => {
      return x.maxkm;
    });
  },
  availableAccessories: (state) => {
    return state.availableAccessories;
  },
  getAccessoryById: (state) => (id) => {
    return state.availableAccessories.find((x) => x.id === id);
  },
  getKilometerOptionByKm: (state) => (km) => {
    return state.kilometerOptions.find((x) => x.maxkm === km);
  },
  getKilometerOptionById: (state) => (id) => {
    return state.kilometerOptions.find((x) => x.id === id);
  },
};
/**
                           $$\                $$\     $$\                               
                          $$ |               $$ |    \__|                              
$$$$$$\$$$$\  $$\   $$\ $$$$$$\    $$$$$$\ $$$$$$\   $$\  $$$$$$\  $$$$$$$\   $$$$$$$\ 
$$  _$$  _$$\ $$ |  $$ |\_$$  _|   \____$$\\_$$  _|  $$ |$$  __$$\ $$  __$$\ $$  _____|
$$ / $$ / $$ |$$ |  $$ |  $$ |     $$$$$$$ | $$ |    $$ |$$ /  $$ |$$ |  $$ |\$$$$$$\  
$$ | $$ | $$ |$$ |  $$ |  $$ |$$\ $$  __$$ | $$ |$$\ $$ |$$ |  $$ |$$ |  $$ | \____$$\ 
$$ | $$ | $$ |\$$$$$$  |  \$$$$  |\$$$$$$$ | \$$$$  |$$ |\$$$$$$  |$$ |  $$ |$$$$$$$  |
\__| \__| \__| \______/    \____/  \_______|  \____/ \__| \______/ \__|  \__|\_______/
 */

const mutations = {
  app_error_operational(state, payload){
    state.apperror.operationalError = payload;
  },
  app_error_departments(state, payload){
    state.apperror.departments = payload;
  },  
  app_error_availablevehicles(state, payload){
    state.apperror.availablevehicles = payload;
  },
  SET_LOADINGSTATES(state, loadingstates) {
    if (Object.prototype.hasOwnProperty.call(loadingstates,'pickupDate')) {

      state.loadingstates.pickupDate = loadingstates.pickupDate;
    }
    if (Object.prototype.hasOwnProperty.call(loadingstates,'deliveryDates')) {

      state.loadingstates.deliveryDates = loadingstates.deliveryDates;
    } 
     if (Object.prototype.hasOwnProperty.call(loadingstates,'locations')) {

      state.loadingstates.locations = loadingstates.locations;
    } 
    if (Object.prototype.hasOwnProperty.call(loadingstates,'departments')) {

      state.loadingstates.departments = loadingstates.departments;
    }
  },
  SET_TEST(state, payload) {
    state.test = payload;
  },
  SET_HIDE_POWERED_BY(state, payload) {
    state.hidepoweredby = payload;
  },
  SET_DEPARTMENTS(state, payload) {
    
    state.departments = payload;
  }, 
  ADD_OPENINGHOURS(state, payload) {
    let openinghours = [...state.openinghours]

    for (const key in payload) {
        const openinghour = payload[key];
        var hour = openinghours.find(x=> x.date == openinghour.date ) 
        if (hour == null ||hour == undefined) {
          openinghours.push(openinghour)
        }
    }

    state.openinghours = openinghours;
  }, 
  SET_OPENINGHOURS(state, payload) {
    state.openinghours = payload;
  }, 
  SET_OPENINGHOURS_DELIVERY(state, payload) {
    state.openinghoursdelivery = payload;
  },
  SET_VEHICLE_TYPES(state, payload) {
    state.vehicleTypes = payload;
  },
  SET_AVAILABLE_START_DAYS(state, payload) {
    state.availableStartDays = payload;
  },
  SET_AVAILABLE_END_DAYS(state, payload) {
    state.availableEndDays = payload;
  },
  SET_AVAILABLE_VEHICLES(state, payload) {
    state.availablevehicles = payload;
  },
  SET_KILOMETER_OPTIONS(state, payload) {
    state.kilometerOptions = payload;
  },
  SET_AVAILABLE_ACCESSORIES(state, payload) {
    state.availableAccessories = payload;
  },
  SET_AGENCY_LOCATIONS(state, payload) {
    state.locations = payload;
  },
  // SET_PRICE_ON_AVAILABLE_VEHICLE(state, payload){

  // }
};
/**
                      $$\     $$\                               
                      $$ |    \__|                              
 $$$$$$\   $$$$$$$\ $$$$$$\   $$\  $$$$$$\  $$$$$$$\   $$$$$$$\ 
 \____$$\ $$  _____|\_$$  _|  $$ |$$  __$$\ $$  __$$\ $$  _____|
 $$$$$$$ |$$ /        $$ |    $$ |$$ /  $$ |$$ |  $$ |\$$$$$$\  
$$  __$$ |$$ |        $$ |$$\ $$ |$$ |  $$ |$$ |  $$ | \____$$\ 
\$$$$$$$ |\$$$$$$$\   \$$$$  |$$ |\$$$$$$  |$$ |  $$ |$$$$$$$  |
 \_______| \_______|   \____/ \__| \______/ \__|  \__|\_______/
 
 */
const actions = {

  app_error_operational({commit}, payload){
    commit("app_error_operational", payload);
  },
  async GET_MONTHCHANGE_PICKUP({ commit, state, rootState, rootGetters}, date){
    const startdays = state.availableStartDays;
   
    commit("SET_LOADINGSTATES", {
      pickupDate : true
    });
    

    const month = parseInt(date.substring(5, 7));
    const year = parseInt(date.substring(0, 4));
    let startDate = DateTime.local(year, month, 1, 0, 0, 0, 0);
    let endDate = startDate.plus( { months: 1 } );
    

    const officeId = rootGetters["order/getOfficeId"]

    // api props
    let  companyId =  rootState.order.companyId;
    let  traceId = rootState.order.traceId; 
    let  sDate =  startDate.toISO(); 
    let  eDate =  endDate.toISO(); 
    let  dayToDay =  true; 
    let  branchofficeId =  officeId;
    let selectedCarId = rootState.order.selectCarFromBruttoList != null ? rootState.order.selectCarFromBruttoList.id : null;
    
    // todo check if openinghours exists
    const openinghours =  await GetOpeningHours(companyId, branchofficeId, traceId, sDate, eDate);
    if (openinghours && openinghours.error != true &&  openinghours.data.length > 0 ) {
      commit("ADD_OPENINGHOURS", openinghours.data);
    }

    const data =  await GetCheckavAilability(companyId, traceId, sDate, eDate, dayToDay, branchofficeId, selectedCarId);
    let newDates = [...startdays];
    for (const key in data.data) {
      const day = data.data[key]
      if (day.numberofavailablecars > 0) {
        var oldDay = startdays.find(x=> x.daterange.startdate.substring(0, 10) == day.daterange.startdate.substring(0, 10)) 
        if (oldDay == null ||oldDay == undefined) {
          newDates.push(day)
        }
      }
    }
    commit("SET_AVAILABLE_START_DAYS", newDates);
    commit("SET_LOADINGSTATES", {
      pickupDate : false
    });
  },
  async GET_MONTHCHANGE_DELIVERY({ commit, state, rootState, rootGetters}, date){
    commit("SET_LOADINGSTATES", {
      deliveryDates : true
    });

    const enddays = state.availableEndDays;
    
    const pickupDate = DateTime.fromISO(rootState.order.pickupDate);
    let startDate = pickupDate;// DateTime.local(year, month, 1, 0, 0, 0, 0);


    const month = parseInt(date.substring(5, 7));
    const year = parseInt(date.substring(0, 4));


    

    let monthChangeDate = DateTime.local(year, month, 1, 0, 0, 0, 0);
    let endDate = monthChangeDate.endOf('month'); //  startDate.plus( { months: 1 } );

    // todo check if dates exists



    const officeId = rootGetters["order/getOfficeId"]

    // api props
    let  companyId =  rootState.order.companyId;
    let  traceId = rootState.order.traceId; 
    let  sDate =  startDate.toISO(); 
    let  eDate =  endDate.toISO(); 
    let  dayToDay =  false; 
    let  branchofficeId =  officeId;
    let selectedCarId = rootState.order.selectCarFromBruttoList != null ? rootState.order.selectCarFromBruttoList.id : null;
  

    // todo check if openinghours exists
    const openinghours =  await GetOpeningHours(companyId, branchofficeId, traceId, sDate, eDate);
    if (openinghours && openinghours.error != true &&  openinghours.data.length > 0 ) {
      commit("ADD_OPENINGHOURS", openinghours.data);
    }

    const data =  await GetCheckavAilability(companyId, traceId, sDate, eDate, dayToDay, branchofficeId, selectedCarId);
    let newDates = [...enddays];
    for (const key in data.data) {
      const day = data.data[key]
      if (day.numberofavailablecars > 0) {
        var oldDay = enddays.find(x=> x.daterange.enddate.substring(0, 10) == day.daterange.enddate.substring(0, 10)   ) 
        if (oldDay == null ||oldDay == undefined) {
          newDates.push(day)
        }
      }
    }
    commit("SET_AVAILABLE_END_DAYS", newDates);
    commit("SET_LOADINGSTATES", {
      deliveryDates : false
    });
  },
  HANDLE_FORCEOFFICEID({dispatch, state, rootState}){

    const forceoffice = rootState.order.forceofficeid;
    if (forceoffice.force) {
      const office = state.departments.find(x=>x.id == forceoffice.officeid);
      if (office) {
        dispatch("order/SET_DEPARTMENT", office.id, {
          root: true,
        });
      } else {
        dispatch("order/RESET_FORCE_DEPARTMENT_ID", null, {
          root: true,
        });
      }
    }
  },
  HANDLE_FORCELOCATION({dispatch,state,rootState}){
    const force =  rootState.order.forcelocations.force || rootState.order.forceofficeid.force;
    if (force) {
      const id = rootState.order.forcelocations.id || rootState.order.forceofficeid.officeid;
      const location = state.locations.startlocations.find(x=> x.id == id || x.branchoffice ? x.branchoffice.id == id : false   );
      if (location && location.name) {
        dispatch("order/SET_STARTLOCATION", location.name, {
          root: true,
        });
      } else {
        dispatch("order/RESET_FORCE_LOCATIONS_ID", null, {
          root: true,
        });
      }
    }
  },
  LOAD_DEPARTMENTS({ commit,dispatch, rootState }, payload) {
    commit("app_error_departments", false);
    try {
      axios
      .get(`/Agency/offices/${payload}?traceId=${rootState.order.traceId}`)
      .then((response) => {
        commit("SET_DEPARTMENTS", response.data);
        dispatch("HANDLE_FORCEOFFICEID");
        commit("SET_LOADINGSTATES", {
          departments: false
        });
      })
      .catch((error) => {
        console.error(error);
        commit("app_error_departments", true);
      });
    } catch (error) {
      console.error("LOAD_DEPARTMENTS http error", error);
      commit("app_error_departments", true);
    }
  },
  LOAD_AGENCY_LOCATIONS({ commit, rootState, dispatch }, companyId) {
    try {
      axios
        .get(`/Agency/locations/${companyId}?traceId=${rootState.order.traceId}`)
        .then((response) => {
          commit("SET_AGENCY_LOCATIONS", response.data);
          dispatch("HANDLE_FORCELOCATION");
          commit("SET_LOADINGSTATES", {
            locations : false
          });
          dispatch("LOAD_DEPARTMENTS", companyId);
          
          // if (!response.data.startlocations || response.data.startlocations.length == 0) {
          //   dispatch("LOAD_DEPARTMENTS", companyId);
          // }
        })
        .catch((error) => {
          // http error 404
          console.warn(error);
          dispatch("LOAD_DEPARTMENTS", companyId);
        });

    } catch (error) {
      dispatch("LOAD_DEPARTMENTS", companyId);
    }
  },
  SET_TEST({ commit }, payload) {
    commit("SET_TEST", payload);
  },
  SET_HIDE_POWERED_BY({ commit }, payload) {
    commit("SET_HIDE_POWERED_BY", payload);
  },
  LOAD_START_DATES({ rootState, commit, rootGetters, dispatch }) {
    commit("SET_LOADINGSTATES", {
      pickupDate : true
    });
    //todo: refactor duplicate code 
    let pickupDateTime = rootGetters["order/pickupDateTime"](null);
    
    if (rootState.order.periodStartBuffer > 0) {
      let bufferPickupDateTime = DateTime.fromISO(pickupDateTime);
      bufferPickupDateTime = bufferPickupDateTime.plus({ days: rootState.order.periodStartBuffer});
      pickupDateTime = bufferPickupDateTime.toISO();
     }
     
    const deliveryDateTime = rootGetters["order/deliveryDateTime"](pickupDateTime, -1, 1);
    // duplicate code - start
    dispatch("LOAD_OPENINGHOURS", false);
   
    let branchoffice = null;
    const startlocation = rootState.order.startlocation;
    
    if (startlocation != "") {
      const locationObj = rootState.company.locations.startlocations.find(x=>x.name == startlocation);
      branchoffice = locationObj?.branchoffice
    } else if(rootState.order.department && rootState.order.department.id){
      branchoffice = rootState.order.department
    }
    let url = "";
    if (branchoffice && branchoffice.id) {
      url =  `/Vehicle/checkavailability/${rootState.order.companyId}/${branchoffice.id}?traceId=${rootState.order.traceId}`
    } else {
      url =  `/Vehicle/checkavailability/${rootState.order.companyId}?traceId=${rootState.order.traceId}`
    }
    if (rootState.order.selectCarFromBruttoList != null) {
      
      url += `&RentingCarId=${rootState.order.selectCarFromBruttoList.id}`
    }
      

    
    

    axios
      .get(
        url,
        {
          params: {
            startDate: pickupDateTime,
            endDate: deliveryDateTime,
            dayToDay: true, // not - duplicate code 
            traceId: rootState.order.traceId
          },
        }
      )
      .then((response) => {
        commit("SET_AVAILABLE_START_DAYS", response.data); // not - duplicate code 
        commit("SET_LOADINGSTATES", {
          pickupDate : false
        });
      })
      .catch((error) => {
        console.error(error);
        commit("SET_LOADINGSTATES", {
          pickupDate : false
        });
      });
  },
  LOAD_OPENINGHOURS({ rootState, commit, rootGetters }, isdelivery = false) {
    const pickupDateTime = rootState.order.pickupDate ? rootGetters["order/pickupDateTime"](rootState.order.pickupDate) : rootGetters["order/pickupDateTime"](null);
    const deliveryDateTime = rootState.order.pickupDate? rootGetters["order/deliveryDateTime"](rootState.order.pickupDate, 0,1) : rootGetters["order/deliveryDateTime"](null, 0,1);
    

    axios
      .get(
        `/companies/openinghours/${rootState.order.companyId}`,
        {
          params: {
            startDate: pickupDateTime,
            endDate: deliveryDateTime,
            traceId: rootState.order.traceId
          },
        }
      )
      .then((response) => {
        if (isdelivery) {
          commit("SET_OPENINGHOURS_DELIVERY", response.data);
        } else {
          commit("SET_OPENINGHOURS", response.data);
        }

      })
      .catch((error) => {
        console.error(error);
      });
  },
  LOAD_END_DATES({ rootState, commit, dispatch }) {
    commit("SET_LOADINGSTATES", {
      deliveryDates : true
    });
    var theDate =rootState.order.pickupDate? DateTime.fromISO(rootState.order.pickupDate):DateTime.local();
    var pickupDate =  theDate.set({ hour: 0, minute:0, second:0, millisecond:0  }).toISO();
    var deliveryDate =  theDate.set({ hour: 0, minute:0, second:0, millisecond:0  }).plus( { months: 1 } ).toISO();     
    // duplicate code - start
    dispatch("LOAD_OPENINGHOURS", true);
    
    let branchoffice = null;
    const startlocation = rootState.order.startlocation;

    if (startlocation != "") {
      const locationObj = rootState.company.locations.startlocations.find(x=>x.name == startlocation);
      branchoffice = locationObj?.branchoffice
    } else if(rootState.order.department && rootState.order.department.id){
      branchoffice = rootState.order.department
    }

    let url = "";
    if (branchoffice && branchoffice.id) {
      url =  `/Vehicle/checkavailability/${rootState.order.companyId}/${branchoffice.id}?traceId=${rootState.order.traceId}`
    }else {
      url =  `/Vehicle/checkavailability/${rootState.order.companyId}?traceId=${rootState.order.traceId}`
    }
    if (rootState.order.selectCarFromBruttoList != null) {
      
      url += `&RentingCarId=${rootState.order.selectCarFromBruttoList.id}`
    }

    axios
      .get(
        url,
        {
          params: {
            startDate: pickupDate,
            endDate: deliveryDate,
            traceId: rootState.order.traceId
          },
        }
      )
      .then((response) => {
        commit("SET_AVAILABLE_END_DAYS", response.data);// not - duplicate code 
        commit("SET_LOADINGSTATES", {
          deliveryDates : false
        });
      })
      .catch((error) => {
        console.error(error);
        commit("SET_LOADINGSTATES", {
          deliveryDates : false
        });
      });
    // duplicate code - end

  },
  GET_CARS({
    commit,
    rootState,
    dispatch,
    rootGetters
  }) {
    commit("app_error_availablevehicles", false);

   
    const pickupDateTime = rootGetters["order/pickupDateTime"](rootState.order.pickupDate);
    const deliveryDateTime = rootGetters["order/deliveryDateTime"](rootState.order.deliveryDate);
 //TODO: duplicate code - start
    let url = "";
    const startlocation = rootState.order.startlocation;
    const locationObj = rootState.company.locations.startlocations.find(x=>x.name == startlocation);
    let branchoffice = locationObj?.branchoffice;
    if (!branchoffice && rootState.order.department && rootState.order.department.id) {
      branchoffice = rootState.order.department
    }
    
    if (branchoffice && branchoffice.id) {
      url =  `/Vehicle/availablevehicles/${rootState.order.companyId}/${branchoffice.id}?traceId=${rootState.order.traceId}`
    } else {
      url =  `/Vehicle/availablevehicles/${rootState.order.companyId}?traceId=${rootState.order.traceId}`
    }



 //TODO: duplicate code - end

    axios
      .get(
        url,
        {
          params: {
            startDate: pickupDateTime,
            endDate: deliveryDateTime,
          },
        }
      )
      .then((response) => {
        let vehicles = response.data.map(x=>{
         x.priceData = {};
         x.hasDetailedPrice = false;
         x.shouldHide = false;
         return x;
        })
        commit("SET_AVAILABLE_VEHICLES", vehicles);
        dispatch("GET_PRICE_ON_AVAILABLE_VEHICLES");
        commit("step/NEXT", null, {
          root: true,
        });
      })
      .catch((error) => {
        console.error(error);
        commit("app_error_availablevehicles", true);

      });
  },
  GET_PRICE_ON_AVAILABLE_VEHICLES({  state,  dispatch}) {
    if (state.availablevehicles && state.availablevehicles.length > 0) {
      for (let index = 0; index < state.availablevehicles.length; index++) {
        let vehicle = state.availablevehicles[index];
        dispatch("GET_PRICE_FOR_VEHICLE", vehicle.id)
      }
    }
  },
  GET_PRICE_FOR_VEHICLE({ rootState, state, commit, rootGetters }, id ) {
    const pickupDateTime = rootGetters["order/pickupDateTime"](rootState.order.pickupDate);
    const deliveryDateTime = rootGetters["order/deliveryDateTime"](rootState.order.deliveryDate);
    const vehicle = rootGetters["company/getVehicleById"](id);
    if (vehicle == undefined || vehicle == null) {
      return;
    }

    let data = {
      startdate: pickupDateTime,
      enddate: deliveryDateTime,
      rentingcarid: vehicle.id,
      extrakms: [],
      accessories: [],
      ownriskinsurance: rootState.order.insurance,
      extraDrivers: 0
    };

    if (rootState.order.range != undefined) {
      let extrakms = {
        extrakmpackageid: rootState.order.range.id,
      };
      if (
        rootState.order.range.userspecifiedmaxkm &&
        rootState.order.customRange
      )
        extrakms.userspecifiedmaxkm = rootState.order.customRange;

      if (
        rootState.order.range.userspecifiedmaxkm &&
        rootState.order.customRange == 0
      )
        return;

      data.extrakms = [extrakms];
    }

    // if (rootState.order.accessories.length > 0) {
    //   data.accessories = rootState.order.accessories.map((x) => ({
    //     accessoryid: x.id,
    //     quantity: x.quantityvalue,
    //   }));
    // }

    const accessories = rootState.order.accessories.filter(x=>x.accessorytype  ==  "standard");
    if (accessories && accessories.length > 0) {
      data.accessories = accessories.map((x) => ({
        accessoryid: x.id,
        quantity: x.quantityvalue,
      }));
    }

    const accessoriesextradriver = rootState.order.accessories.find(x=>x.accessorytype  ==  "extradriver");
    if (accessoriesextradriver && rootState.order.extraDrivers && rootState.order.extraDrivers.length) {
      data.extraDrivers = rootState.order.extraDrivers.length
    }

    axios
      .post(
        `/Vehicle/detailedPrice/${rootState.order.companyId}?traceId=${rootState.order.traceId}`,
        data
      )
      .then((response) => {
        vehicle.price = response.data.totalprice;
        vehicle.priceData = response.data;
        vehicle.hasDetailedPrice = true;
        state.detailedPriceError = false;

        var monthsView = rootGetters["order/getMonthsView"];
        if (monthsView) {
          vehicle.shouldHide =  vehicle.priceData.subscriptiontotalprice <= 0;
        } else {
          vehicle.shouldHide = vehicle.priceData.totalprice <= 0 || vehicle.priceData.rentingpriceperday <= 0;
        }

        commit("SET_AVAILABLE_VEHICLES", state.availablevehicles);
      })
      .catch((error) => {
        console.error(error);
        state.detailedPriceError = true;
      });
  },
  SET_KILOMETER_OPTIONS({ commit, rootState }) {
    axios
      .get(
        `/Vehicle/kilometeroptions/${rootState.order.companyId}/${rootState.order.car.id}?traceId=${rootState.order.traceId}`
      )
      .then((response) => {
        commit("SET_KILOMETER_OPTIONS", response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  SET_AVAILABLE_ACCESSORIES({ commit, rootState , rootGetters }) {
    const pickupDateTime = rootGetters["order/pickupDateTime"](rootState.order.pickupDate);
    const deliveryDateTime = rootGetters["order/deliveryDateTime"](rootState.order.deliveryDate);


    const companyId = rootState.order.companyId
    const pickupDate = rootState.order.pickupDate
    const deliveryDate = rootState.order.deliveryDate
    const branchofficeId = rootState.order.car.branchoffice?.id

    if (
      companyId == "" ||
      pickupDate == null ||
      pickupDate == "" ||
      deliveryDate == null ||
      deliveryDate == ""
    ) {
      return;
    }
    let branchOfficeIdValue = branchofficeId
      if(!branchofficeId) {
        branchOfficeIdValue = 0
      }
    axios
      .get(
        `/Vehicle/accessories/${companyId}/${branchOfficeIdValue}?traceId=${rootState.order.traceId}`,
        {
          params: {
            startDate: pickupDateTime,
            endDate: deliveryDateTime,
          },
        }
      )
      .then((response) => {
        var monthsView = rootGetters["order/getMonthsView"];
        let accessories = [];
        if( monthsView == true ){
          accessories = [
            ...response.data.filter(x => x.priceprmonthincltax && x.accessorytype != "extradriver" ), 
            // add extradriver 
            ...response.data.filter(x => x.accessorytype == "extradriver" )
          ];
         
        } else{
          accessories = [...response.data];
        }
        commit("SET_AVAILABLE_ACCESSORIES", accessories);
      })
      .catch((error) => {
        console.error(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
