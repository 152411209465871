<template>
  <v-app>
    <v-main>
      <div
        v-if="this.$store.state.company.test"
        class="ribbon ribbon-top-right"
      >
        <span>præsentation</span>
      </div>

      <router-view></router-view>
      <div class="container">
        <div class="row">
          <v-spacer></v-spacer>

          <div v-if="!hidePoweredby" class="d-flex poweredByWeb caption">
            <a
              href="http://www.autoit.dk/web/"
              class="d-flex align-center"
              target="_blank"
            >
              <img height="20" class="mr-1" src="/auto-it-logo.png" alt="" />
              <span>Powered by AutoIt Web</span>
            </a>
          </div>
          <v-spacer></v-spacer>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import parameters from "./Helpers/contentdataParameters.js";
import { addParameterToURL } from "./Helpers/dateHelperMixin.js";

export default {
  name: "App",
  components: {},
  mounted() {

    this.setTest();
    this.setCulture();
    this.setHost();
    this.setTheme();
    this.setLangAppTheme();
    this.hidePoweredBy();
    this.inverseHidePrice()
  },
   data: function () {
    return {
     hidePoweredby: false,
    };
  },
  methods: {
    inverseHidePrice(){
      const dispatch = "order/setInverseHidePrice";
      let optionValue = parameters.data['inv-price']; // default false
      if (optionValue !== "") {
        let isOptionSet = false;

        if (typeof optionValue === "boolean" && optionValue === true) {
          isOptionSet = true;
        } else if ((typeof optionValue === "string" || optionValue instanceof String) &&
                  optionValue.toLowerCase() === "true") {
          isOptionSet = true;
        }

        if (isOptionSet) {
          this.$store.dispatch(dispatch, true);
        }
      }
    },
    setTheme() {
      // ??  local storage ??
      let colorprimary = parameters.data.colorprimary || "#1abc9c";
      let colorsecondary = parameters.data.colorsecondary || "#222222";

      this.$vuetify.theme.themes.light.primary = colorprimary;
      this.$vuetify.theme.themes.light.accent = colorprimary;
      this.$vuetify.theme.themes.light.info = colorprimary;

      this.$vuetify.theme.themes.light.info = colorsecondary;
      this.$vuetify.theme.themes.light.secondary = colorsecondary;
    },
    setCulture() {
      let culture = parameters.data.culture || "da-DK";
      culture = culture.includes("-") ? culture.split("-")[0] : culture;
      const availableLanguages = Object.getOwnPropertyNames(
        this.$i18n.messages
      );
      this.$i18n.locale = availableLanguages.includes(culture) ? culture : "da";
      this.$store.dispatch("order/SET_CULTURE", culture);
    },
    setLangAppTheme() {
      let appTheme = parameters.data.apptheme;
      
      if (appTheme || appTheme != "") {
        const availableLanguages = Object.getOwnPropertyNames(
          this.$i18n.messages
        );
        const langTheme = this.$i18n.locale + "-" + appTheme;
        const hasLangTheme = availableLanguages.includes(langTheme);
        if (hasLangTheme) {
          this.$i18n.locale = langTheme;
          this.$store.dispatch("order/SET_APPTHEME", appTheme);
        }
      }
    },
    setTest() {
      let test = parameters.data.test || false;
      this.$store.dispatch("company/SET_TEST", test);
    },  
    hidePoweredBy() {
      this.hidePoweredby = parameters.data['hide-ait-logo'] || false;

      // If there is a hide-ait-logo param in the url, use that to hide the logo. This is useful when returning from the booking flow.
      if(!this.hidePoweredby){
        // Check if the url has the parameter
        const urlParams = new URLSearchParams(window.location.search);
        const hidePoweredBy = urlParams.get('hide-ait-logo');
        if(hidePoweredBy){
          this.hidePoweredby = true;
        }
      }
      // Save the value in the store to send it to the payment service. Note, that the value only has to be set to something truthy, like a string, number or true.
      this.$store.dispatch("company/SET_HIDE_POWERED_BY", !!this.hidePoweredby);
    },
    setHost() {
      let host = localStorage.getItem("host");
      if (parameters.host !== null && parameters.host !== "") { 
          localStorage.setItem("host", parameters.host);
          this.$store.dispatch("order/SET_CONFIGHOST", parameters.host);
        } else if (host !== null && host !== ""){
          this.$store.dispatch("order/SET_CONFIGHOST", host);
        }
        addParameterToURL("host", host??process.env.VUE_APP_HOST);
    },
  },
};
</script>
<style lang="scss">
@import "src/scss/main";
.poweredByWeb {
  height: 30px;
}

</style>
