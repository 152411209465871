<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="4" align-self="center">
        <v-row justify="center">
          <CarIcon class="caricon"></CarIcon>
        </v-row>
      </v-col>
      <v-col cols="1" class="d-none d-sm-flex">
        <v-divider vertical></v-divider>
      </v-col>
      <v-col cols="12" sm="7" justify="center">
        <div class="pa-3">
          <div v-if="isPaid">
            <h1 class="header">{{ $t("accepted_page.title") }}</h1>
          </div>
          <div v-else>
            <h1 class="header">{{ $t("step0.title") }}</h1>
          </div>

          <div v-if="loading">{{ $t("accepted_page.loading") }}</div>

          <Loader v-if="!sendreceiptSuccess"></Loader>
          
          <div v-if="paymentError">
            <Er-ErrorMsg>{{ $t("accepted_page.error") }}</Er-ErrorMsg>
          </div>

          <div v-if="isPaid">
            <h2>{{ $t("accepted_page.subtitle") }}</h2>
          </div>

          <div v-if="!isPaid && retryCount <= 0">
            <p>
              {{ $t("accepted_page.error_text") }}
              <a @click="retryhandelCheckoutAccepted">
                <span>{{ $t("accepted_page.click_here") }}</span>
              </a>
            </p>
          </div>

          <div v-if="sendreceiptSuccess">{{ $t("accepted_page.info") }}</div>

          <div v-else-if="sendreceiptError">
            {{ $t("accepted_page.sendreceiptError") }}
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
  <!-- 
http://localhost:8080/payaccepted?id=cs_df44eaa45339895b1a060f6e69c946c8&invoice=Cnr1656_Onr_nr_0&customer=cust-0026&payment_method=ca_c34f79e05baf7f7f55e4e77e85bf6edf -->
</template>

<script>
import axios from "../settings/axios.js";
import GTMMessage from "@/Helpers/GTMMessage";
import _ from "lodash";
import CarIcon from "../assets/Icons/CarIcon.vue";
import { mapGetters } from "vuex";
import Loader from '../components/Loader.vue'

export default {
  name: "CheckoutAccepted",
  components: { CarIcon, Loader },
  data() {
    return {
      customer: null,
      traceId: null,
      token: null,

      responseData: null,
      loading: true,
      paymentError: false,
      sendreceiptError: false,
      sendreceiptSuccess: false,
      isPaid: false,
      retryCount: 3,
    };
  },
  beforeMount() {

    if (localStorage.traceId) {
      this.$store.dispatch("order/TRACEID", localStorage.traceId);
    } else {
      this.$store.dispatch("order/TRACEID", undefined);
    }

    this.traceId = localStorage.traceId;

    const query = this.$route.query;

    this.customer = _.toNumber(query.customer);
  },
  mounted() {
    this.handelCheckoutAccepted();
  },
  methods: {
    handelCheckoutAccepted() {
      this.paymentError = false;
      this.sendreceiptError = false;

      let url = `/Contracts/payment/info/${this.customer}?traceId=${this.traceId}`;
      try {
        axios
          .get(url)
          .then((response) => {
            this.responseData = response.data;
            this.token = response.data.token;
            this.sendreceiptSuccess = response.data.receiptsend;
            this.isPaid = response.data.ispaid;
            this.loading = false;

            // if recall (f5) page and subscription is paid and completeSubscription is called
            if (
              response.data.issubscription && 
              ( response.data.subscriptionstatus == null || response.data.subscriptionstatus == "" )
            ) {
              console.log("subscription is paid and completeSubscription is called");
              this.completeSubscription(this.token);
            } else if (!response.data.ispaid && this.retryCount <= 0) {
              console.log("retryCount is 0");
              this.handelCheckoutAccepted();
              this.retryCount = this.retryCount - 1;
            } else {
              console.log("sendGTMMessage");
              localStorage.removeItem("host");
              this.sendGTMMessage();
            }
          })
          .catch((error) => {
            // get payment status
            this.loading = false;
            this.paymentError = true;
            console.error("payment error", error);
          });
      } catch (error) {
        this.loading = false;
        this.paymentError = true;
        console.error("payment error", error);
      }
    },
    completeSubscription(token) {
      this.paymentError = false;
      this.sendreceiptError = false;
      try {
        axios
          .post(
            `/Contracts/completesubscription/${token}?traceId=${this.$store.state.order.traceId}`
          )
          .then((response) => {
            console.log("completsubscription response", response);
            if (
              response.data.subscriptionstatus != null &&
              response.data.subscriptionstatus.length > 0
            ) {
              this.isPaid = true;
              this.loading = false;
              this.sendGTMMessage();
              localStorage.removeItem("host");
              this.sendreceiptSuccess = response.data.receiptsend;
            } else {
              console.log("handelCheckoutAccepted agen");
              this.retryCount = 1;
              this.handelCheckoutAccepted();
            }
          })
          .catch((error) => {
            this.loading = false;
            this.paymentError = true;
            console.error("completsubscription error", error);
          });
      } catch (error) {
        this.loading = false;
        this.paymentError = true;
      }
    },
    retryhandelCheckoutAccepted() {
      this.responseData = null;
      this.loading = true;
      this.paymentError = false;
      this.sendreceiptError = false;
      this.isPaid = false;
      this.retryCount = 3;
      this.handelCheckoutAccepted(this.customer);
    },
    sendGTMMessage() {
      const dataLayerData = {
        uniqueid: this.traceId,
        eventAction: "checkoutAccepted",
        eventCategory: "confirmation",
        contractId: this.customer,
        contractAmount: this.responseData.amount,
        contractPaymentStatus: this.responseData.payment
          ? this.responseData.payment.paymentstatus
          : null,
        contractCurrency: this.responseData.payment
          ? this.responseData.payment.currency
          : null,
      };

      GTMMessage.post(dataLayerData, this.$store.state.order.host);
    },
  },
  computed: {
    ...mapGetters({
      // paymentStatus: "order/paymentstatus",
    }),
  },
};
</script>
