<template>

  <v-container id="step2" >
  <!-- <v-row>
    <CarouselVehiclePictures :VehicleId="car.id" :firstImage="car.image" ></CarouselVehiclePictures>
  </v-row> -->
    <v-row>
      <v-col cols="12" md="7">
       
        <h1 class="text-center mb-4">{{ $t("step2.title") }}</h1>
        <p class="text-center mb-15">{{ $t("step2.subtitle") }}</p>
        <v-form ref="formdetails">
          <v-row v-if="kilometerSelectOptions.length > 0">
            <v-col cols="12" >
               <p
                v-if="car.defaultcontractagreement && monthsView == true"
                v-html="
                  $t('step2.range-text', {
                    included_mdr: car.priceData.maxkmpermonth,
                    included_day: car.priceData.maxkmperday
                  })
                "
              ></p>

              <p
                v-else-if="car.defaultcontractagreement"
                v-html="
                  $t('step2.range-text-dayview', {
                    included: car.defaultcontractagreement.maxkmprday,
                  })
                "
              ></p>
              <v-select
                  :menu-props="{ closeOnContentClick: true }"
                  :items="kilometerSelectOptions"
                  :label="$t('step2.input.range')"
                  v-model="range"
                  dense
                  solo
                >
                  <template v-slot:item="{ item }">
                    <div class="v-list-item__content">
                      <div class="v-list-item__title" :class="{'active':range.id == item.id}">
                       <template v-if="item.included">
                          {{ item.name }}
                        </template>
                        <template v-else-if="item.userspecifiedmaxkm">
                          {{ item.name }}  <small >{{$t("step2.range_userspecifiedmaxkm_extra_text", {price: formatPrice(item.priceperkm) } ) }}</small>
                        </template>
                        <template v-else>
                            <template v-if="monthsView == true">
                                {{ item.name }} 
                                / {{ (item.maxkm / Math.ceil($store.state.order.daysAndMonthsToPay.fullmonths) ).toFixed(0)  }}  {{$t("general.kilometer") }} {{$tc("general.pr_months", 1) }} 
                            </template>
                            <template v-else>
                                {{ item.name }} 
                                / {{ (item.maxkm / Math.ceil($store.state.order.daysToPay) ).toFixed(0)  }}  {{$t("general.kilometer") }} {{$t("general.per_day") }} 
                            </template>
                          
                          <small > 
                           ( pris {{ formatPrice(item.maxkm * item.priceperkm) }} ) 
                          </small>
                        </template>
                      </div>
                    </div>
                  </template>
                  <template v-slot:selection="{ item }">
                   <div class="v-list-item__content">
                      <div class="v-list-item__title" :class="{'active':range.id == item.id}">
                        <template v-if="item.included">
                          {{ item.name }}
                        </template>
                        <template v-else-if="item.userspecifiedmaxkm">
                          {{ item.name }} <small >{{$t("step2.range_userspecifiedmaxkm_extra_text", {price: formatPrice(item.priceperkm) }) }}</small>
                        </template>
                        <template v-else>
                           <template v-if="monthsView == true">
                          {{ item.name }} 
                          /  {{ ( item.maxkm / Math.ceil($store.state.order.daysAndMonthsToPay.fullmonths) ).toFixed(0) }}  {{$t("general.kilometer") }} {{$t("general.pr_months") }} 
                           </template>
                           <template v-else>
                          {{ item.name }} 
                          /  {{ ( item.maxkm / Math.ceil($store.state.order.daysToPay) ).toFixed(0) }}  {{$t("general.kilometer") }} {{$t("general.per_day") }} 
                           </template>
                         
                          <small > 
                           ( pris {{ formatPrice(item.maxkm * item.priceperkm) }}  )
                          </small>
                        </template>
                      </div>
                    </div>
                  </template>
                </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <template  v-if="isRangeUserSpecified">
                
                <v-text-field
                  v-model="customRange"
                
                  :label="$t('step2.input.maxrange', { maxkm: range.maxkm })"
                  :rules="[
                    (v) => v !== '' || $t('validation.required'),
                    (v) => (v !== '' && !isNaN(v)) || $t('validation.number'),
                    (v) => v >  (range.maxkm - 1) || $t('validation.minimum', { min: range.maxkm - 1 }),
                  ]"
                >
                  <template slot="append">
                    {{ $t("general.kilometer") }} 
                  </template>
                </v-text-field>
                <small v-if="monthsView == true">
                  +{{  ( Math.ceil(customRange / $store.state.order.daysAndMonthsToPay.fullmonths) ) }}  {{$t("general.kilometer") }} {{$tc("general.pr_months", 1) }}
                  pris {{ formatPrice(customRange * range.priceperkm) }} 
                </small>
                <small v-else>
                  +{{  ( Math.ceil(customRange / $store.state.order.daysToPay) ) }}  {{$t("general.kilometer") }} {{$t("general.per_day") }}
                  pris {{ formatPrice(customRange * range.priceperkm) }} 
                </small>
              </template>
            </v-col>
          </v-row>


          <v-row>
            <v-col cols="12" v-if="$store.state.company.availableAccessories.length > 0">
              <h2>{{ $t("step2.input.addons") }}</h2>
              <AccessoriesPicker :car="car" />
            </v-col>
          </v-row>
          <v-row v-if="defaultcontractagreement" >
            <v-col cols="12" >
              <h2>{{ $t("step2.insurance.title") }}</h2>
              <p  v-if="car.defaultcontractagreement.insurancechoice == 'UsersChoice'  || car.defaultcontractagreement.insurancechoice == 'Mandatory' ">
                {{ $t("step2.insurance.text") }}
              </p>
              <p v-else >{{ $t("step2.insurance.ownrisk_not_available", {price: formatPrice(car.defaultcontractagreement.ownriskprice)}) }}</p>
              <v-checkbox :disabled="insuranceMandatory" v-if="car.defaultcontractagreement.insurancechoice == 'UsersChoice'  || car.defaultcontractagreement.insurancechoice == 'Mandatory'" :class="[ {'mandatory':insuranceMandatory}, 'flex-column','align-start']"
                v-model="insurance"
              >
                <template slot="label" >
                  <span>
                    <b v-if="car.priceData">
                      <template v-if="monthsView == true"   >
                        {{ $t("step2.insurance.ownrisk_text", { price:  formatPrice(defaultcontractagreement.insurancepricepermonth) , enddec :  $tc("general.pr_months",1) }) }}
                      </template>
                      <template v-else   >
                        {{ $t("step2.insurance.ownrisk_text", { price:  formatPrice(defaultcontractagreement.insurancepriceperday) , enddec :  $t("general.per_day") }) }}
                      </template>
                    </b>
                    <br />
                    <small>
                      {{
                        $t("step2.insurance.ownrisk_subtext", {
                          ownriskprice: formatPrice( defaultcontractagreement.ownrisknotselectedprice),
                          minownriskprice:formatPrice(defaultcontractagreement.ownriskselectedprice)
                        })
                      }}
                      <!-- template v-if="monthsView == false">
                        {{
                          $t("step2.insurance.ownrisk_subtext_daysview", {
                            maxinsuranceprice:formatPrice(defaultcontractagreement.maxinsuranceprice)
                          })
                        }}
                      </template -->
                    </small>
                  </span>
                </template>
              </v-checkbox>
             
             


            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col cols="1" class="d-none d-sm-flex justify-center">
        <v-divider vertical></v-divider>
      </v-col>
      <v-col cols="12" md="4">
        <ChosenCompact :car="car"></ChosenCompact>
      </v-col>
    </v-row>
    <v-row class="er-btn-row">
      <v-col class="er-btn-col" >
        <v-btn block x-large rounded @click="previousStep">
          {{ $t("general.back") }}
        </v-btn>
      </v-col>
      <v-col class="er-btn-col" >
        <v-btn color="primary" block x-large rounded @click="nextStep">
          {{ $t("general.next") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import ChosenCompact from "../components/ChosenCompact.vue";
import {  numberFormatHelperMixin } from "../Helpers/numberFormatHelperMixin";
import Scroll from '@/Helpers/Scroll';
import AccessoriesPicker from "../components/AccessoriesPicker";
import GTMMessage from "@/Helpers/GTMMessage";
import { priceHelperMixin } from "../Helpers/price";
// import CarouselVehiclePictures from "../components/CarouselVehiclePictures.vue"; 


export default {
  name: "Step2",
  mixins: [numberFormatHelperMixin, priceHelperMixin],
   data() {
    return {
      insuranceMandatory:false
    };
  },
  components: { ChosenCompact, AccessoriesPicker /*, CarouselVehiclePictures */},
   mounted() {
    Scroll.sendScroll(this.$store.state.order.container, this.$store.state.order.headOffset, this.$store.state.order.host);
    if (this.$store.state.order.car && this.$store.state.order.car.defaultcontractagreement && this.$store.state.order.car.defaultcontractagreement.insurancechoice == "Mandatory" ) {
      this.insuranceMandatory = true;
      this.insurance = true;
    }

  },
  methods: {
    calcInsurancePriceSubscriptionPeriods( totalprice , periods){
      return ( totalprice / periods).toFixed(2);
    },
    updatePrice() {
      this.$store.dispatch("company/GET_PRICE_FOR_VEHICLE", this.car.id);
    },
    previousStep() {
      
      this.$store.dispatch("order/RESET_CHOSEN", this.car.id);
      this.$store.commit("step/PREVIOUS");
      // this.$destroy();
    },
    nextStep() {
      if (!this.$refs.formdetails.validate()) return;
      GTMMessage.changeStep('rentalcar', 'next', this.$store.state.order.host, this.$store.state.order.traceId);
      this.$store.commit("step/NEXT");
    },
    updateCustomRange(value) {
      this.$store.commit("order/SET_CUSTOM_RANGE", value);
    }
    ,
    updateAccessoriesByItem(items){
        this.$store.dispatch("order/SET_ACCESSORIESBYITEM", items);
        this.$store.dispatch("company/GET_PRICE_FOR_VEHICLE", this.car.id);
    }
  },
  computed: {
    defaultcontractagreement: {
      get ()  {
       return this.$store.state.order.insurancePriceForCar
      }
    },
    kilometerSelectOptions: {
      get() {
       if(this.$store.state.company.kilometerOptions.length){

         return [
          {
            name: this.$i18n.t("step2.input.range"),
            maxkm: 0,
            id: 0,
            userspecifiedmaxkm: false,
            included: true,
            priceperkm: 0,
          },
          ...this.$store.state.company.kilometerOptions,
        ];
          } 
        return [];
      },
    },
    range: {
      get() {
        const range = this.$store.state.order.range;
        return range != undefined ||range != null? range : {};
      },
      set(value) {
        if (value.id == 0) {
          this.$store.dispatch("order/SET_RANGE", null);
        } else {
          this.$store.dispatch("order/SET_RANGE", value.maxkm);
        }

        if (value.userspecifiedmaxkm) {
          this.customRange = value.maxkm
        }

        this.$store.dispatch("company/GET_PRICE_FOR_VEHICLE", this.car.id);
      },
    },
    customRange: {
      get() {
        return this.$store.state.order.customRange;
      },
      set(value) {
        this.updateCustomRange(value);
        this.$store.dispatch("company/GET_PRICE_FOR_VEHICLE", this.car.id);
      },
    },
    insurance: {
      get() {
        return this.$store.state.order.insurance;
      },
      set(value) {
        this.$store.dispatch("order/SET_INSURANCE", value);
        this.$store.dispatch("company/GET_PRICE_FOR_VEHICLE", this.car.id);
      },
    },
    ...mapState({ car: (state) => state.order.car }),
    ...mapGetters({
      kilometerOptionNames: "company/kilometerOptionNames",
      kilometerOptionsValues: "company/kilometerOptionsValues",
      isRangeUserSpecified: "order/isRangeUserSpecified",
      kilometerOptions: "company/kilometerOptions",
      monthsView: "order/getMonthsView",
      availableAccessories: "company/availableAccessories",
    }),
  },
};
</script>
<style lang="scss">

#app .v-list-item.primary--text{
    background-color: white  !important;
    color: white  !important;
}
.v-list-item__content{
  color:rgba(0, 0, 0, 0.8) !important;
}
.active{
  color: var(--v-primary-base) !important;
}

// .v-input--checkbox.mandatory {
//   cursor: not-allowed !important;
//   .v-label{
//     cursor: not-allowed !important;
//   }
// }
</style>
