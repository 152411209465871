<template>
  <v-container class="step3">
    <v-row v-if="showTermsOfTrade" class="terms-of-trade-view">
      <v-col>
        <v-card width="100vw">
          <v-card-actions>
            <v-btn  color="teal accent-4" icon @click="clickShowTermsOfTrade()"><v-icon>mdi-close-box</v-icon></v-btn>
          </v-card-actions>
          <v-card-text>
            <div v-html="termsOfTradehtmlData"></div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!showTermsOfTrade">
      <v-col>
        <h1 class="text-center">{{ $t("step3.title") }}</h1>
      </v-col>
    </v-row>
    <v-form v-if="!showTermsOfTrade" ref="contactForm" autocomplete="on">
      <v-row>
        <v-col>
          <v-text-field
            name="firstname"
            :placeholder="$t('step3.input.firstname')"
            v-model="firstName"
            :rules="[(v) => v !== '' || $t('validation.required')]"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            name="lastname"
            :placeholder="$t('step3.input.lastname')"
            v-model="lastName"
            :rules="[(v) => v !== '' || $t('validation.required')]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            name="organization"
            :placeholder="$t('step3.input.companyname')"
            v-model="companyName"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            :placeholder="$t('step3.input.cvr')"
            v-model="cvr"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            name="address"
            :placeholder="$t('step3.input.address')"
            v-model="address"
            :rules="[(v) => v !== '' || $t('validation.required')]"
          ></v-text-field
        ></v-col>
        <v-col>
          <v-text-field
            name="city"
            :placeholder="$t('step3.input.city')"
            v-model="city"
            :rules="[(v) => v !== '' || $t('validation.required')]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            name="zipcode"
            :placeholder="$t('step3.input.zipcode')"
            v-model="zipcode"
            :rules="[
              (v) => v !== '' || $t('validation.required'),
              (v) => !isNaN(v) || $t('validation.number'),
            ]"
          ></v-text-field>
        </v-col>
        <v-col>
          <BirthdateDatepicker
            v-model="birthdate"
            no-title
            :placeholder="$t('step3.input.birthdate')"
            :max="min18"
            active-picker="YEAR"
            :rules="[(v) => v !== '' || $t('validation.required')]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            name="email"
            :placeholder="$t('step3.input.email')"
            v-model="email"
            :rules="[
              (v) => v !== '' || $t('validation.required'),
              (v) =>
                !v ||
                /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                $t('validation.email'),
            ]"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            name="phone"
            :placeholder="$t('step3.input.phone')"
            v-model="phone"
            :rules="[
              (v) => v !== '' || $t('validation.required'),
              (v) => !isNaN(v) || $t('validation.number'),
            ]"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-checkbox
            v-model="acceptTerms"
            :label="$t('step3.input.acceptTerms')"
            :rules="[(v) => v || $t('validation.required')]"
          >
          </v-checkbox>
        </v-col>
        <v-col cols="12"  v-if="showAcceptsSms"  >
          <v-checkbox
            v-model="acceptssms"
            :label="$t('step3.input.acceptSms')"
          >
          </v-checkbox>
        </v-col>
        <v-col cols="12">
          <v-checkbox
            v-model="acceptTermsOfTrade"
            :label="$t('step3.input.acceptTermsOfTrade')"
            :rules="[(v) => v || $t('validation.required')]"
          >
          </v-checkbox>
          <a @click="clickShowTermsOfTrade()" class="ml-8">{{$t('step3.input.readTermsOfTrade')}}</a>
          <!-- <v-menu
            v-model="menu"
            :close-on-content-click="false"
            top
            nudge-top="100vh"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="indigo" dark v-bind="attrs" v-on="on">
                Læs handelsbetingelser
              </v-btn>
            </template>
            <v-card width="100vw">
              <v-container>
                <v-row>
                  <v-col>
                    <div v-html="termsOfTradehtmlData"></div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-menu> -->
        </v-col>
      </v-row>
      <v-row class="er-btn-row">
        <v-col class="er-btn-col">
          <v-btn block x-large rounded @click="previousStep">
            {{ $t("general.back") }}
          </v-btn>
        </v-col>
        <v-col class="er-btn-col" >
          <v-btn rounded block x-large color="primary" @click="goToPayment">
            {{ $t("general.next") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <!-- <div v-html="htmlData" ></div> -->
  </v-container>
</template>
<script> 
import BirthdateDatepicker from "../components/Fragments/BirthdateDatepicker.vue";
import Scroll from "@/Helpers/Scroll";
import GTMMessage from "@/Helpers/GTMMessage";
import { GetTermsOfTrade } from "@/Helpers/apiDataHelper";
import { DateTime, Duration } from "luxon";

export default {
  name: "Step3",
  components: { BirthdateDatepicker },
  async mounted() {
    Scroll.sendScroll(
      this.$store.state.order.container,
      this.$store.state.order.headOffset,
      this.$store.state.order.host
    );
    const termsOfTrade = await GetTermsOfTrade(
      this.$store.state.order.companyId,
      this.$store.state.order.traceId
    );
    this.termsOfTradehtmlData = termsOfTrade.data;
  },
  data() {
    return {
      today: new Date(),
      acceptTerms: false,
      acceptTermsOfTrade: false,
      termsOfTradehtmlData: "",
      showTermsOfTrade:false
    };
  },
  methods: {
    goToPayment() {
      if (!this.$refs.contactForm.validate()) return;
      GTMMessage.changeStep(
        "customer-info",
        "next",
        this.$store.state.order.host,
        this.$store.state.order.traceId
      );
      this.$store.commit("step/NEXT");
    },
    previousStep() {
      GTMMessage.changeStep(
        "customer-info",
        "previous",
        this.$store.state.order.host,
        this.$store.state.order.traceId
      );
      this.$store.commit("step/PREVIOUS");
    },
    clickShowTermsOfTrade(){
      this.showTermsOfTrade = !this.showTermsOfTrade
      if (this.showTermsOfTrade) {
         this.$vuetify.goTo(0)
      }
    },
  },

  computed: {
    min18() {
      return new Date(
        this.today.getFullYear() - 18,
        this.today.getMonth(),
        this.today.getDate()
      )
        .toISOString()
        .substring(0, 10);
    },
    showAcceptsSms:{
      get() {
     
        if(this.$store.state.order.showSmsNotify){
          const a =  DateTime.fromISO(this.$store.state.order.pickupDate);
          const b = DateTime.local().plus( Duration.fromObject({ day: 1 }) );
          const c = DateTime.local();
          const tomorrow =  a.hasSame(b, "day") && a.hasSame(b, "month") && a.hasSame(b, "year");
          const toDay =  a.hasSame(c, "day") && a.hasSame(c, "month") && a.hasSame(c, "year");
          if (!tomorrow && !toDay) {
            return true;
          } 
        }

        return false;
      }
    },
    firstName: {
      get() {
        return this.$store.state.order.contactInfo.firstName;
      },
      set(value) {
        this.$store.commit("order/SET_FIRSTNAME", value);
      },
    },
    lastName: {
      get() {
        return this.$store.state.order.contactInfo.lastName;
      },
      set(value) {
        this.$store.commit("order/SET_LASTNAME", value);
      },
    },
    companyName: {
      get() {
        return this.$store.state.order.contactInfo.companyName;
      },
      set(value) {
        this.$store.commit("order/SET_COMPANYNAME", value);
      },
    },
    cvr: {
      get() {
        return this.$store.state.order.contactInfo.cvr;
      },
      set(value) {
        this.$store.commit("order/SET_CVR", value);
      },
    },
    address: {
      get() {
        return this.$store.state.order.contactInfo.address;
      },
      set(value) {
        this.$store.commit("order/SET_ADDRESS", value);
      },
    },
    city: {
      get() {
        return this.$store.state.order.contactInfo.city;
      },
      set(value) {
        this.$store.commit("order/SET_CITY", value);
      },
    },
    zipcode: {
      get() {
        return this.$store.state.order.contactInfo.zipcode;
      },
      set(value) {
        this.$store.commit("order/SET_ZIPCODE", value);
      },
    },
    birthdate: {
      get() {
        return this.$store.state.order.contactInfo.birthdate;
      },
      set(value) {
        this.$store.commit("order/SET_BIRTHDATE", value);
      },
    },
    email: {
      get() {
        return this.$store.state.order.contactInfo.email;
      },
      set(value) {
        this.$store.commit("order/SET_EMAIL", value);
      },
    },
    phone: {
      get() {
        return this.$store.state.order.contactInfo.phone;
      },
      set(value) {
        this.$store.commit("order/SET_PHONE", value);
      },
    },
    acceptssms: {
      get() {
        return this.$store.state.order.acceptssms;
      },
      set(value) {
        this.$store.commit("order/SET_ACCEPTSSMS", value);
      },
    },
  },
};
</script>
