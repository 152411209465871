<template>
  <v-row class="car-grid" v-if="filteredItems">
    <v-col cols="12" class="menu-items">
      <v-btn @click="toggleVehicleTypesClick('')" rounded :class="{
        primary: toggleVehicleTypes.includes(''),
        'menu-item': true,
      }">
        {{ $t("general.all") }}
      </v-btn>
      <v-btn v-for="vt in filteredTypes" rounded @click="toggleVehicleTypesClick(vt.name)" :class="{
        primary: toggleVehicleTypes.includes(vt.name),
        'menu-item': true,
      }" :key="vt.name">
        {{ vt.name }}
      </v-btn>

      <slot name="menuend"></slot>
    </v-col>
    <v-col cols="12" v-if="allCarsShouldHide">
      {{ $t("component.availableMonthsOptions.periodesOptionsError") }}
    </v-col>
    <v-col cols="12">
      <v-row>
        <v-col v-for="car in filteredItems" cols="12" sm="6" md="4" xl="3" :key="car.id">
          <VehicleCard :car="car" :focus="isVehicleInFocus(car.id)" :months-view="monthsView" @selected="selectCar(car)"
            :isBruttoList="isBruttoList"></VehicleCard>
          <!-- <VehicleCard :cars="filteredItems" :months-view="monthsView" @selected="selectCar"></VehicleCard> -->
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import { priceHelperMixin } from "../Helpers/price";
import GTMMessage from "@/Helpers/GTMMessage";
import VehicleCard from "./Fragments/VehicleCard";

export default Vue.component("carGrid", {
  components: { VehicleCard },
  props: ["carList", "vehicleTypes", "monthsView", "vehiclesInFocus", "isBruttoList"],
  mixins: [priceHelperMixin],
  data() {
    return {
      toggleVehicleTypes: [''],
    };
  },
  computed: {
    ...mapGetters({
      // carList: "company/getCarList",
      // vehicleTypes: "company/vehicleTypes",
      // monthsView: "order/getMonthsView",

    }),
    allCarsShouldHide() {
      return this.carList.every((x) => x.shouldHide);
    },
    filteredTypes() {
      const cars = this.carList.map((x) => {
        if (x.vehicleclass != undefined) {
          return x.vehicleclass.name;
        }
      });
      return this.vehicleTypes.filter((item) => {
        if (cars.includes(item.name)) {
          return item;
        }
      });
    },
    filteredItems() {
      const selectVehicleType = this.toggleVehicleTypes;
      let vehicles = [];

      if (selectVehicleType.includes('')) {
        vehicles = this.carList;
      } else {
        vehicles = this.carList.filter((item) => {
          if (
            item.vehicleclass != undefined &&
            selectVehicleType.includes(item.vehicleclass.name)
          ) {
            return item;
          }
        });
      }
      if (this.vehiclesInFocus && this.vehiclesInFocus.length > 0) {
        vehicles = vehicles.sort((a, b) => this.vehiclesInFocus.includes(b.id) - this.vehiclesInFocus.includes(a.id));
      }

      return vehicles



    },
    hasCarType() {
      return this.cars === undefined || this.cars?.length > 0;
    },
  },
  methods: {
    isVehicleInFocus(id) {
      if (this.vehiclesInFocus && this.vehiclesInFocus.length > 0) {
        return this.vehiclesInFocus.includes(id) ? true : false;
      }
      return false;
    },
    toggleVehicleTypesClick(val) {
      if (val == '') {
        if (
          this.toggleVehicleTypes.includes('') &&
          this.toggleVehicleTypes.length > 1
        ) {
          const index = this.toggleVehicleTypes.indexOf(val);
          this.toggleVehicleTypes.splice(index, 1);
        } else {
          this.toggleVehicleTypes = [''];
        }
      } else {
        if (this.toggleVehicleTypes.includes('')) {
          const index0 = this.toggleVehicleTypes.indexOf('');
          this.toggleVehicleTypes.splice(index0, 1);
        }

        if (this.toggleVehicleTypes.includes(val)) {
          const index = this.toggleVehicleTypes.indexOf(val);
          this.toggleVehicleTypes.splice(index, 1);
        } else {
          this.toggleVehicleTypes = [val, ...this.toggleVehicleTypes];
        }
      }

      if (this.toggleVehicleTypes.length == 0) {
        this.toggleVehicleTypes = [''];
      }
    },
    selectCar(car) {
      const dataLayerData = {
        uniqueid: this.$store.state.order.traceId,
        eventAction: "selected",
        eventCategory: "rentalcarlist",
        rentalcarId: car.id,
        rentalcarClass: car.vehicleclass ? car.vehicleclass.name : "",
        rentalcarModel: car.vehiclemodel ? car.vehiclemodel.name : "",
        rentalcarBrand:
          car.vehiclemodel && car.vehiclemodel.vehiclebrand
            ? car.vehiclemodel.vehiclebrand.name
            : "",
      };

      GTMMessage.post(dataLayerData, this.$store.state.order.host);

      this.$emit("selected", car);
      // this.carList.find((x) => x.id == car.id);

    },
  },
});
</script>

<style lang="scss" scoped>
.fill-80 {
  width: 80%;
  max-width: 80%;
}


.v-image__image--preload {
  filter: none;
}
</style>

<style lang="scss">
// temp
.v-image__image--preload {
  filter: none;
}

.car-grid {
  .menu-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 42px;

    .menu-item {
      padding-left: 20px;
      padding-right: 20px;
      margin-right: 8px;
      margin-left: 8px;
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }
}
</style>