<template>
  <v-col cols="12" class="expansion-panel-accessories">
    <v-row class="primary white--text">
      <v-col class="header">
        <strong class="header-text">
          {{ headerText }}
        </strong>
        <v-icon @click="toggle" class="header-icon" color="white">reorder</v-icon>
      </v-col>
    </v-row>
    <v-row class="content">
      <v-col cols="12" class="content-closed" v-if="!open">
        <span v-for="(item, index) in accessories" :key="index">
          &nbsp;{{ item.name }} <small> x {{ item.quantity }}</small><i v-if="index != accessories.length - 1">,</i>
        </span>
      </v-col>
      <v-col cols="12" class="content-open" v-if="open">
        <v-row>
          <v-col cols="12">
            <v-row v-for="(item, i) in accessories" :key="i">
              <v-col cols="6" class="pt-0">
                {{ item.name }} <small>x {{ item.quantity }}</small>
              </v-col>
              <v-col cols="6" class="
                    text-right text-sm-right text-md-left text-lg-right
                    pt-0
                  ">
                <template v-if="item.pricetype == 'DayPrice'">
                  <template v-if="monthsview == true">
                    {{ formatPrice(item.pricepermonth) }}
                    {{ $tc("general.pr_months", 1) }}
                  </template>
                  <template v-else>
                    {{ formatPrice(item.price) }}
                    {{ $t("general.per_day") }}
                  </template>
                  <br />
                  <small>
                    {{ $t("general.total") }}:
                    {{ formatPrice(item.totalprice) }}
                  </small>
                </template>
                <template v-else>
                  {{ formatPrice(item.totalprice) }}
                </template>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="totalPrice > 0" cols="12" class="d-flex flex-row justify-space-between">
        <strong>{{ $t("general.total") }}</strong>
        <strong class="text-right">{{ formatPrice(totalPrice) }}</strong>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import Vue from "vue";
import { priceHelperMixin } from "../../Helpers/price";

export default Vue.component("ChosenExpansionPanelAccessories", {
  methods: {
    toggle() {
      this.open = !this.open;
    },
  },
  mixins: [priceHelperMixin],
  props: {
    accessories: {
      default: () => [],
      type: Array,
    },
    monthsview: {
      default: false,
      type: Boolean,
    },
    totalPrice: {
      default: 0,
      type: Number,
    },
    headerText: {
      default: "",
      type: String,
    },
  },
  computed: {

  },
  data() {
    return {
      open: false,
    };
  },
});
</script>
<style lang="scss" scoped>
.expansion-panel-accessories {

  //   width: 100%;
  .header {
    &-icon {
      text-align: right;
    }

    &-text {
      flex: 1;
    }

    // width: 100%;
    display: flex;
    align-content: space-between;
  }

  .content {
    border: 1px solid #8c8c8c;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>