<template>
  <div tag="aside">
    <v-row class="header">
      <v-col>
        <slot name="header">
          <h2>{{ $t("component.asidecar.title") }}</h2>
        </slot>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <CarouselVehiclePictures :VehicleId="car.id" :firstImage="car.image"></CarouselVehiclePictures>
      </v-col>
    </v-row>
    <v-row v-if="carName">
      <v-col cols="12" class="text-center">
        <h3 class="car-name" title="double click to copy id" v-on:dblclick="clipboard">{{ carName }}</h3>
      </v-col>
    </v-row>

    <!-- totaldays -->
    <v-row class="primary white--text">
      <v-col cols="6" md="12" lg="6" class="font-weight-bold">
        {{ $t("component.asidecar.rental_period") }}
      </v-col>
      <v-col cols="6" md="12" lg="6" class="text-right text-sm-right text-md-left text-lg-right">
        <p v-if="car.priceData.numberofsubscriptionperiods > 0" class="mb-0">
          {{ car.priceData.numberofsubscriptionperiods }}
          {{ $tc("general.months", car.priceData.numberofsubscriptionperiods) }}
          <span v-if="car.priceData.numberofsubscriptionperiodsdaysleft">
            {{ car.priceData.numberofsubscriptionperiodsdaysleft }}
            {{ $tc("general.days", car.priceData.numberofsubscriptionperiodsdaysleft) }}
          </span>
        </p>
        <p v-else class="mb-0">
          {{ car.priceData.numberofsubscriptionperiodsdaysleft }}
          {{ $tc("general.days", car.priceData.numberofsubscriptionperiodsdaysleft) }}
        </p>
      </v-col>
    </v-row>

    <v-row class="pickupAndDeliveryTime">
      <v-col cols="6" md="12" lg="6" class="pickupAndDeliveryTime-pickup text-center">
        <span>{{ $t("component.asidecar.pickup") }}</span>
        <br />
        <span>
          {{ formatDate(pickup) }}
          {{ $t("general.time_symbol") }}
          {{ this.$store.state.order.pickupTime }}
        </span>
      </v-col>
      <v-col cols="6" md="12" lg="6" class="pickupAndDeliveryTime-delivery text-center">
        <span>{{ $t("component.asidecar.delivery") }}</span>
        <br />
        <span>
          {{ formatDate(delivery) }}
          {{ $t("general.time_symbol") }}
          {{ this.$store.state.order.deliveryTime }}
        </span>
      </v-col>
    </v-row>

    <!-- 
      ## Range Included
     -->
    <v-row>
      <v-col cols="12">
        <v-row class="chosen-info-card-list">
          <div class="chosen-info-card-item primary white--text" v-if="rangeIncluded">
            <div class="chosen-info-card-head">
              {{ $t("component.asidecar.range_included") }}
            </div>
            <div class="chosen-info-card-content">
              <div class="chosen-info-card-content--item">
                <template v-if="monthsView == true">
                  {{ car.priceData.maxkmpermonth.toLocaleString('da-DK') }} {{ $t("general.kilometer") }}
                  {{ $tc("general.pr_months", 1) }}
                  <span class="chosen-info-card-content--sub-info">
                    {{ $t("general.total") }}:
                    {{
                      Math.ceil(
                        car.priceData.maxkmpermonth *
                        car.priceData.numberofsubscriptionperiods
                      ).toLocaleString('da-DK')
                    }}
                    {{ $t("general.kilometer") }}
                  </span>
                </template>
                <template v-else>
                  {{ car.priceData.maxkmperday.toLocaleString('da-DK') }} {{ $t("general.kilometer") }}
                  {{ $t("general.per_day") }}
                  <span class="chosen-info-card-content--sub-info">
                    {{ $t("general.total") }}:
                    {{
                      Math.ceil(
                        car.priceData.maxkmperday * car.priceData.rentingdays
                      ).toLocaleString('da-DK')
                    }}
                    {{ $t("general.kilometer") }}
                  </span>
                </template>
              </div>
            </div>
          </div>

          <div class="chosen-info-card-item primary white--text"
            v-if="this.$store.state.company.kilometerOptions.length && range">
            <div class="chosen-info-card-head">
              {{ $t("component.asidecar.range") }}
            </div>
            <div class="chosen-info-card-content">
              <div class="chosen-info-card-content--item">
                +{{ Math.ceil(range.maxkm).toLocaleString('da-DK') }} {{ $t("general.kilometer") }}
                <span class="chosen-info-card-content--sub-info">
                  {{ $t("general.price") }}:
                  {{ formatPrice(car.priceData.totalextrakmpackagesprice) }}
                </span>
              </div>
            </div>
          </div>

          <div class="chosen-info-card-item primary white--text" v-if="this.$store.state.order.insurance">
            <div class="chosen-info-card-head">
              {{ $t("component.asidecar.insurance") }}
            </div>
            <div class="chosen-info-card-content">
              <div class="chosen-info-card-content--item" v-if="defaultcontractagreement && monthsView == true">
                {{ formatPrice(defaultcontractagreement.insurancepricepermonth) }}
                {{ $t("general.per_mnd") }}
              </div>
              <div v-else class="chosen-info-card-content--item">
                {{ formatPrice(defaultcontractagreement.insurancepriceperday) }}
                {{ $t("general.per_day") }}
                <span class="chosen-info-card-content--sub-info">
                  {{ $t("general.total") }}:
                  <template v-if="defaultcontractagreement.totalinsuranceprice <=
                    defaultcontractagreement.maxinsuranceprice
                    ">
                    {{ formatPrice(defaultcontractagreement.totalinsuranceprice) }}
                  </template>
                  <template v-else>
                    {{ formatPrice(defaultcontractagreement.maxinsuranceprice) }}
                  </template>
                </span>
              </div>
            </div>
          </div>

          <!-- <div class="chosen-info-card-item primary white--text">
            <div class="chosen-info-card-head">Kilometer inkluderet</div>
            <div class="chosen-info-card-content">
              <div class="chosen-info-card-content--item">+400 km<span class="chosen-info-card-content--sub-info">Hey hey </span></div>
              <div class="chosen-info-card-content--item">800 km<span class="chosen-info-card-content--sub-info">Total:200 km</span></div>
            </div>
          </div> -->
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <ChosenExpansionPanelAccessories v-if="accessories && accessories.length > 0"
        :headerText="$t('component.asidecar.addons')" :accessories="accessories" :monthsview="monthsView" :totalPrice="car.priceData.totalaccessoriesprice + (monthsView ? car.priceData.extradriverspricepermonth : car.priceData.totalextradriversprice)
          "></ChosenExpansionPanelAccessories>
    </v-row>
    <v-row v-if="monthsView == true &&
      car.priceData.totalprice != car.priceData.subscriptiontotalprice
      ">
      <v-col cols="4" md="12" lg="4">
        <span class="text-h6 font-weight-bold">
          {{ $t("component.asidecar.PackageAddOn") }}
        </span>
      </v-col>
      <v-col cols="8" md="12" lg="8" class="text-right text-sm-right text-md-left text-lg-right">
        {{ formatPrice(car.priceData.onetimeprice) }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" md="12" lg="4">
        <span class="text-h5 font-weight-bold">
          {{ $t("general.total") }}
          <template v-if="monthsView == true">
            {{ $tc("general.pr_months", 1) }}
          </template>
        </span>
      </v-col>
      <v-col cols="8" md="12" lg="8" class="text-right text-sm-right text-md-left text-lg-right">
        <template v-if="car.hasDetailedPrice">
          <template v-if="monthsView == true">
            <span class="text-h4 font-weight-bold">
              {{ formatPrice(car.priceData.subscriptionprice) }}
            </span>
            <template class="transition-swing text-body-2" v-if="car.priceData.subscriptionprice != car.priceData.totalprice &&
              getInverseHidePrice('months')
              ">
              <br />
              {{ $t("general.or") }}
              <span class="text-lowercase">{{ $t("general.total") }}</span>
              {{ formatPrice(car.priceData.totalprice) }}
            </template>
          </template>
          <template v-else>
            <span class="text-h4 font-weight-bold">
              {{ formatPrice(car.priceData.totalprice) }}
            </span>
            <br />
            <template v-if="car.priceData.subscriptionprice != 0 && getInverseHidePrice('days')">
              <br />
              <span class="transition-swing text-body-2">
                {{ $t("general.or") }}
                {{ formatPrice(car.priceData.subscriptionprice) }}
                {{ $tc("general.pr_months", 1) }}
              </span>
            </template>
          </template>
        </template>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <p v-if="this.$store.state.company.detailedPriceError" class="red--text detailed-price-error py-2"
          v-html="$t('component.asidecar.detailedPriceError')"></p>
      </v-col>
    </v-row>

    <v-row class="body-2" v-if="car.defaultcontractagreement">
      <v-col cols="6" md="12" lg="6" class="font-weight-bold">
        {{ $t("component.asidecar.priceforoverrunkm") }}
      </v-col>
      <v-col cols="6" md="12" lg="6" class="text-right text-sm-right text-md-left text-lg-right pt-0">
        {{ formatPrice(car.defaultcontractagreement.priceforoverrunkm) }}
      </v-col>
    </v-row>

    <v-row class="body-2">
      <v-col cols="6" md="12" lg="6" class="font-weight-bold">
        {{ $t("component.asidecar.rent_price") }}
      </v-col>
      <v-col cols="6" md="12" lg="6" class="text-right text-sm-right text-md-left text-lg-right">
        {{ formatPrice(car.priceData.totalrentingprice) }}
      </v-col>
    </v-row>
    <v-row class="body-2" v-if="monthsView == false">
      <v-col cols="4" md="12" lg="4">
        <span class="font-weight-bold">
          {{ $t("component.asidecar.daypris") }}
        </span>
      </v-col>
      <v-col cols="8" md="12" lg="8" class="text-right text-sm-right text-md-left text-lg-right">
        {{ formatPrice(car.priceData.totalprice / car.priceData.rentingdays) }}
        {{ $t("general.per_day") }}
      </v-col>
    </v-row>

    <v-row class="body-2">
      <v-col cols="6" md="12" lg="6" class="font-weight-bold">
        {{ $t("component.asidecar.Pickup_location") }}
      </v-col>
      <v-col cols="6" md="12" lg="6" class="text-right text-sm-right text-md-left text-lg-right">
        <p class="mb-0">
          {{ $store.state.order.startlocation || $store.state.order.department.city }}
          <br />
          <span v-if="$store.state.order.department.id != undefined">{{
            $store.state.order.department.address
          }}</span>
        </p>
      </v-col>
    </v-row>

    <v-row class="body-2" v-on:dblclick="clipboard" title="double click to copy">
      <v-col cols="6" md="12" lg="6" class="font-weight-bold">
        {{ $t("component.asidecar.car_id") }}
      </v-col>
      <v-col cols="6" md="12" lg="6" class="text-right text-sm-right text-md-left text-lg-right">
        <p class="mb-0">
          {{ car.id }}
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="mt-5">
        <p>
          <i>
            {{ $t("component.asidecar.all_price_incl_tax") }}
          </i>
        </p>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { priceHelperMixin } from "../Helpers/price";
import { numberFormatHelperMixin } from "../Helpers/numberFormatHelperMixin";
import ChosenExpansionPanelAccessories from "./Fragments/chosen-expansion-panel-accessories.vue";
import CarouselVehiclePictures from "./CarouselVehiclePictures.vue";
import { DateTime } from "luxon";

export default {
  name: "chosencompact",
  mixins: [priceHelperMixin, numberFormatHelperMixin],
  props: {
    header: String,
    showImageToRight: Boolean,
  },
  components: { ChosenExpansionPanelAccessories, CarouselVehiclePictures },
  methods: {
    formatDate(date) {
      return DateTime.fromISO(date)
        .setLocale(this.$store.state.order.culture)
        .toLocaleString(DateTime.DATE_FULL);
    },
    getInverseHidePrice(typeGiven) {
      return this.$store.getters["order/getInverseHidePrice"](typeGiven);
    },
    clipboard() {
      navigator.clipboard.writeText(this.car.id);
    },
  },
  computed: {
    carName() {
      let name = "";
      if (this.car.vehiclemodel && this.car.vehiclemodel.vehiclebrand) {
        name = `${this.car.vehiclemodel.vehiclebrand.name} ${this.car.vehiclemodel.name}`;
      }
      return name;
    },
    rangeIncluded() {
      return this.car.priceData.maxkmperday;
    },
    range: {
      get() {
        const customRange = this.$store.state.order.customRange;
        let range = this.$store.state.order.range;
        if (
          range &&
          customRange &&
          range.userspecifiedmaxkm &&
          range.maxkm <= customRange
        ) {
          return {
            name: range.name.replace(range.maxkm, customRange),
            maxkm: customRange,
            priceperkm: range.priceperkm,
          };
        } else {
          return range;
        }
      },
    },
    accessories: {
      get() {
        let extradriver = null;
        if (
          this.car.priceData.extradriverspricepermonth ||
          this.car.priceData.extradriverspriceperday
        ) {
          extradriver = {
            name: "Ekstra fører",
            accessoryid: 99999,
            quantity: this.$store.state.order.extraDrivers.length,
            totalprice: this.car.priceData.totalextradriversprice,
            price: this.car.priceData.extradriverspriceperday,
            pricepermonth: this.car.priceData.extradriverspricepermonth,
            pricetype: this.car.priceData.extradriverspricetype,
          };
        }
        const accessories = extradriver
          ? [...this.car.priceData.accessories, extradriver]
          : this.car.priceData.accessories;

        return accessories;

        // return this.$store.state.order.accessories.map((x) => {
        //   if (x.quantityvalue && x.quantityvalue > 1) {
        //     x.calcPrice =
        //       x.pricetype == "DayPrice"
        //         ? this.totaldays * x.priceincltax * x.quantityvalue
        //         : x.priceincltax * x.quantityvalue;
        //   } else {
        //     x.calcPrice =
        //       x.pricetype == "DayPrice"
        //         ? this.totaldays * x.priceincltax
        //         : x.priceincltax;
        //   }
        //   return x;
        //});
      },
    },
    daysAndMonths: {
      get() {
        return this.$store.state.order.daysAndMonthsToPay;
      },
    },
    totaldays: {
      get() {
        return Math.ceil(this.$store.state.order.daysToPay);
      },
    },

    defaultcontractagreement: {
      get() {
        return this.$store.state.order.insurancePriceForCar;
      },
    },
    ...mapState({
      car: (state) => state.order.car,
    }),
    ...mapGetters({
      delivery: "order/delivery",
      pickup: "order/pickup",
      selectedAccessories: "order/selectedAccessories",
      companysettings: "order/companysettings",
      availableAccessories: "company/availableAccessories",
      monthsView: "order/getMonthsView",
    }),
  },
};
</script>
<style lang="scss" scoped>
.v-expansion-panel {
  border: 1px solid black;

  &::before {
    box-shadow: none;
  }
}

.pickupAndDeliveryTime {
  margin-top: 1em;

  &-pickup {
    border-right: 1px solid #bbb;

    @media #{map-get($display-breakpoints, 'md-only')} {
      border-right: 0px solid #bbb;
    }
  }
}

.chosen-info-card-list {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(1, 1fr);

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media #{map-get($display-breakpoints, 'md-only')} {
    grid-template-columns: repeat(1, 1fr);
  }

  .chosen-info-card-item {
    min-height: 100px;
    display: flex;
    flex-direction: column;

    .chosen-info-card-head {
      text-align: center;
      font-weight: 700;
      padding-top: 1em;
      flex: 1;
    }

    .chosen-info-card-content {
      flex: 2;
      display: flex;

      &--item {
        flex: 1;
        text-align: center;
        display: flex;
        flex-direction: column;
      }

      &--sub-info {
        font-size: 0.8em;
      }
    }
  }
}

.detailed-price-error {
  border: 3px red solid;
  text-align: center;
  font-weight: 600;
}

.header h2 {
  text-align: center;
}
</style>
