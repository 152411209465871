<template>
  <div id="easy-rent-app">
    <v-container v-if="currentStep >= 1">
      <Stepper :currentStep="currentStep" :steps="[
        $t('stepper.step_1'),
        $t('stepper.step_2'),
        $t('stepper.step_3'),
        $t('stepper.step_4'),
      ]"></Stepper>
    </v-container>
    <Step0 v-if="currentStep === 0"></Step0>
    <Step1 v-if="currentStep === 1"></Step1>
    <Step2 v-if="currentStep === 2"></Step2>
    <Step3 v-if="currentStep === 3"></Step3>
    <Step4 v-if="currentStep === 4"></Step4>
  </div>
</template>

<script>
import parameters from "../Helpers/contentdataParameters.js";
import { mapState } from "vuex";
import Stepper from "@/components/Fragments/Stepper.vue";
import { getTraceId, GetPeriods } from "@/Helpers/apiDataHelper";
import Step0 from "./Step0.vue";
import Step1 from "./Step1.vue";
import Step2 from "./Step2.vue";
import Step3 from "./Step3.vue";
import Step4 from "./Step4.vue";

export default {
  name: "Home",
  components: { Stepper, Step0, Step1, Step2, Step3, Step4 },
  beforeMount() { },
  computed: {
    ...mapState({
      currentStep: (state) => state.step.currentStep,
    }),
  },
  methods: {
    setHeadOffset: (_this) => {
      if (!isNaN(Number(parameters.data.scrolloffset))) {
        _this.$store.dispatch("order/SET_CONFIGHEADOFFSET", Number(parameters.data.scrolloffset));
      }
    },
    setContainerId: (_this) => {
      if (parameters.container !== null && parameters.container !== "") {
        let container = parameters.container;
        _this.$store.dispatch("order/SET_CONFIGCONTAINER", container);
      }
    },
    appStartup: (_this) => {
      let companyId = parameters.data.companyid || parameters.data.companyId;
      if (!companyId) {
        console.error("no companyId");
      }
      _this.$store.dispatch("order/INIT_APPDATA", companyId);
    },
    forceLocations: (_this) => {
      let officeid = parameters.data.location || parameters.data.officeid;
      if (officeid) {
        _this.$store.dispatch("order/SET_FORCE_LOCATIONS", officeid);
      }
    },
    activateDeliveryTime() {
      let activatedelivery = parameters.data.restrictdeliverytime; // default false
      if (activatedelivery != "") {
        if (typeof activatedelivery == "boolean") {
          if (activatedelivery == true) {
            this.$store.dispatch(
              "order/SET_ACTIVATE_DELIVERYTIME_FROM_RENTLOG",
              true
            );
          }
        } else if (
          typeof activatedelivery === "string" ||
          activatedelivery instanceof String
        ) {
          if (activatedelivery.toLowerCase() === "true") {
            this.$store.dispatch(
              "order/SET_ACTIVATE_DELIVERYTIME_FROM_RENTLOG",
              true
            );
          }
        }
      }
    },
    payinstoreoptionSetting() {
      let payinstoreoption = parameters.data.showpayinstore; // default false
      if (payinstoreoption != "") {
        if (typeof payinstoreoption == "boolean") {
          if (payinstoreoption == true) {
            this.$store.dispatch("order/SET_PAYINSTOREOPTION", true);

          }
        } else if (
          typeof payinstoreoption === "string" ||
          payinstoreoption instanceof String
        ) {
          if (payinstoreoption.toLowerCase() === "true") {
            this.$store.dispatch("order/SET_PAYINSTOREOPTION", true);


          }
        }
      }
    },


    disableOnlinePayment() {
      let payinstoreoption = parameters.data['disable-online-payment']; // default false
      if (payinstoreoption != "") {
        if (typeof payinstoreoption == "boolean") {
          if (payinstoreoption == true) {
            this.$store.dispatch("order/setDisableOnlinePayment", true);
          }
        } else if (
          typeof payinstoreoption === "string" ||
          payinstoreoption instanceof String
        ) {
          if (payinstoreoption.toLowerCase() === "true") {
            this.$store.dispatch("order/setDisableOnlinePayment", true);
          }
        }
      }
    },
    showBruttoList() {
      const propoption = parameters.data['show-brutto-list']; // default false

      let activate = false;
      if (propoption != "") {
        if (typeof propoption == "boolean") {
          if (propoption == true) {
            activate = true;
          }
        } else if (
          typeof propoption === "string" ||
          propoption instanceof String
        ) {
          if (propoption.toLowerCase() === "true") {
            activate = true;
          }
        }

        if (activate) {
          this.$store.dispatch("order/setShowBruttoList", true);
        }

      }
    },
    minimumRentalPeriod() {
      let prop = parameters.data["minimum-rental-period"];
      if (prop && prop != "") {
        if (typeof prop === "string" || prop instanceof String) {
          if (/m$/.test(prop) || /d$/.test(prop)) {
            this.$store.dispatch("order/setMinimumRentalPeriod", prop);
          }
        }
      }
    },
    async periods() {
      // 27.10.23: When dealing with periods:
      // Before, it was only possible to define periods in months (attr: periods-of-months). In html it looked like this: data-btsettings-periods-of-months="[1,3,6]"
      // Now it is possible to define periods in weeks and months (attr: periods). In html it looks like this: data-btsettings-periods="1w,2w,1m,3m"
      // Note that the new syntax does NOT include the brackets [].

      let period = parameters.data["periods"] || parameters.data["periods-of-months"];
      
      if (!period) return;
      
      let traceId = await getTraceId();
      const _periods = parameters.data["periods"] || '';
      const _periodsOfMonths = parameters.data["periods-of-months"] || '';

      // Get periods from API, sorted by size (shortest duration first)
      const result = await GetPeriods(_periods, _periodsOfMonths, traceId.data);
      if(result.error){
        console.log('error', result.error);
        return;
      }
      
      this.$store.dispatch("order/setPeriodsFromProp", result.data?.periods);
      this.$store.dispatch("order/setShowPeriodsView", true);
    },
    restrictToPeriods() {
      let prop = parameters.data["restrict-to-periods"]; // default false

      if (prop != "") {
        if (typeof prop == "boolean") {
          if (prop == true) {
            this.$store.dispatch("order/setRestrictToPeriods", true);
          }
        } else if (
          typeof prop === "string" ||
          prop instanceof String
        ) {
          if (prop.toLowerCase() === "true") {
            this.$store.dispatch("order/setRestrictToPeriods", true);

          }
        }
      }
    },
    showSMS() {
      let prop = parameters.data["show-sms-notify"]; // default false

      if (prop != "") {
        if (typeof prop == "boolean") {
          if (prop == true) {
            this.$store.dispatch("order/setShowSmsNotify", true);
          }
        } else if (
          typeof prop === "string" ||
          prop instanceof String
        ) {
          if (prop.toLowerCase() === "true") {
            this.$store.dispatch("order/setShowSmsNotify", true);

          }
        }
      }
    },
    periodStartBuffer() {
      const prop = parameters.data["period-start-buffer"];
      if (prop) {
        let parsed = parseInt(prop);
        if (
          typeof parsed === "number" ||
          parsed instanceof Number ||
          !isNaN(parsed)
        ) {
          // parsed = parsed < 30 ? parsed : 15;
          this.$store.dispatch("order/setPeriodStartBuffer", parsed);
        }
      }
    },
  },
  mounted() {

    getTraceId().then((res) => {
      this.$store.dispatch("order/TRACEID", res.data)
      this.setHeadOffset(this);
      this.appStartup(this);
      this.setContainerId(this);
      this.forceLocations(this);
      this.payinstoreoptionSetting();
      this.activateDeliveryTime();
      this.minimumRentalPeriod();
      this.periodStartBuffer();
      this.periods();
      this.restrictToPeriods();
      this.disableOnlinePayment();
      this.showBruttoList();
      this.showSMS();
      const rentingstarttime = parameters.data.rentingstarttime;
      if (rentingstarttime) {
        this.$store.dispatch("order/SET_COMPANY_SETTINGS", {
          rentingstarttime: rentingstarttime,
          parametersLock: "rentingstarttime",
        });
      }

      const rentingendtime = parameters.data.rentingendtime;
      if (rentingendtime) {
        this.$store.dispatch("order/SET_COMPANY_SETTINGS", {
          rentingendtime: rentingendtime,
          parametersLock: "rentingendtime",
        });
      }

    });
  },
};
</script>
