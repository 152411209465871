const environment = process.env.NODE_ENV;

const postScroll = (container, offset, host) => {
    let currentHost = host;
    if (environment === 'development' || host === '') {
        currentHost = document.location.origin;
    }
    parent.postMessage({ action: 8, namespace: 'bt', id: container, offset: offset }, currentHost);
    return true;
};


const sendScroll = postScroll;

export default {
    sendScroll
};