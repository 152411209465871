<template>
  <div>
    <v-card class="payment-options_card flex d-flex flex-column">
      <div class="d-flex justify-center">
        <v-icon class="my-4" large color="black">{{ icon }}</v-icon>
      </div>
      <v-card-title class="justify-center">
        {{ title }}
      </v-card-title>
      <v-card-text v-if="text != ''" class="pb-0">
        <p class="text-center">
          {{ text }}
        </p>
      </v-card-text>
      <v-row class="mx-2 price-list">
        <v-col cols="4">
          {{ $t("general.price") }}
        </v-col>
        <v-col cols="8" class="text-right">
          <b>{{ formatPrice(price) }}</b>
          {{ addetPriceText }}
          <template v-if="additionalPriceText != ''">
            <br />
            <small>{{ additionalPriceText }}</small>
          </template>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-btn color="primary" rounded block :disabled="btnDisabled" :loading="loading" @click="onBookingClick()">
          {{ confirmBtnText }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <p class="mt-4 font-italic body-2 text--secondary" style="min-height: 120px">
      {{ disclaimerText }}
    </p>
  </div>
</template>
<script>
import Vue from "vue";
import { priceHelperMixin } from "../../Helpers/price";

export default Vue.component("payment-options-card", {
  mixins: [priceHelperMixin],
  methods: {
    onBookingClick() {
      this.$emit("onBookingClick");
    },
  },
  props: {
    icon: {
      default: "directions_car",
      type: String,
    },
    title: {
      default: "",
      type: String,
    },
    text: {
      default: "",
      type: String,
    },
    confirmBtnText: {
      default: "",
      type: String,
    },
    disclaimerText: {
      default: "",
      type: String,
    },
    addetPriceText: {
      default: "",
      type: String,
    },
    price: {
      default: 0,
      type: Number,
    },
    additionalPriceText: {
      default: "",
      type: String,
    },
    btnDisabled: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    }
  },
});
</script>
