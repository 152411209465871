const getParameters = () => {
  let uri = window.location.search.substring(1);
  let params = new URLSearchParams(uri);
  return params;
};

const getContainerId = () => {
  const fallback = "";
  const params = getParameters().get("containerid");
  let parameters = params !== null ? params : fallback;
  return parameters;
};

const getHost = () => {
  const fallback = "";
  const params = getParameters().get("host");
  let parameters = params !== null ? params : fallback;
  return parameters;
};

function b64DecodeUnicode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  // https://stackoverflow.com/a/30106551
  return decodeURIComponent(
    atob(str)
      .split("")
      .map(function(c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
}

const getData = () => {
  const params = getParameters().get("contentdata");
  let parameters = params !== null ? JSON.parse(b64DecodeUnicode(params)) : [];
  return parameters;
};

const data = getData();
const container = getContainerId();
const host = getHost();

export default {
  data,
  container,
  host,
};
