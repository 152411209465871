<template>
   <v-alert border="left" elevation="2" outlined prominent type="error">
      <slot></slot>
      <br>
      <a :href="'mailto:support@autoit.dk?subject=Fejl med EasyRent - traceId:' + this.$store.state.order.traceId">Send
         e-mail {{ $t("general.to") }} support@autoit.dk</a>
      <br>
      <small>Trace-Id: {{ this.$store.state.order.traceId }}</small>
   </v-alert>
</template>
<script>
import Vue from "vue";
export default Vue.component("EasyRentErrorMsg", {

});
</script>
