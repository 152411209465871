<template>
  <div style="width: 100%;">
    <div v-if="items.length > 1" style="width: 100%;">
      <v-carousel hide-delimiters cycle interval="2000" height="500">
        <v-carousel-item v-for="(item, i) in items" :key="i" :src="item" cover width="100%"></v-carousel-item>
      </v-carousel>
    </div>
    <div v-else-if="!isLoaded" class="d-flex justify-center" style="height: 400px;">
      <v-progress-circular indeterminate color="primary" model-value="20" :size="78" :width="12"></v-progress-circular>
    </div>
    <v-img v-else class="align-end" :src="firstImage || '/imagecomingsoondefault.png'"></v-img>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "../settings/axios.js";
import { mapGetters } from "vuex";

export default Vue.component("VehiclePicturesCarousel", {
  props: {
    VehicleId: Number,
    firstImage: String,
  },
  data: function () {
    return {
      items: [],
      isLoaded: false,
    };
  },
  mounted() { },
  beforeMount() {
    this.getVehiclePictures();
    // this.getVehiclePictures();
  },
  methods: {
    getVehiclePictures() {
      const limit = 30;

      try {
        axios
          .get(
            `/Vehicle/Pictures?companyId=${this.$store.state.order.companyId}&vehicleId=${this.VehicleId}&traceId=${this.$store.state.order.traceId}&limit=${limit}`
          )
          .then((response) => {
            this.items = [
              // this.firstImage,
              ...response.data.vehiclepictures];
            this.isLoaded = true;

          })
          .catch((error) => {
            this.isLoaded = true;

            console.error("payment error", error);
          });
      } catch (error) {
        this.isLoaded = true;
        console.info("payment error", error);
      }
    },
  },
  ...mapGetters({
    accessValues: "order/getApiAccessValues",
  }),
});
</script>
<style lang="scss" scoped></style>