var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","align-self":"center"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('CarIcon',{staticClass:"caricon"})],1)],1),_c('v-col',{staticClass:"d-none d-sm-flex justify-center",attrs:{"cols":"1"}},[_c('v-divider',{attrs:{"vertical":""}})],1),_c('v-col',{attrs:{"cols":"12","sm":"7","justify":"center"}},[(_vm.$store.state.company.apperror.departments || _vm.$store.state.order.daysAndMonthsToPayError || _vm.$store.state.company.apperror.operationalError)?_c('Er-ErrorMsg',[_vm._v(_vm._s(_vm.$t("general.server_error")))]):_vm._e(),_c('v-form',{ref:"form",attrs:{"id":"step1form","lazy-validation":""}},[_c('h1',{staticClass:"header"},[_vm._v(_vm._s(_vm.$t("step0.title")))]),_c('p',{staticClass:"header-sub"},[_vm._v(_vm._s(_vm.$t("step0.subtitle")))]),(_vm.selectCarFromBruttoList)?_c('v-row',[_c('v-col',[_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(_vm.$t("step0.selectCarFromBrutto")))]),_c('v-chip',{staticClass:"mr-3"},[_vm._v(_vm._s(_vm.selectCarFromBruttoList.carbrandname)+" "+_vm._s(_vm.selectCarFromBruttoList.carmodelname))]),_c('v-btn',{attrs:{"color":"primary","depressed":"","elevation":"2","icon":"","x-small":""},on:{"click":_vm.deselect_CarFromBruttoList}},[_c('v-icon',[_vm._v("close")])],1)],1)],1):_vm._e(),(_vm.locationsOptions && !_vm.loadingstates.locations)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.locationsOptions,"item-value":"name","item-text":"name","disabled":_vm.$store.state.order.forcelocations.force &&
              _vm.$store.state.order.forcelocations.id > 0,"required":"","prepend-inner-icon":"place","label":_vm.$t('step0.input.place'),"rules":[function (v) { return v !== undefined || _vm.$t('validation.required'); }],"no-data-text":_vm.$t('step0.input.noDataText')},on:{"input":function($event){return _vm.setSelectedLocationOrCity(_vm.locationsStart)}},model:{value:(_vm.locationsStart),callback:function ($$v) {_vm.locationsStart=$$v},expression:"locationsStart"}})],1)],1):(_vm.cityOptions && !_vm.loadingstates.departments)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.cityOptions,"item-value":"id","item-text":"city","disabled":_vm.$store.state.order.forceofficeid.force &&
              _vm.$store.state.order.forceofficeid.officeid > 0,"required":"","prepend-inner-icon":"place","label":_vm.$t('step0.input.place'),"rules":[function (v) { return v !== undefined || _vm.$t('validation.required'); }],"no-data-text":_vm.$t('step0.input.noDataText')},on:{"input":function($event){return _vm.setSelectedLocationOrCity(_vm.city)}},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})],1)],1):_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"disabled":true,"prepend-inner-icon":"place","label":_vm.$t('step0.input.place'),"rules":[function (v) { return v !== undefined || _vm.$t('validation.required'); }]}})],1)],1),(_vm.$store.state.order.restrictToPeriods == false && _vm.$store.state.order.periodsFromProp && _vm.$store.state.order.showPeriodsView)?_c('v-row',[_c('v-col',[_c('v-checkbox',{staticClass:"showMeMonthsOptions",attrs:{"label":_vm.$t('step0.periodsMonthsOptionsLabel'),"messages":_vm.$t('step0.periodsMonthsOptionsText')},model:{value:(_vm.chosenShowMonthsOptions),callback:function ($$v) {_vm.chosenShowMonthsOptions=$$v},expression:"chosenShowMonthsOptions"}}),_c('br')],1)],1):_vm._e(),(_vm.$store.state.order.restrictToPeriods || _vm.chosenShowMonthsOptions)?[_c('AvailableMonthsOptions'),(!_vm.$store.state.order.periodesOptionsError && (_vm.officeInfo || _vm.locationsStart) && _vm.pickupDate && _vm.deliveryDate && _vm.$store.state.order.periodeChosenKey)?_c('v-row',[_c('v-col',[(_vm.officeInfoisEmpty == false)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t(
                'step0.periodeInfoText', {
                  address: _vm.officeInfo.address,
                  city: _vm.officeInfo.city,
                  pickupDate: _vm.formatDate(_vm.pickupDate),
                  deliveryDate: _vm.formatDate(_vm.deliveryDate),
                  periodeChosenKey: Number(_vm.$store.state.order.periodeChosenKey.substring(1)),
                  periodLabel: _vm.$store.state.order.periodeChosenKey.substring(0,1) === 'm' ? Number(_vm.$store.state.order.periodeChosenKey.substring(1)) > 1 ? _vm.$t('general.months_plural') : _vm.$t('general.months_singular') : Number(_vm.$store.state.order.periodeChosenKey.substring(1)) > 1 ? _vm.$t('general.weeks_plural') : _vm.$t('general.weeks_singular')
                }
                // TODO -> periodLabel: This should come from same label, separated with a pipe, but it doesn't work atm
              )
                )}}):_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t(
                'step0.periodeInfoTextlocationsStart', {
                city: _vm.locationsStart,
                pickupDate: _vm.formatDate(_vm.pickupDate),
                deliveryDate: _vm.formatDate(_vm.deliveryDate),
                periodeChosenKey: _vm.$store.state.order.periodeChosenKey
              }
              )
                )}})])],1):_vm._e()]:_vm._e(),(_vm.$store.state.order.restrictToPeriods == false
          && !_vm.chosenShowMonthsOptions)?[_c('v-row',[(this.deliveryTime
              && this.deliveryDate
              && this.pickupTime
              && this.pickupDate)?_c('v-col',{staticClass:"py-0"},[(_vm.monthsView && _vm.daysAndMonths)?_c('div',[(_vm.daysAndMonths.fullmonths > 0)?_c('p',[_vm._v(" "+_vm._s(_vm.daysAndMonths.fullmonths)+" "+_vm._s(_vm.$tc("general.months", _vm.daysAndMonths.fullmonths))+" "),(_vm.daysAndMonths.fullmonthsdaysleft)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("general.and"))+" "+_vm._s(_vm.daysAndMonths.fullmonthsdaysleft)+" "+_vm._s(_vm.$tc("general.days", _vm.daysAndMonths.fullmonthsdaysleft))+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("general.is_chosen"))+" ")]):_vm._e()]):(!_vm.monthsView && _vm.daysToPay)?_c('div',[_c('p',[_vm._v(_vm._s(_vm.$tc("step0.count_total_days", _vm.daysToPay)))])]):_vm._e()]):_vm._e()],1),_c('PickupAndDeliveryOptions')]:_vm._e(),_c('v-btn',{attrs:{"block":"","rounded":"","x-large":"","color":"primary","disabled":!_vm.hunkyDoryCheck},on:{"click":_vm.nextStep}},[_vm._v(_vm._s(_vm.$store.state.order.showBruttoList ? _vm.$t("step0.btn.next_brutto") : _vm.$t("step0.btn.next")))])],2)],1),(_vm.$store.state.order.showBruttoList)?_c('v-col',[_c('BruttoListOfVehicles',{on:{"selectCar":_vm.onSelectCar}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }