import axios from "../settings/axios.js";

/**
 * @typedef {Object} daterange
 * @property {Date} startdate 
 * @property {Date} enddate
 */

/**
 * @typedef {Object} checkavailability
 * @property {String} message 
 * @property {Array<{numberofavailablecars:Number, daterange: daterange }>} data
 * @property {Boolean} error
 */

/**
 * Returns checkavailability
 * @returns {checkavailability}
 */
export async function GetCheckavAilability(companyId, traceId, startDate, endDate, dayToDay = false, branchofficeId = 0, selectedCarId) {
  let url = "";
  let resObj = {
    error: false,
    error_message: "",
    data: []
  };
  if (branchofficeId) {
    url = `/Vehicle/checkavailability/${companyId}/${branchofficeId}?traceId=${traceId}`
  } else {
    url = `/Vehicle/checkavailability/${companyId}?traceId=${traceId}`
  }
  try {
    let response = await axios.get(url,
      {
        params: {
          startDate: startDate,
          endDate: endDate,
          dayToDay: dayToDay,
          traceId: traceId,
          rentingCarId: selectedCarId
        },
      }
    );
    resObj.data = response.data;
  } catch (error) {
    console.error(error);
    resObj.error = true;
    resObj.message = error.message
  }
  return resObj;
}



/**
 * @typedef {Object} openinghours
 * @property {String} message 
 * @property {Array<{close:String, date: String, open: String, opened:Boolean }>} data
 * @property {Boolean} error
 */
/**
 * Returns openinghours
 * @returns {openinghours}
 */
export async function GetOpeningHours(companyId, officeId, traceId, startDate, endDate) {
  
  let resObj = {
    error: false,
    error_message: "",
    data: []
  };
  try {
    let response = await axios.get(
      `/companies/openinghours/${companyId}`,
      {
        params: {
          startDate: startDate,
          endDate: endDate,
          traceId: traceId,
          officeId: officeId
        },
      }
    )
    resObj.data = response.data;
  } catch (error) {
    console.error(error);
    resObj.error = true;
    resObj.message = error.message
  }
  return resObj;
}

/**
 * Returns int
 * @returns {number}
 */
export async function GetDaysToPay(companyId, traceId, startDate, endDate) {
  let resObj = {
    error: false,
    error_message: "",
    data: []
  };
  try {
    let response = await axios.get(
      `/Contracts/daysToPay`,
      {
        params: {
          BookingStart: startDate,
          BookingEnd: endDate,
          TraceId: traceId,
          CompanyId: companyId
        },
      }
    )
    resObj.data = response.data.value;

  } catch (error) {
    console.error(error);
    resObj.error = true;
    resObj.message = error.message
  }
  return resObj;
}
/**
 * @typedef {Object} daysAndMonths
 * @property {number} TotalDays 
 * @property {number} FullMonths 
 * @property {number} FullMonthsDaysLeft 
 */
/**
 * Returns daysAndMonths
 * @returns {daysAndMonths}
 */
export async function GetDaysAndMonthsToPay(companyId, traceId, startDate, endDate) {
  let resObj = {
    error: false,
    error_message: "",
    data: []
  };
  try {
    let response = await axios.get(
      `/Contracts/daysAndMonthsToPay`, // ?CompanyId=${companyId}&BookingStart=${startDate}&BookingEnd=${endDate}&TraceId=${traceId}
      {
        params: {
          BookingStart: startDate,
          BookingEnd: endDate,
          TraceId: traceId,
          CompanyId: companyId
        },
      }
    )
    resObj.data = response.data;
      
  } catch (error) {
    console.error(error);
    resObj.error = true;
    resObj.message = error.message
  }
  return resObj;
}

export async function GetAvailablePeriods(companyId, traceId, startDate, periodes, sort, includeDeliveryOpenHours, officeid = null, carId = null, isMonth = true) {
  
  let resObj = {
    error: false,
    error_message: "",
    data: []
  };
  try {
    let data = {
      startdate: startDate,
      companyid: companyId,
      
      traceId: traceId,
      periodes: periodes,
      sort: sort,
      includeDeliveryOpenHours: includeDeliveryOpenHours
    };

    if (officeid) {
      data.officeid = officeid
    }
    if (carId) {
      data.rentingCarId =  carId
    }

    let response = await axios.post(`/Vehicle/${isMonth ? 'availablemonths': 'availableweeks'}`,data)
    resObj.data = response.data;
  } catch (error) {
    console.error(error);
    resObj.error = true;
    resObj.message = error.message
  }
  return resObj;
}

export async function GetInsurancePriceForCar(companyId, traceId, startDate, endDate, rentingcarid, extrakms = [], accessories = []) {
  let resObj = {
    error: false,
    error_message: "",
    data: []
  };
  try {

    let data = {
      startdate: startDate,
      enddate: endDate,
      rentingcarid: rentingcarid,
      extrakms: extrakms,
      accessories: accessories,
      ownriskinsurance: true
    };

    let response = await axios.get(
      `/Vehicle/detailedPrice/${companyId}?traceId=${traceId}`, // ?CompanyId=${companyId}&BookingStart=${startDate}&BookingEnd=${endDate}&TraceId=${traceId}
      {
        params: data,
      }
    )
    resObj.data = response.data;
  } catch (error) {
    console.error(error);
    resObj.error = true;
    resObj.message = error.message
  }
  return resObj;
}

export async function GetTermsOfTrade(companyId, traceId) {
  let resObj = {
    error: false,
    error_message: "",
    data: []
  };
  try {
    let response = await axios.get(`/Contracts/termsoftrade/?CompanyId=${companyId}&traceId=${traceId}`)
    resObj.data = response.data;
  } catch (error) {
    console.error(error);
    resObj.error = true;
    resObj.message = error.message
  }
  return resObj;
}


export async function bruttolistvehicles(companyId, officeId, traceId) {
  let resObj = {
    error: false,
    error_message: "",
    data: []
  };
  try {
    let response = await axios.get(`Vehicle/bruttolistvehicles?companyId=${companyId}&traceId=${traceId}` + (officeId? `&officeId=${officeId}`: '' ))
    resObj.data = response.data;
  } catch (error) {
    console.error(error);
    resObj.error = true;
    resObj.message = error.message
  }
  return resObj;
}



export async function getTraceId() {
  try {
    return await axios.get(`Maintenance/token`);
  } catch (error) {
    return { error: true, message: "token error" }
  }

}

export async function  CheckOperationalProblems() {
  try {
    return await axios.get(`Maintenance/CheckOperationalProblems`);
  } catch (error) {
    console.error("CheckOperationalProblems error");
    return  { error: true, message: "Operational Problems error" }
  }
}

export async function GetPeriods(periods, periodsOfMonths, traceId) {
  let resObj = {
    error: false,
    error_message: "",
    data: []
  };
  try {
    let uri = `/Calendar/periods/?periods=${periods ? `[${periods}]`:''}&periodsofmonths=${periodsOfMonths ? `[${periodsOfMonths}]`:''}&traceId=${traceId}`;
    let response = await axios.get(uri)
    resObj.data = response.data;
  } catch (error) {
    console.error(error);
    resObj.error = true;
    resObj.message = error.message
  }
  return resObj;
}