<template>
  <div>
    <v-row>
      <v-col cols="7" md="9" class="pb-0">
        <Datepicker :key="pickupDate" :label="$t('step0.input.pickupTitle')" :loading.sync="loadingstates.pickupDate"
          v-model="pickupDate" @monthchange="monthchangepickup" no-title required
          :rules="[(v) => !!v || $t('validation.required')]"
          :disabled="$store.state.company.availableStartDays.length == 0" :allowed-dates="pickupAllowedDates" />
        <!-- :dates="$store.state.company.availableStartDays" -->
      </v-col>
      <v-col cols="5" md="3" class="pb-0">
        <v-select :disabled="pickupDate == undefined || pickupDate == ''" required v-model="pickupTime"
          :items="getPickupOpeninghoursByDate(pickupDate)" :rules="[(v) => v !== '' || $t('validation.required')]"
          :label="$t('step0.input.pickup_time_label')">
          <template v-slot:no-data>
            <div class="v-list-item theme--light">
              <div class="v-list-item__content">
                <div class="v-list-item__title">
                  {{ $t("step0.timeNotAvailable") }}
                </div>
              </div>
            </div>
          </template>
        </v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="my-0 py-0">
        <p :class="[isTimeSpanIn24 ? 'opacity1' : 'opacity0']" class="red--text font-weight-bold">
          {{ $t("step0.isTimeSpanUnder24") }}
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="7" md="9">
        <Datepicker :key="deliveryDate" :label="$t('step0.input.deliveryTitle')"
          :loading.sync="loadingstates.deliveryDates" v-model="deliveryDate" no-title required :min="minimumRentalPeriod"
          @monthchange="monthchangedelivery" :disabled="!pickupTime" :rules="[(v) => !!v || $t('validation.required')]"
          :allowed-dates="deliveryAllowedDates" :show-current="pickupDate" />

        <!-- :dates="$store.state.company.deliveryAllowedDates" -->
      </v-col>
      <v-col cols="5" md="3">
        <v-select :disabled="deliveryDate == undefined || deliveryDate == ''"
          :items="getDeliveryOpeninghoursByDate(deliveryDate)" required v-model="deliveryTime"
          :rules="[(v) => !!v || $t('validation.required')]" :label="$t('step0.input.delivery_time_label')"></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <p v-if="minimumRentalPeriodText.value > 0">
          {{ $t("step0.minimumRentalPeriod") }}
          {{ minimumRentalPeriodText.value }}
          <template v-if="minimumRentalPeriodText.type == 'd'">
            {{ $tc("general.days", minimumRentalPeriodText.value) }}
          </template>
          <template v-else>
            {{ $tc("general.months", minimumRentalPeriodText.value) }}
          </template>
        </p>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { DateTime } from "luxon";


import Datepicker from "./Fragments/Datepicker";
export default {
  name: "PickupAndDeliveryOptions",
  mixins: [],
  components: { Datepicker },
  props: {
    periodes: Array,
  },
  data: function () {
    return {};
  },
  computed: {
    minimumRentalPeriod: {
      get() {
        const pickupDate = this.$store.state.order.pickupDate;
        const period =
          this.$store.getters["order/getMinimumRentalPeriodDate"](pickupDate);
        return period;
      },
    },
    isTimeSpanIn24: {
      get() {
        if (
          this.$store.state.order.pickupDate &&
          this.$store.state.order.pickupTime
        ) {
          const now = DateTime.local();
          const then = DateTime.fromISO(
            this.$store.state.order.pickupDate +
            "T" +
            this.$store.state.order.pickupTime
          );

          // todo fejl med dk / en ?
          const diff = then.diff(now, ["days"]);

          return diff.days <= 1;
        }
        return false;
      },
    },
    locationsStart: {
      get() {
        return this.$store.state.order.startlocation;
      },
      set(value) {
        this.resetTimesAndDates();
        this.$store.dispatch("order/SET_STARTLOCATION", value);
      },
    },
    city: {
      get() {
        const department = this.$store.state.order.department;
        return { id: department.id, city: department.city };
      },
      set(value) {
        this.resetTimesAndDates();
        this.$store.dispatch("order/SET_DEPARTMENT", value);
      },
    },
    pickupDate: {
      get() {

        return this.$store.state.order.pickupDate;
      },
      set(value) {


        this.$store.dispatch("order/SET_PICKUP_DATE", value);
        this.$store.dispatch("order/SET_DELIVERY_DATE", null);


      },
    },
    deliveryDate: {
      get() {


        return this.$store.state.order.deliveryDate;
      },
      set(value) {


        this.$store.dispatch("order/SET_DELIVERY_DATE", value);
        this.$store.dispatch("order/SET_DELIVERY_TIME", null);
      },
    },
    pickupTime: {
      get() {
        return this.$store.state.order.pickupTime;
      },
      set(value) {
        this.$store.dispatch("order/SET_PICKUP_TIME", value);
      },
    },
    deliveryTime: {
      get() {
        return this.$store.state.order.deliveryTime;
      },
      set(value) {
        this.$store.dispatch("order/SET_DELIVERY_TIME", value);
      },
    },
    ...mapGetters({
      minimumRentalPeriodText: "order/getMinimumRentalPeriodText",
      loadingstates: "company/loadingstates",
      locationsOptions: "company/locationsOptions",
      cityOptions: "company/cities",
      vehicleTypes: "company/vehicleTypes",
      pickupAllowedDates: "company/isPickupdayAllowed",
      deliveryAllowedDates: "company/isDeliverydayAllowed",
      getDeliveryOpeninghoursByDate: "company/getDeliveryOpeninghoursByDate",
      getPickupOpeninghoursByDate: "company/getPickupOpeninghoursByDate",
      // deliveryTimeRange: "company/deliveryTimeRange",
    }),
  },
  methods: {
    monthchangepickup(date) {
      this.$store.dispatch("company/GET_MONTHCHANGE_PICKUP", date);
    },
    monthchangedelivery(date) {
      this.$store.dispatch("company/GET_MONTHCHANGE_DELIVERY", date);
    },
    resetTimesAndDates() {
      this.$store.dispatch("order/SET_PICKUP_DATE", null);
      this.$store.dispatch("order/SET_PICKUP_TIME", null);

      this.$store.dispatch("order/SET_DELIVERY_DATE", null);
      this.$store.dispatch("order/SET_DELIVERY_TIME", null);
    },
  },
};
</script>
<style lang="scss" scoped>
.opacity {
  &0 {
    opacity: 0;
  }

  &1 {
    opacity: 1;
  }
}
</style>
