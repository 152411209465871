
export function numberFormat(price, locales) {
   locales = locales || "da-DK";
    return new Intl.NumberFormat( locales,{
        style: 'decimal',
        minimumFractionDigits:  price % 1 == 0?  0 : 2
    }).format(price);
 }

export const  numberFormatHelperMixin =  {
    methods: {
        numberFormat: function (price, locales = null) {
            return numberFormat(price, locales)
        },
      
      }
 }
